import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { ReactComponent as CookingIcon } from "../../../Assets/Icons/Cookingicon.svg";
import Card from "../Card";
import "./AddCookingInstructions.css";
import {  message } from "antd";
const AddCookingInstructions = (props) => {
  const [text, setText] = React.useState('');

 

  const handleTextChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 100) {
      setText(inputValue);
    }
  };

  useEffect(() => {
    props.addCookingInstructions(text);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
   []); // Run once on mount

  const handleSave = () => {
    props.addCookingInstructions(text);
    if(text){
      message.success({
        content: "Cooking Instructions added Successfully",
        className: "custom-class", 
        style: {
          marginTop: "55vh",
          marginLeft: "35vh",
        },
      })}else if(text===""){
        message.error({
          content: "Please add cooking instructions",
          className: "custom-class", 
          style: {
            marginTop: "55vh",
            marginLeft: "35vh",
          },
      })}
    // }
  //},1000)
  };

  const businessTypeIds = props?.Cart?.storeid?.businessTypeId || [];

  return (
    businessTypeIds.some(id => id === 2 || id === 11) && (
      <Card hasBorder={false}>
        <Grid container style={{ display: 'flex', alignItems: "center", paddingBottom: '10px' }}>
          <Grid item xs={0.5} sm={0.5} md={0.5} style={{ display: 'flex', alignItems: "center" }}>
            <div><CookingIcon /></div>
          </Grid>
          <Grid
            style={{ display: 'flex', alignItems: "center" }}
            item
            xs={10.5}
            sm={10.5}
            md={10.5}
          >
            <p
              style={{ fontSize: '18px', fontWeight: 700, color: '#000' }}
              className="cookingInsHead"
            >
              {'Add Cooking Instructions'}
            </p>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="character-counter">
              <textarea
                value={text}
                onChange={handleTextChange}
                rows="4"
                maxLength="100"
                className="custom-textarea"
              ></textarea>
              <div className="char-count">
                <span id="char-count">{text.length}</span>/100
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container style={{
          alignItems: 'flex-end',
          paddingTop: "18px",
          display: "flex",
          justifyContent: "right"
        }}>
          <Grid item xs={12} md={4} style={{
            alignItems: 'flex-end'
          }}>
            <button
              style={{
                color: "white",
                paddingRight: "34px",
                paddingLeft: "34px",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRadius: '26.5px',
                background: '#6AA92A',
                border: 'none',
                cursor: 'pointer',
                textAlign: "center"
              }}
              onClick={handleSave}
            >
              Save
            </button>
          </Grid>
        </Grid>
      </Card>
    )
  );
};

export default AddCookingInstructions;
