export const checkForNewVersion = () => {
  const currentVersion = process.env.REACT_APP_VERSION;
  const cachedVersion = localStorage.getItem('app_version');

  // If there's no cached version (first visit) OR the cached version differs from the current version
  if (!cachedVersion || cachedVersion !== currentVersion) {
    // Clear storage only when a new version is detected (or on the first visit)
    
    // Clear local storage except app version
    const savedVersion = localStorage.getItem('app_version');
    // localStorage.clear(); // Clear all items in localStorage
    localStorage.setItem('app_version', savedVersion); // Keep app version
    
    // Clear session storage
    sessionStorage.clear();

    // Clear IndexedDB if necessary (uncomment if needed)
    // indexedDB.databases().then((databases) => {
    //     databases.forEach((db) => {
    //         indexedDB.deleteDatabase(db.name);
    //     });
    // });

    // Update app version in local storage to the current version after clearing
    localStorage.setItem('app_version', currentVersion);

    // Reload the page to apply the new version
    window.location.reload(true); 
  }
};
