import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Grid, useMediaQuery } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useStyles } from "./style";

const SelectItem = (props) => {
  const [checked, setChecked] = React.useState([]);
  const [checkeditem, setCheckedItem] = React.useState([]);
  const [checkeditemname, setCheckedItemName] = React.useState([]);
  const isTabletOrLarger = useMediaQuery("(min-width: 768px)");

  const classes = useStyles();

  const handleToggle = (value, id, name) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newCheckedItem = [...checkeditem];
    const newCheckedItemName = [...checkeditemname];
    if (currentIndex === -1) {
      newChecked.push(value);
      newCheckedItem.push(id);
      newCheckedItemName.push(name);
    } else {
      newChecked.splice(currentIndex, 1);
      newCheckedItem.splice(currentIndex, 1);
      newCheckedItemName.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setCheckedItem(newCheckedItem);
    setCheckedItemName(newCheckedItemName);
    props.SelectedItemList(newCheckedItemName);
  };

  const SubmitItems = () => {
    props.SubmitItemList(props.SelectedItemsList);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {props.submititems && props.submititems === true ? (
        <>
          <h4>{"Selected Items"}</h4>
          {props.SelectedItemsList &&
            props.SelectedItemsList !== null &&
            props.SelectedItemsList.length > 0 &&
            props.SelectedItemsList.map((item, index) => (
              <Grid
                item
                xs={12}
                md={12}
                key={index}
                sx={{ paddingTop: "20px" }}
              >
                <Grid
                  container
                  style={{
                    paddingBottom: "20px",
                    gap: isTabletOrLarger ? "10px" : "0px",
                  }}
                >
                  <Grid item xs={1} md={1} className={classes.messageGrid}>
                    <AccountCircleIcon fontSize="medium" color="inherit" />
                  </Grid>
                  <Grid item xs={11} md={11} justifyContent="left">
                    <div className={classes.usercomplaintdisableddiv}>
                      {item}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </>
      ) : (
        <>
          <h4 style={{ textAlign: "center" }}>{"Select Items"}</h4>
          <Box display="flex" justifyContent="center" alignItems="center">
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                {props.orderdetailssuccess &&
                  props.orderdetailssuccess === true &&
                  props.OrderDetails &&
                  props.OrderDetails !== null &&
                  props.OrderDetails.products !== null &&
                  props.OrderDetails.products.length > 0 &&
                  props.OrderDetails.products.map((p, i) => {
                    const labelId = `checkbox-list-label-${
                      p.productid.productname && p.productid.productname
                    }`;
                    return (
                      <>
                        <ListItem key={i} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={handleToggle(
                              i,
                              p.productid.id,
                              p.productid.productname
                            )}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checked.indexOf(i) !== -1}
                                value={p.productid.id}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={`${
                                p.productid.productname &&
                                p.productid.productname
                              }`}
                            />
                          </ListItemButton>
                        </ListItem>
                      </>
                    );
                  })}
              </FormGroup>
            </FormControl>
          </Box>
          {props.SelectedItemsList &&
          props.SelectedItemsList !== null &&
          props.SelectedItemsList.length > 0 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className={classes.submitbutton} onClick={SubmitItems}>
                {"Submit"}
              </button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className={classes.disabledbutton} disabled>
                {"Submit"}
              </button>
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export default SelectItem;
