import React from 'react';
import { useStyles } from './style';
import selectorder from '../../Assets/Images/selectorder.svg';

const Noorders = () => {
    const classes = useStyles();
  return (
    <div>
        <div className={classes.noordersDiv}>
          <img src={selectorder} alt="noorders" className={classes.noordersimage} />
          <p className={classes.noordersheading}>
            {' Select item to view the status'}
          </p>
          {/* <p className={classes.noorderstext}>
        {`You haven’t added anything from this store `}
        </p> */}
        </div>
    </div>
  )
}

export default Noorders