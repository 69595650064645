import React from 'react';
import './style.css';

const ExploreButton = (props) => {
  const openSellerPortal = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <div 
    className={props.screen==='small'?"explore-button-small-screen":"explore-button"}
    onClick={() => openSellerPortal('https://testseller.nearshopz.com/')}>
        {'Become a Seller on NearShopz'}
  </div>
  )
}

export default ExploreButton