import React from "react";
import { useState } from "react";
import "./Coupon.css";
import { useDispatch } from "react-redux";

const ApplyCouponTextField = (props) => {
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState(false);
  const dispatch=useDispatch();

  const handleCouponId = (couponcodeText, storeid, totalPrice) => {

    const foundCoupon = props?.AvailableCoupons?.find(coupon => coupon.couponCode === couponcodeText);
    if (foundCoupon) {
      const couponId = foundCoupon?.id;
      props?.applyCoupon(couponId, storeid, totalPrice);
      setCouponError(false);
      
    } else {
      
      setCouponError(true);
    }
  };

  const handleCouponSelect = () => {
    if (couponCode !== null) {
      handleCouponId(
        couponCode,
        props?.Cart?.storeid?.id,
        props?.Cart?.totalPrice
      );
      setTimeout(() => {
        dispatch({
            type:"APPLY_COUPON_CLEAR"
        })
    }, 2000);
    } else {
      
      setCouponError(true);
    }
  };
  return (
    <>
      <div className="coupon-text-field">
        <span className="coupon-input-span">
          <input
            className="coupon-input"
            placeholder="Enter coupon code"
            onChange={(event) =>
              { 
                setCouponCode(event.target.value)}}
          />
        </span>
        
        <span
          className="coupon-apply-button"
          onClick={() => handleCouponSelect()}
        >
          {"APPLY"}
        </span>
        
      </div>
      {couponError && <span className="coupon-error-message">Please enter a valid coupon code</span>}
    </>
  );
};

export default ApplyCouponTextField;
