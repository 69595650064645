import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { useStyles } from './style';

const AlertBar = ({ type, message, props, source }) => {
  const classes = useStyles();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      className={type === 'success' ? classes.root : type === 'error' ? classes.error : classes.root}
      icon={type === 'success' ? <CheckCircleIcon /> : type === 'error' ? <ErrorIcon /> : <WarningIcon />}
      {...props} />;
  });
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    props.closesnackbars();
    if (source && source === 'otp') {
      props.otpclose()
    }
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
      autoHideDuration={3000}
      onClose={handleClose}
      
       >
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} {...props}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertBar;
