import React, { Component } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import MainFile from './MainFile';

export class RouterFile extends Component {
    constructor(props)
    {
        super(props);
        this.state={};
    }
  render() {
    return (
      <Router>
        <MainFile {...this.props}/>
      </Router>
    )
  }
}

export default RouterFile