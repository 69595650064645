import React, { useEffect, useRef, useState } from 'react';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Grid } from '@mui/material';
import './style.css';

const apiKey = 'AIzaSyBYfXjV8YJL_Y0S9nzRxK_kAuv8Xwv2Ds4';
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
// const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// load google map api js

function loadAsyncScript(src) {
  return new Promise(resolve => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src
    })
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  })
}

const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city + zip + state + this.country;
    }
  }

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach(component => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });

  return address;
}

function GoogleMap(props) {
  const searchInput = useRef(null);
  // eslint-disable-next-line
  const [address, setAddress] = useState({});
  const [open, setOpen] = useState(false);
  const [suggestionsopen, setSuggestionsOpen] = useState(false);
  const [inputfocus, setInputfocus] = useState(true);
  const inputRef = useRef(null);
  // eslint-disable-next-line
  const [value, setValue] = React.useState(null);
  // eslint-disable-next-line
  const [selectetedlocation, setSelectedLocation] = React.useState('');
  const [inputValue, setInputValue] = React.useState("");
  // eslint-disable-next-line
  const [options, setOptions] = React.useState([]);
  const autocomplete = (e) => {
    props.autocomplete(e);
  };

  useEffect(() => {
    // Set focus on the input field when the component mounts
    inputRef.current.focus();
  }, []);

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
    localStorage.setItem('location', extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>onChangeAddress(autocomplete));
    
  }

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    props.getPlaceBylatlng(`${lat},${lng}`);
    setOpen(false);
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
        localStorage.setItem("lattitude", position.coords.latitude);
        localStorage.setItem("longitude", position.coords.longitude);
      })
    }
  }
  // eslint-disable-next-line
  const inputFocus = () => {
    setInputfocus(true);
  };

  const selectLocation = (placeid, description) => {
    setSelectedLocation(description);
    setInputValue(description);
    localStorage.setItem('location', description);
    setSuggestionsOpen(false);
    props.suggestioncordinates(placeid);
  };

  React.useEffect(
    () => {
      props.resetsuggestions();
      setInputValue("");
      localStorage.clear();
      setInputfocus(true);
    },
    // eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      if (inputfocus === true) initMapScript().then(() => initAutocomplete());
    },
    // eslint-disable-next-line
    [inputfocus]
  );

  React.useEffect(
    () => {
      let active = true;

      if (inputValue === "") {
        setOptions(value ? [value] : []);
        localStorage.removeItem("location");
        localStorage.removeItem("lattitude");
        localStorage.removeItem("longitude");
        props.removeLocationProps();
        return undefined;
      }

      autocomplete(inputValue);
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (props.isSuggestions && props.Suggestions) {
          newOptions = [...newOptions, ...props.Suggestions];
        }

        setOptions(newOptions);
      }

      return () => {
        active = false;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, inputValue]
  );

  React.useEffect(
    () => {
      if (props.PlaceDescription && props.PlaceDescription !== null) {
        setInputValue(
          props.PlaceDescription.formatted_address &&
            props.PlaceDescription.formatted_address
        );
        localStorage.setItem(
          "location",
          props.PlaceDescription.formatted_address &&
            props.PlaceDescription.formatted_address
        );
      }
    },
    // eslint-disable-next-line
    [props.isPlaceDescription]
  );

  React.useEffect(
    () => {
      if (
        props.Coordinates &&
        props.Coordinates.data &&
        props.Coordinates.data.googlemapplacedetails &&
        props.Coordinates.data.googlemapplacedetails !== null
      ) {
        localStorage.setItem(
          "lattitude",
          props.Coordinates.data.googlemapplacedetails.lat
        );
        localStorage.setItem(
          "longitude",
          props.Coordinates.data.googlemapplacedetails.lng
        );
      }
    },
    // eslint-disable-next-line
    [props.isCoordinates]
  );

  const placeholderTexts = ["Trivandrum", "Palakkad", "Kollam"];
  const [placeholder, setPlaceholder] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const typingEffect = setInterval(() => {
      const currentPlaceholder = placeholderTexts[currentIndex];
      if (placeholder !== currentPlaceholder) {
        setPlaceholder(currentPlaceholder.substring(0, placeholder.length + 1));
      } else {
        clearInterval(typingEffect);
        setTimeout(() => {
          setPlaceholder("");
          setCurrentIndex((currentIndex + 1) % placeholderTexts.length);
        }, 1000);
      }
    }, 100);
    return () => {
      clearInterval(typingEffect);
    };
  }, [currentIndex, placeholder]);

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      <div className="locationtextfield">
        <span className="locationicon" onClick={findMyLocation}>
          <GpsFixedIcon color="inherit" />
        </span>
        {"|"}
        <span className="textfield">
          <input
            id="LocationSearchInput"
            ref={inputRef}
            value={inputValue}
            onChange={(e) => {
              setSuggestionsOpen(true);
              setInputValue(e.target.value);
              setOpen(true);
            }}
            type="text"
            placeholder={`Enter your location e.g."${placeholder}"`}
          />
        </span>
      </div>
      {inputValue !== "" &&
        suggestionsopen === true &&
        open === true &&
        props.isSuggestions &&
        props.isSuggestions === true &&
        props.Suggestions &&
        props.Suggestions.length > 0 && (
          <div
            style={{
              width: "100%",
              height: "100px",
              position: "absolute",
              zIndex: 2,
              marginTop: "10px",
            }}
            id="LocationSearchResults"
          >
            <div className="suggestions-div" id="suggestions-div">
              {props.Suggestions.map((s) => (
                <>
                  <Grid
                    container
                    alignItems="center"
                    sx={{ cursor: "pointer", paddingTop: "10px" }}
                    onClick={() => {
                      selectLocation(
                        s.place_id && s.place_id,
                        s.description && s.description
                      );
                    }}
                  >
                    <Grid item xs={12}>
                      <span style={{ display: "flex" }}>
                        <span
                          style={{
                            paddingRight: "15px",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                        >
                          <FmdGoodIcon />
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                        >
                          {s.description.split(",", 1)}
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#BDBDBD",
                            }}
                          >
                            {s.description.substring(
                              s.description.indexOf(",") + 1
                            )}
                          </div>
                        </span>
                      </span>
                    </Grid>
                  </Grid>
                </>
              ))}
            </div>
          </div>
        )}
    </div>
  );
}

export default GoogleMap;
