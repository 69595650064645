import { makeStyles } from "@mui/styles";
// import { buttongr een, location_green} from "../../Assets/Colors";
// import { main_font } from "../../Assets/Fonts";
import { alpha } from '@mui/material/styles';

export const useStyles = makeStyles(() => ({
    locatemebuttonspan:{
       display:'flex',
       float:'right',
       width:'30%',
    },
    locatemebutton:{
        background: 'transparent',
        boxShadow:'none',
        border: 'none',
        borderLeft:'1px solid rgba(0,0,0,0.2)',
        boxSizing: 'border-box',
        borderRadius: 'none',
        height: '30px',  
        width:'100%',
        textAlign:'left',
        display:'flex',
        alignItems:'center',
        cursor:'pointer',
        // float:'right',
        paddingLeft:'10px',
        '&:focus':{
            border:'none',
        },
        '@media (min-width: 320px)': {
         paddingLeft:"4px",
         textAlign:"center" 
      }
        
    },
    mobilesearch: {
        width:'100%',
        backgroundColor:`transparent`,
        boxShadow:'none',
        // borderRadius:'12px',
        // fontFamily:`${main_font}`,
        fontSize:'12px',
        color:'white',
        // paddingTop:'5px',
        // paddingBottom:'5px',
        // paddingLeft:'10px',
        // paddingRight:'10px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        cursor:'pointer'
       },
    mobile:{
        width:'100%',
        backgroundColor:alpha('#ffffff', 0.5),
        boxShadow:'none',
        borderRadius:'14px',
        // fontFamily:`${main_font}`,
        fontSize:'12px',
        color:'white',
        paddingLeft:'10px',
        paddingRight:'10px',
        display:'flex',
        cursor:'pointer'
    },
    icon:{
        color:'white',
        fontStyle:'semibold',
        paddingLeft:'8px',
        paddingRight:'8px',
        textAlign:'center',
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
    },
    addressheading:{
        fontStyle:'normal',
        fontWeight:'500',
        textOverflow: 'ellipsis' ,
        width:'100px',
        whiteSpace:'nowrap',
        overflow: 'hidden',
        textAlign:'left'
    },
    addressSubheading:{
        fontStyle:'normal',
        fontWeight:'500',
        color:alpha('#ffffff', 0.5),
        fontSize:'10px',
        textOverflow: 'ellipsis',
        whiteSpace:'nowrap',
        overflow: 'hidden'
    },
    modalHeading: {
        fontWeight: 1000,
        fontSize: '18px',
        lineHeight: '25px',
        textAlign: 'center',
        color: '#000000',
    },
    input: {
        background: 'rgba(105, 168, 92, 0.1)',
        boxShadow:'0px 8px 44px rgba(0, 0, 0, 0.04)',
        border: '1px solid rgba(0,0,0,0.2)',
        boxSizing: 'border-box',
        borderRadius: '13px',
        height: '55px',
        width:'70%',
        paddingLeft:'15px',
        paddingRight:'15px',
        display:'flex',
        alignItems:'center',
        '&:focus':{
            border:'none',
        }
    },
    enterlocationinputspan:{
        display:'flex',
        float:'left',
        width:'70%'
    },
    enterlocationinput:{
        height:'45px',
        width:'100%',
        background:'transparent',
        border:'none',
        '&:focus':{
            border:'none',
        }
    },
    suggestionList: {
        paddingTop:'15px',
        paddingBottom:'15px',
        paddingLeft:'15px',
        cursor:'pointer',
        display:'flex',
        flexDirection:'column',
        '&:hover':{
            fontSize:'16px',
            backgroundColor:'rgba(133, 190, 73, 0.2)',
            color:'rgba(133, 190, 73, 1)',
            borderRadius:'20px',
        }
    },
    suggestionHeading: {
        fontSize:'16px',
        fontWeight:800,
        display:'flex',
    },
    locationIcon: {
        paddingRight:'10px',
    },
    suggestionsubheading: {
        fontSize:'14px',
        paddingLeft:'35px',
    }

  }));

