import { Grid } from "@mui/material";
import React from "react";
import monochromelogo from "../../Assets/Images/monochromelogo.svg";
import "./index.css";
import Header from "../Header";

const TopSection = () => {
  return (
    <>
      <Header />
      <div className="about-us-container">
        {/* <Grid container style={{
        background: '#85BE49',
        paddingTop: '60px',
        paddingBottom: '60px',
        color: '#fff',
        borderRadius: '10px'
      }}>
        <Grid container className='ImageGrid'>
          <Grid item xs={6}>
            <img src={monochromelogo} className='logo centerItems' alt='logo' />
          </Grid>
        </Grid>
        <Grid container className='centerItems'>
          <Grid item xs={6}>
            <h2> About Us</h2>
          </Grid>
        </Grid>
      </Grid> */}
      </div>
    </>
  );
};

export default TopSection;
