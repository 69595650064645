import * as React from "react";
import Drawer from "@mui/material/Drawer";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Nonotifications from "../../Assets/Images/Nonotifications.svg";
import { Badge, Box } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./index.css";
import { DialogTitle, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function TemporaryDrawer(props) {
  const [state, setState] = React.useState({
    right: false,
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [messages, setMessages] = React.useState(null);
  const [liveorderdata, setLiveorderdata] = React.useState(0);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (open === true) {
      props.props.getLiveOrders(localStorage.getItem("userid"));
    }
    if (open === false) {
      setMessages(null);
    }
    setState({ ...state, right: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: { xs: "100vw", md: 400 },
        backgroundColor: "rgb(246, 247, 248)",
      }}
      role="presentation"
      onClick={toggleDrawer("right", false)}
    >
      <div className="NotificationHeader">
        {/* {isSmallScreen && (
          <span className={"leftarrow"}>
            <KeyboardBackspaceIcon />
          </span>
        )} */}
        <p>{"Notifications"}</p>
      </div>
      <div className="Content">
        {(props.props.isLiveOrders &&
          props.props.isLiveOrders === true &&
          props.props.LiveOrders &&
          props.props.LiveOrders.length <= 0) ||
        !localStorage.getItem("token") ? (
          <div className="NotificationImgContent">
            <div className="NotificationImage">
              <img src={Nonotifications} alt="No New Notification" />
            </div>
            <div className="noMessage">{"Uh-oh !!!"}</div>
            <div className="noMessages">{"You have no new notifications"}</div>
          </div>
        ) : (
          <div className="NotificationContent">
            <>
              {props.socket &&
                messages !== null &&
                liveorderdata !== 0 &&
                liveorderdata.lastStatus !== "Completed" && (
                  <>
                    <div className="NotificationCard">
                      {liveorderdata.lastStatus === "Order-Accepted" ? (
                        <div className="BlueIndicator"></div>
                      ) : liveorderdata.lastStatus === "Order-Placed" ? (
                        <div className="RedIndicator"></div>
                      ) : liveorderdata.lastStatus === "Order-Ready" ? (
                        <div className="YellowIndicator"></div>
                      ) : liveorderdata.lastStatus === "Completed" ? (
                        <div className="GreenIndicator"></div>
                      ) : (
                        <div className="OtherIndicator"></div>
                      )}
                      <div className="Message">
                        <div className="SubMessage">
                          <b>
                            {`#${liveorderdata.orderNumber}(${liveorderdata.lastStatus}) : `}
                          </b>
                          {`${
                            liveorderdata.notifications &&
                            liveorderdata.notifications[
                              liveorderdata.notifications.length - 1
                            ].message
                          }`}
                        </div>
                        <div className="time">
                          {props.socket &&
                          messages !== null &&
                          liveorderdata !== 0 &&
                          liveorderdata.created !== null
                            ? new Date(liveorderdata.created)
                                .toLocaleTimeString()
                                .slice(0, 5) +
                              new Date(liveorderdata.created)
                                .toLocaleTimeString()
                                .slice(8)
                            : null}
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className="divider-01"></div>
                  </>
                )}
              {props.props.isLiveOrders &&
                props.props.isLiveOrders === true &&
                props.props.LiveOrders &&
                props.props.LiveOrders.map((o) =>
                  (props.socket &&
                    messages !== null &&
                    liveorderdata !== 0 &&
                    liveorderdata.orderNumber &&
                    liveorderdata.orderNumber === o.orderNumber &&
                    liveorderdata.lastStatus !== "Completed" &&
                    o.lastStatus !== "Completed") ||
                  (props.socket &&
                    messages !== null &&
                    liveorderdata !== 0 &&
                    liveorderdata.orderNumber &&
                    liveorderdata.orderNumber === o.orderNumber &&
                    liveorderdata.lastStatus === "Completed") ? null : (
                    <>
                      <div className="NotificationCard">
                        {o.lastStatus === "Order-Accepted" ? (
                          <div className="BlueIndicator"></div>
                        ) : o.lastStatus === "Order-Placed" ? (
                          <div className="RedIndicator"></div>
                        ) : o.lastStatus === "Order-Ready" ? (
                          <div className="YellowIndicator"></div>
                        ) : o.lastStatus === "Completed" ? (
                          <div className="GreenIndicator"></div>
                        ) : (
                          <div className="OtherIndicator"></div>
                        )}
                        <div className="Message">
                          <div className="SubMessage">
                            <b>{`#${o.orderNumber}(${o.lastStatus}) : `}</b>
                            {`${
                              o.notifications &&
                              o.notifications[o.notifications.length - 1]
                                .message
                            }`}
                          </div>
                        </div>
                        <div>
                          <div className="time">
                            {new Intl.DateTimeFormat("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                            }).format(
                              o.notifications[o.notifications.length - 1]
                                .created
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="divider-01"></div>
                    </>
                  )
                )}
            </>
          </div>
        )}
      </div>
    </Box>
  );

  React.useEffect(() => {
    const messageListener = (message) => {
      if (message !== null) {
        setMessages((prevMessages) => {
          const newMessages = { ...prevMessages };
          newMessages[message.id] = message;
          setLiveorderdata({
            orderNumber: message.orderNumber,
            lastStatus: message.lastStatus,
            notifications: message.notifications,
            created: message.createdAt,
          });
          return newMessages;
        });
      } else return null;
    };

    const deleteMessageListener = (messageID) => {
      setMessages((prevMessages) => {
        const newMessages = { ...prevMessages };
        delete newMessages[messageID];
        return newMessages;
      });
    };
    if (props.socket !== null) {
      props.socket.on(
        `${localStorage.getItem("userid")}_order`,
        messageListener
      );
      props.socket.on("deleteMessage", deleteMessageListener);
      props.socket.emit("getMessages");
    }

    return () => {
      if (props.socket !== null) {
        props.socket.off(
          `${localStorage.getItem("userid")}_order`,
          messageListener
        );
        props.socket.off("deleteMessage", deleteMessageListener);
      }
    };
  }, [props.socket]);

  return (
    <div>
      <React.Fragment>
        <button
          className="notification-button"
          onClick={toggleDrawer("right", true)}
        >
          {props.socket && messages === null ? (
            <NotificationsNoneIcon />
          ) : (
            <Badge variant="dot" color="warning">
              <NotificationsNoneIcon />
            </Badge>
          )}
          <span>Notifications</span>
        </button>
        <Drawer
          PaperProps={{
            sx: {
              height: "100%",
              background: "#fff",
            },
          }}
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
