import NavBar from "../Navbar/index";
import { Grid } from "@mui/material";
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../Assets/Images/WhiteLogo.svg';
import CancelationPolicyDetails from "./CancelationDetails.js"
import "./index.css"
const CancelationPolicyTerms = (props) => {
    const logoClick = () => {
        props.navigate("/stores");
      };
  return (
    <div className="cancelation-conatiner">
      <div>
      <AppBar position="fixed" sx={{background:'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)',boxShadow:'none'}}>
      <Toolbar style={{justifyContent:'center'}}>
          <img style={{height:'38px',width:'152px',cursor:'pointer',opacity:1}} src={logo} alt="SHOP.png"  onClick={logoClick}/>
        </Toolbar>
    </AppBar>
      </div>
      <div className="wrapper-cancelation">
        <span className="heading-cancelation">Refund and Cancellation Policy</span>
        <div className="cancelation-text-conatiner">

            {
                CancelationPolicyDetails.map((policeis)=>{
                    return (
                      <div style={{
                        display:"flex",
                        flexDirection:"column",
                        gap:"16px",
                        paddingBottom:"32px",
                        boxSizing:"border-box"
                      }}>
                        <div style={{
                            display:"flex",
                            alignItems:"center"
                        }}>
                          <p className="cancelation-subhead">{`${policeis.index}. `}</p>
                          <h6 className="cancelation-subhead">{policeis.subhead}</h6>
                        </div>
                        {
                           policeis.policytext.map((terms)=>{
                            return(
                                <div className="canacelation-terms-text">
                                    {terms}
                                </div>
                            )
                           }) 
                        }
                      </div>
                    );
                })
            }
            
        </div>
      </div>
    </div>
  );
};
export default CancelationPolicyTerms;
