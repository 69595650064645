import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
    root: {
        background: '#FFFFFF',
        borderRadius: '15px',
        padding:'30px',
        width:'100%',
        userSelect:'none',
    },
    image: {
        maxWidth:'100%',
        maxHeight:'100%',
        width:'218px',
        height:'160px',
    },
    productname: {
        fontWeight: 800,
        fontSize: '20px',
        lineHeight: '25px',
        color: '#061303',
    },
    description: {
        color:'rgba(6, 19, 3, 0.5)',
        fontSize:'14px',
        fontWeight:600,
        paddingTop:'15px',
        '@media (max-width: 600px)': {
            // Styles for smaller screens
            paddingTop:'5px',
        },
    },
    productprice: {
        color: '#061303',
        fontSize:'16px',
        fontWeight:900
    },
    productpriceuom: {
        color: '#061303',
        fontSize:'13px',
        fontWeight:900,
        opacity:0.8
    },
    total: {
        color:'#061303',
        fontSize:'24px',
        fontWeight:800,
        paddingTop:'15px',
    },
    productadd: {
        display:'flex',
        width:'100%',
        paddingTop:'35px',
        '@media (max-width: 600px)': {
            // Styles for smaller screens
            paddingTop:'0px',
        },
    },
    price: {
        float:'left',
        width:'100%',
    },
    button: {
        float:'right',
        width:'40%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
    },
    relatedproductsheading: {
        paddingTop:'50px',
        color: '#000000',
        fontSize:'18px',
        fontWeight:800,
    },
    relatedproducts: {
        paddingTop:'25px',
        paddingBottom:'50px',
    },
}));
