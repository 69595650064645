import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { db } from "../db";
import { useLiveQuery } from "dexie-react-hooks";
import { useStyles } from "./style.js";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import './style.css'

//add to cart bug button
//berlin
const ExpandButton = (props) => {
  const classes = useStyles();
  const [productCount, setProductCount] = useState(0);
  const [cartData, setCartData] = useState([]);
  const { cart } = db;
  const cartdata = useLiveQuery(() => cart.toArray(), []);
  const [openAlert, setOpenAlert] = useState(false);

  // const handleAdd = async (
  //     productID,
  //     productName,
  //     productImage,
  //     price,
  //     promoprice,
  //     storeID,
  //     storeName,
  //     uom,
  //     productquantity,
  //     q
  //   ) => {
  //     let updatedCartData;

  //     // Fetch the current store data and all cart data
  //     let storedata = await db.cart.get(storeID);
  //     let cartData = await db.cart.toArray();

  //     // Count items from other stores in the cart
  //     const itemsFromOtherStore = await db.cart
  //       .where("storeid")
  //       .notEqual(storeID)
  //       .count();

  //     // If there are items from another store, show the alert dialog and exit
  //     if (itemsFromOtherStore > 0) {
  //      // props.props.setCartlimit();  // Trigger the alert dialog
  //      setOpenAlert(true)
  //       return;
  //     }

  //     // Proceed with adding/updating items in the cart
  //     if (!storedata) {
  //       if (cartData.length >= 5) {
  //         props.props.setCartlimit();
  //       } else {
  //         updatedCartData = {
  //           storeid: storeID,
  //           storename: storeName,
  //           totalPrice: 5000,
  //           products: [{
  //             productid: productID,
  //             productQuantity: productquantity,
  //             cartquantity: 1,
  //             productname: productName,
  //             image: productImage,
  //             productprice:  Number(price),
  //             uom: uom
  //           }]
  //         };
  //         setProductCount(1);
  //       }
  //     } else {
  //       const existingProduct = storedata.products.find(product => product.productid === productID);
  //       if (existingProduct) {
  //         const updatedProducts = storedata.products.map(product =>
  //           product.productid === productID
  //             ? { ...product, cartquantity: product.cartquantity + q }
  //             : product
  //         );
  //         updatedCartData = { ...storedata, products: updatedProducts };
  //         setProductCount(productCount + q);
  //       } else {
  //         updatedCartData = {
  //           ...storedata,
  //           products: [
  //             ...storedata.products,
  //             {
  //               productid: productID,
  //               productQuantity: productquantity,
  //               cartquantity: 1,
  //               productname: productName,
  //               image: productImage,
  //               productprice:Number(price),
  //               uom: uom
  //             }
  //           ]
  //         };
  //       }
  //     }

  //     // Add or update the cart in the database
  //     if (!storedata) {
  //       await db.cart.add(updatedCartData);
  //     } else {
  //       await db.cart.update(storeID, updatedCartData);
  //     }

  //     // Update the cart data state
  //     setCartData(cartData);
  //   };

  const handleAdd = async (
    productID,
    productName,
    productImage,
    price,
    promoprice,
    storeID,
    storeName,
    uom,
    productquantity,
    q
  ) => {
    let updatedCartData;

    // Fetch the current store data and all cart data
    let storedata = await db.cart.get(storeID);
    let cartData = await db.cart.toArray();

    // Count items from other stores in the cart
    const itemsFromOtherStore = await db.cart
      .where("storeid")
      .notEqual(storeID)
      .count();

    // If there are items from another store, show the alert dialog and exit
    if (itemsFromOtherStore > 0) {
      // props.props.setCartlimit();  // Trigger the alert dialog
      setOpenAlert(true);
      return;
    }

    // Proceed with adding/updating items in the cart
    if (!storedata) {
      if (cartData.length >= 5) {
        props.props.setCartlimit();
      } else {
        updatedCartData = {
          storeid: storeID,
          storename: storeName,
          totalPrice: 5000,
          products: [
            {
              productid: productID,
              productQuantity: productquantity,
              cartquantity: 1,
              productname: productName,
              image: productImage,
              productprice: Number(price),
              uom: uom,
            },
          ],
        };
        setProductCount(1);
      }
    } else {
      const existingProduct = storedata?.products?.find(
        (product) => product?.productid === productID
      );
      if (existingProduct) {
        const newQuantity = existingProduct.cartquantity + q;

        // Check if the total cart quantity exceeds 50
        if (newQuantity > 50) {
          // Show an alert or trigger an action when limit exceeds 50
          alert("Cannot add more than 50 items of this product.");
          return;
        }

        const updatedProducts = storedata.products.map((product) =>
          product.productid === productID
            ? { ...product, cartquantity: newQuantity }
            : product
        );
        updatedCartData = { ...storedata, products: updatedProducts };
        setProductCount(productCount + q);
      } else {
        updatedCartData = {
          ...storedata,
          products: [
            ...storedata.products,
            {
              productid: productID,
              productQuantity: productquantity,
              cartquantity: 1,
              productname: productName,
              image: productImage,
              productprice: Number(price),
              uom: uom,
            },
          ],
        };
      }
    }

    // Add or update the cart in the database
    if (!storedata) {
      await db.cart.add(updatedCartData);
    } else {
      await db.cart.update(storeID, updatedCartData);
    }

    // Update the cart data state
    setCartData(cartData);
  };

  const handleRemove = async (storeID, productID) => {
    const storedata = await db.cart.get(storeID);
    if (storedata) {
      const updatedProducts = storedata.products.filter(
        (product) => product.productid !== productID
      );
      const updatedCartData = { ...storedata, products: updatedProducts };
      if (updatedProducts.length === 0) {
        await db.cart.delete(storeID);
        setProductCount(0);
      } else {
        await db.cart.update(storeID, updatedCartData);
        setProductCount(0);
      }
      setCartData(cartdata);
    }
  };

  const checkCount = async (storeid, id) => {
    const storedata = await db.cart.get(storeid);
    if (!storedata || !storedata.products) {
      setProductCount(0);
    } else {
      const product = storedata.products.find(
        (product) => product.productid === id
      );
      if (!product) {
        setProductCount(0);
      } else {
        setProductCount(product.cartquantity);
      }
    }
  };

  useEffect(() => {
    checkCount(props.storeid, localStorage.getItem("productid"));
  }, [cartdata, props.storeid]);

  const removestorefromcart = async (storeid) => {
    await db.cart.delete(storeid);
    setOpenAlert(false);
  };
  const handleStoreClose = () => {
    setOpenAlert(false);
  };
  return (
    <React.Fragment>
      {productCount === 0 ? (
        props?.props?.StoreDetails?.storeWorking === true && (
          <div
            className={classes.addtocartbutton}
            onClick={() => {
              handleAdd(
                localStorage.getItem("productid"),
                props.productname,
                props.image,
                props.markedupProductPrice,
                props.promoprice,
                props.storeid,
                props.storename,
                props.uom,
                props.quantity,
                1
              );
            }}
          >
            {"Add to cart"}
          </div>
        )
      ) : (
        <ButtonGroup
          variant="text"
          style={{
            width: "100%",
            height: "38px",
            userSelect: "none",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            fontSize: "12px",
            border: "1px solid rgba(105, 168, 92, 0.1)",
          }}
          aria-label="outlined button group"
        >
          {productCount === 1 ? (
            <Button
              style={{
                background: "rgba(209, 216, 224, 0.34)",
                border: "none",
                width: "38px",
                height: "38px",
                borderRadius: "10px",
                color: "#85BE49",
                fontSize: "9px",
              }}
              onClick={() => {
                setProductCount(0);
                handleRemove(props.storeid, localStorage.getItem("productid"));
              }}
            >
              <DeleteOutlineIcon />
            </Button>
          ) : productCount === 0 || productCount < 1 ? (
            <Button
              style={{
                background: "rgba(209, 216, 224, 0.34)",
                border: "none",
                width: "38px",
                height: "38px",
                borderRadius: "10px",
                color: "#000000",
                userSelect: "none",
              }}
              disabled
            >
              -
            </Button>
          ) : (
            <Button
              style={{
                background: "rgba(209, 216, 224, 0.34)",
                border: "none",
                width: "38px",
                height: "38px",
                borderRadius: "10px",
                color: "#000000",
                userSelect: "none",
              }}
              onClick={() => {
                handleAdd(
                  localStorage.getItem("productid"),
                  props.productname,
                  props.image,
                  props.markedupProductPrice,
                  props.promoprice,
                  props.storeid,
                  props.storename,
                  props.uom,
                  props.quantity,
                  -1
                );
              }}
            >
              -
            </Button>
          )}
          <Button
            style={{
              border: "none",
              color: "#000000",
              fontSize: "12px",
              width: "100%",
              userSelect: "none",
              background: "#ffffff",
            }}
          >
            {productCount}
          </Button>
          <Button
            disabled={productCount > 49 ? true : false}
            style={
              productCount > 49
                ? {
                    background: "rgba(196, 196, 196)",
                    boxShadow: "0px 9px 17px rgba(0, 0, 0, 0.1)",
                    width: "38px",
                    height: "38px",
                    borderRadius: "10px",
                    color: "#ffffff",
                    userSelect: "none",
                  }
                : {
                    background:
                      "linear-gradient(180deg, #85BE49 0%, #69A85C 100%)",
                    boxShadow: "0px 9px 17px rgba(0, 0, 0, 0.1)",
                    width: "38px",
                    height: "38px",
                    borderRadius: "10px",
                    color: "#ffffff",
                    userSelect: "none",
                  }
            }
            onClick={() => {
              handleAdd(
                localStorage.getItem("productid"),
                props.productname,
                props.image,
                props.markedupProductPrice,
                props.promoprice,
                props.storeid,
                props.storename,
                props.uom,
                props.quantity,
                1
              );
            }}
          >
            +
          </Button>
        </ButtonGroup>
      )}
      {openAlert && (
        // <Snackbar
        //   open={openAlert}
        //   autoHideDuration={1000}
        //   onClose={handleClose}
        //   anchorOrigin={{ vertical: "top", horizontal: "center" }}
        //   style={{position: "fixed", top: "15%", transform: "translatex(-50%)",zIndex:3,  }}
        // >
        //   <Alert
        //     onClose={handleClose}
        //     severity="error"
        //     variant="filled"
        //     anchorOrigin={{ vertical: "top", horizontal: "center" }}
        //     autoHideDuration={3000}
        //     sx={{ width: "100%", zIndex: 3  }}
        //   >
        //     Cannot add more than one store at a time. Please remove any store
        //     from the cart to continue.
        //   </Alert>
        // </Snackbar>
        <Dialog
          PaperProps={{
            style: {
              borderRadius: "13px",
              backgroundColor: "#ffffff",
              width: "450px",
              padding: "25px",
            },
          }}
          // fullScreen={fullScreen}
          maxWidth={"md"}
          open={openAlert}
          // onClose={handleStoreClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {`Replace Cart Item?`}
          </DialogTitle>
          <DialogContent
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              display: "flex",
              overflowX: "hidden",
            }}
            sx={{padding:"0px"}}
          >
            {cartdata.map((cartItem) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                Your cart contains items from {cartItem.storename} do you want
                to discard the selection and add items from {props?.storename}?
                <div
                  style={{
                    width: "80%",
                    marginTop: "15px",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    paddingTop: "8px",
                    display: "flex",
                    gap: "30px",
                  }}
                >
                  <button
                    className="button-replace"
                    onClick={handleStoreClose}
                  >
                    {"No"}
                  </button>

                  <button
                    className="button-replace"
                    key={cartItem.storeid} // Adding a key prop for list rendering
                    onClick={() => removestorefromcart(cartItem.storeid)}
                  >
                    {"Yes"}
                  </button>
                </div>
              </div>
            ))}
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};
export default ExpandButton;
