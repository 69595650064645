import React from 'react';
import Coupons from './Coupons';
import Drawer from '@mui/material/Drawer';
import './Coupon.css';
import { Box } from '@mui/material';
import ApplyCouponTextField from './ApplyCouponTextField';
import { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/';
const ApplyCouponDrawer = (props) => {
  const [state, setState] = React.useState({
    right: false,
  });
 
  useEffect(() => {
    props.ApplyBestCoupon(localStorage.getItem('storeid'), localStorage.getItem('userid'));
  }, [props.isQuantityUpdated]);
    
//    useEffect(() => {

//     props?.getAvailableCouponsList(props?.Cart?.storeid?.id, props?.Cart?.userid?.id)
//     // props?.getAvailableCouponsList(props?.Stores?.map((stores)=>stores.id),props?.Cart?.userid?.id)
//     const bxgy = props.AvailableCoupons.filter(item => item.name === "Buy X Get Y Coupons");
//     const quantity = props?.Cart?.products?.map((items)=>{
//       return items
//     })
//     console.log("items",quantity)
// console.log("bxgy",bxgy) 

// }, [props.isQuantityUpdated])
//console.log("quantity",props.Cart.quantity)

  const RemoveCoupon =()=>{
    
    let storeId= localStorage.getItem("storeid")
    let userId = localStorage.getItem("userid")
    props?.removeAppliedCoupon?.(storeId,userId)
  }

  const toggleDrawer =
    (open) =>
      (event) => {
        if (
          event.type === 'keydown' &&
          ((event).key === 'Tab' ||
            (event).key === 'Shift')
        ) {
          return;
        }
      
        setState({ ...state, right: open });
      };

  const list = () => (
    <Box
      sx={{
        backgroundColor: "#fff",
        height: "auto",
        paddingLeft: { sm: "10px", md: "40px" },
        paddingRight: { sm: "10px", md: "74px" },
        paddingTop: { sm: "10px", md: "20px" },
        paddingBottom: { sm: "10px", md: "100px" },
        width: { md: '580px', sm: '100%' }
      }}
      role="presentation"
    >
      <div
        style={{
          padding: "15px",
        }}
      >
        <ApplyCouponTextField {...props} />
        <br />
        <div className='dashline'></div>
        <div className='coupons-container'>
          <div className='available-coupons-text'>
            {'Available Coupons'}
          </div>
          <Coupons props={props}  setState={setState}/>
        </div>
      </div>
    </Box>
  );

  const theme = useTheme();
  const xlmatch = useMediaQuery(theme.breakpoints.down("lg"));
  

  return (
    <>
      <div className="apply-coupon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
        >
          <path
            d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V6C0.530433 6 1.03914 6.21071 1.41421 6.58579C1.78929 6.96086 2 7.46957 2 8C2 8.53043 1.78929 9.03914 1.41421 9.41421C1.03914 9.78929 0.530433 10 0 10V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V10C19.4696 10 18.9609 9.78929 18.5858 9.41421C18.2107 9.03914 18 8.53043 18 8C18 7.46957 18.2107 6.96086 18.5858 6.58579C18.9609 6.21071 19.4696 6 20 6V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0H2ZM13.5 3L15 4.5L6.5 13L5 11.5L13.5 3ZM6.81 3.04C7.79 3.04 8.58 3.83 8.58 4.81C8.58 5.27943 8.39352 5.72964 8.06158 6.06158C7.72964 6.39352 7.27943 6.58 6.81 6.58C5.83 6.58 5.04 5.79 5.04 4.81C5.04 4.34057 5.22648 3.89036 5.55842 3.55842C5.89036 3.22648 6.34057 3.04 6.81 3.04ZM13.19 9.42C14.17 9.42 14.96 10.21 14.96 11.19C14.96 11.6594 14.7735 12.1096 14.4416 12.4416C14.1096 12.7735 13.6594 12.96 13.19 12.96C12.21 12.96 11.42 12.17 11.42 11.19C11.42 10.7206 11.6065 10.2704 11.9384 9.93842C12.2704 9.60648 12.7206 9.42 13.19 9.42Z"
            fill="#6AA92A"
          />
        </svg>

        {props?.Cart?.couponIsApplied === true  ? (
          <>
          <span className="apply-coupon-text">
            {`${props?.Cart?.couponCode} Applied`}
          </span>
          <span className={xlmatch?"apply-coupon-text":"apply-coupon-text1"}   style={{
            color: "#58B908"
          }} onClick={()=>RemoveCoupon()}>
            {`Remove`}
          </span>
          </>
          
          
        ) : (
          <span className="apply-coupon-text">{`Apply Coupon`}:</span>
        )}

        <span className="apply-coupon-arrow"  onClick={toggleDrawer(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
          >
            <path
              d="M1 6.5H13M13 6.5L7.85714 12M13 6.5L7.85714 1"
              stroke="#6AA92A"
              strokeWidth="1.5"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer(false)}
        PaperProps={{ style: { backgroundColor: "#fff", padding: "15px" } }}
      >
        <span className="drawerCloseIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
            style={{ cursor: "pointer" }}
            onClick={toggleDrawer(false)}
          >
            <path
              d="M10.1562 0.848633C4.55615 0.848633 0 5.82597 0 11.9438C0 18.0616 4.55615 23.0389 10.1562 23.0389C15.7563 23.0389 20.3125 18.0616 20.3125 11.9438C20.3125 5.82597 15.7563 0.848633 10.1562 0.848633ZM13.8335 14.7544C13.9091 14.8328 13.9696 14.927 14.0113 15.0314C14.053 15.1358 14.0752 15.2483 14.0766 15.3622C14.0779 15.4761 14.0584 15.5891 14.0191 15.6947C13.9798 15.8002 13.9216 15.896 13.8478 15.9766C13.7741 16.0572 13.6863 16.1208 13.5897 16.1637C13.4931 16.2066 13.3897 16.228 13.2854 16.2265C13.1811 16.225 13.0782 16.2008 12.9826 16.1552C12.8871 16.1096 12.8008 16.0435 12.729 15.9609L10.1562 13.1509L7.5835 15.9609C7.43579 16.1143 7.23911 16.1985 7.03539 16.1956C6.83167 16.1928 6.63703 16.1031 6.49297 15.9457C6.3489 15.7883 6.26682 15.5757 6.26421 15.3531C6.2616 15.1306 6.33868 14.9157 6.479 14.7544L9.05127 11.9438L6.479 9.13318C6.33868 8.97182 6.2616 8.75696 6.26421 8.5344C6.26682 8.31185 6.3489 8.09922 6.49297 7.94183C6.63703 7.78445 6.83167 7.69478 7.03539 7.69193C7.23911 7.68908 7.43579 7.77328 7.5835 7.92658L10.1562 10.7366L12.729 7.92658C12.8767 7.77328 13.0734 7.68908 13.2771 7.69193C13.4808 7.69478 13.6755 7.78445 13.8195 7.94183C13.9636 8.09922 14.0457 8.31185 14.0483 8.5344C14.0509 8.75696 13.9738 8.97182 13.8335 9.13318L11.2612 11.9438L13.8335 14.7544Z"
              fill="#BDBDBD"
            />
          </svg>
        </span>
        {list()}
      </Drawer>
    </>
  );
}

export default ApplyCouponDrawer