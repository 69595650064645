// PopularProducts.js
import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const PopularProducts = ({ popularProductsList, onPopularProductClick, props }) => {
  const ismobileScreen =useMediaQuery(useTheme().breakpoints.up("xs"));
  return (
    <>
    {!ismobileScreen && (
    <div
      id="popularProducts"
      className="popular_product_conatiner"

    >
      <p className="porpular_prdts_text_head">Popular On NearShopz</p>
      <div className="popular_prdts_div">
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {popularProductsList?.map((popularproduct) => (

            <div style={{ display: "flex", gap: "20px", height: "100%"}}>
              <div
                // className="popular_prdt_child_div"
                key={popularproduct.productId}
                onClick={() => handleClick(popularproduct.productName)}
                style={{ width: "80px", height: "78px", background: "#F2F2F2", borderRadius: "15px" }}
              >
                <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center",cursor:"pointer"}}>

                  <img
                    src={popularproduct.image}
                    alt="Product" 
                    width="60px"
                    height="60px"
                    style={{ borderRadius: "50%",border:"4px solid white"}}
                  />
                </div>

              </div>
              <div style={{height:"78px"}}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                {popularproduct.productName}
              </div>
              </div>
            </div>

          ))}
        </div>
      </div>
    </div>
    
  )}
  </>
  );
  function handleClick(productName) {
    onPopularProductClick(productName);
    props?.updateRecentSearch(productName);
  }
};

export default PopularProducts;
