import React from 'react';
import Dialog from '@mui/material/Dialog';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import CouponPopup from './CouponPopup';
import './Coupon.css';
import {message} from 'antd'
import {useDispatch} from 'react-redux'

const Coupons = ({ props, setState }) => {

    const [open, setOpen] = React.useState(false);
    useEffect(() => {

        props?.getAvailableCouponsList(props?.Cart?.storeid?.id, props?.Cart?.userid?.id)
        // props?.getAvailableCouponsList(props?.Stores?.map((stores)=>stores.id),props?.Cart?.userid?.id)
    }, [])

    useEffect(()=>{
        if(props.applycouponerror){
            message.error({
                content: props.applycouponerror,
                className: "custom-class",
                style: {
                  marginTop: "20vh",
                  marginLeft: "40vh",
                },
              });
        }else if(props.applycouponsuccess){
            setOpen(true);
        }
    },[props.applycouponerror,props.applycouponsuccess])
    const [selectedindex, setSelectedIndex] = React.useState(null);
    
const dispatch=useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = (couponId, storeId, totalprice) => {
       // setOpen(true);
        props?.applyCoupon?.(couponId, storeId, totalprice)

        setTimeout(() => {
            dispatch({
                type:"APPLY_COUPON_CLEAR"
            })
        }, 2000);

    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>


                <div className='root1'>
                    
            {props?.AvailableCoupons?.map((coupon, i) =>(
                <>
                    <div className='couponHeader'>
                        <div style={{display:"flex",flexDirection:"column",flexWrap:"wrap",width:"90%"}}>
                        <div className='couponName'>
                            {coupon?.couponCode}
                        </div>
                        <div className='offer'>
                            {coupon?.description[0]}
                        </div>
                        <div className='description1'>
                            {coupon?.description[1]}
                        </div>
                        </div>
                        <span className='offerPercentage'>

                            {coupon?.type === 1 && `${coupon?.discountPercent}% OFF`}
                            {coupon?.type === 2 && `FLAT OFF`}
                            {coupon?.type === 3 && `B ${coupon?.buyItemNo} G ${coupon?.getItemNo}`}
                            {coupon?.type === 4 && `DELIVERY OFF`}
                        </span>
                        <div className="circle1">
                            <div className="half1">
                                <div className="content1" style={{ background: "transparent" }}></div>

                                <div className="content1" style={{ background: "white" }}></div>
                            </div>
                        </div>

                        <div className="circle2">
                            <div className="half2">
                                <div className="content2" style={{ background: "white" }}></div>

                                <div className="content2" style={{ background: "transparent" }}></div>
                            </div>
                        </div>
                    </div>
                    {selectedindex !== i &&
                        <div className='moreButtonDiv'>
                            <span className='moreIcon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <rect width="16" height="16" fill="#6FAD59" />
                                    <path d="M13 9.24254H8.71429V13.9244H7.28571V9.24254H3V7.6819H7.28571V3H8.71429V7.6819H13V9.24254Z" fill="white" />
                                </svg>
                            </span>

                            <span className='moreText' onClick={() => { setSelectedIndex(i) }}>
                                {'More'}
                            </span>
                        </div>
                    }
                    {selectedindex === i &&

                        <ul className="couponConditionslist">
                            {coupon?.description?.slice(2).map((desc, j) => (
                                <li key={j + 2}>{desc}</li>
                            ))}
                        </ul>

                    }
                    <div className='couponApplyButton' onClick={() => handleClickOpen(coupon.id, props.Cart?.storeid?.id, props?.Cart?.totalPrice)}>
                        {'Apply'}
                    </div>
                    <div className='leftCircle' />
                    <div className='rightCircle' />



                    <div className='Dashedline'></div>
                </>
            )
                
                )}
                </div>
           
            <Dialog
                fullScreen={fullScreen}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ style: { padding: '40px' } }}
            >
                <CouponPopup props={props} setOpen={setOpen} open={open} setState={setState} />
            </Dialog>
        </>
    )
}

export default Coupons