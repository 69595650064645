import React from 'react';
import './style.css';

const DepartmentCarousel = (props) => {

    const [open, setOpen] = React.useState(false);
    const [categoryopen, setCategoryOpen] = React.useState(false);
    const [category1open, setCategory1Open] = React.useState(false);
    const setDepartmentId = (id) => {
        localStorage.setItem("departmentid", id);
        props.props.getDepartmentProducts({
            storeid: localStorage.getItem('storeid'),
            departmentid: localStorage.getItem('departmentid')
        });
        props.navigate(`/stores/categories/departmentproducts`);
    };
    const setNoCategoryDepartmentId = (id) => {
        localStorage.setItem("departmentid", id);
        setOpen(!open);
        props.props.getCategorylist({ storeid: localStorage.getItem('storeid'), categoryid: id, userid: localStorage.getItem('userid') });
        props.navigate(`/stores/categories/category`);
    };
    const setCategoryId = (id) => {
        localStorage.setItem("categoryid", id);
        props.props.getCategoryProducts({
            storeid: localStorage.getItem('storeid'),
            categoryid: localStorage.getItem('categoryid'),
            userid: localStorage.getItem('userid')
        });
        props.navigate(`/stores/categories/categoryproducts`);
    };
    const setNoCategoryId = (id) => {
        localStorage.setItem("categoryid", id);
        setCategoryOpen(!open);
        props.props.getCategory1list({ storeid: localStorage.getItem('storeid'), categoryid: localStorage.getItem("categoryid"), userid: localStorage.getItem('userid') });
        props.navigate(`/stores/categories/category1`);
    };
    const setCategory1Id = (id) => {
        localStorage.setItem("category1id", id);
        props.props.getCategory1Products({
            storeid: localStorage.getItem('storeid'),
            categoryid1: localStorage.getItem('category1id'),
            userid: localStorage.getItem('userid')
        });
        props.navigate(`/stores/categories/category1products`);
    };
    const setNoCategory1Id = (id) => {
        localStorage.setItem("category1id", id);
        setCategory1Open(!open);
        props.props.getCategory2list({ storeid: localStorage.getItem('storeid'), categoryid: id, userid: localStorage.getItem('userid') });
        props.navigate(`/stores/categories/category2`);
    };
    const setCategory2Id = (id) => {
        localStorage.setItem("category2id", id);
        props.props.getCategory2Products({
            storeid: localStorage.getItem('storeid'),
            categoryid2: localStorage.getItem('category2product'),
            userid: localStorage.getItem('userid')
        });
        props.navigate(`/stores/categories/category2products`);
    };
    React.useEffect(() => {
        if (!props.props.isDepartments) {
            if (props.params.storeid)
                props.props.getDepartments(props.params.storeid);
            else
                props.props.getDepartments(localStorage.getItem('storeid'));
        }
    },
        // eslint-disable-next-line
        [])
    return (
        <>
            <div className='category-list-container'>
                <div className='horizontal-scroll-list'>
                    <div className='category-container-div'>
                        {props.props.isDepartments && props.props.isDepartments === true &&
                            props.props.Departments && props.props.Departments !== null && props.props.Departments.length > 0 &&
                            props.props.Departments.map(({ name, id, childCategoryPresent, departmentProducts }) =>
                                departmentProducts.items.length > 0 &&
                                    childCategoryPresent === false ? (
                                    <div className='category-padding'>
                                        <div className='category-list-div' onClick={() => {
                                            setDepartmentId(id);
                                        }}>
                                            <div className='category-name-div' style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                                            {name}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className='category-padding'>
                                            <div className='category-list-div' onClick={() => {
                                                setNoCategoryDepartmentId(id);
                                            }}>
                                                <div className='category-name-div'>
                                                    {name}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default DepartmentCarousel