import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    addtocartbutton: {
        width: '100%',
        height:'38px',
        background:'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)',
        color: '#ffffff',
        // border:'0.5px solid rgba(0, 0, 0, 0.03)',
        boxShadow:'0px 9px 17px rgba(0, 0, 0, 0.1)',
        borderRadius:'10px',
        paddingTop:'5px',
        paddingBottom:'5px',
        paddingLeft:'5px',
        paddingRight:'5px',
        boxSizing:'border-box',
        fontSize:'14px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        cursor:'pointer',
        '@media (max-width: 600px)': {
            // Styles for smaller screens
            paddingRight:'0px',
            paddingLeft:'0px',
            
        },
    },
}));
