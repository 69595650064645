// import * as React from 'react';
// import { useLocation } from 'react-router-dom';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import Notifications from '../Notifications';
// import Account from '../Account';
// import Cart from '../Cart/index';
// import Location from '../Location';
// import StoreSearch from '../Search/Storesearch';
// import logo from '../../Assets/Images/WhiteLogo.svg';
// import ProductSearch from '../Search/ProductSearch';
// import { Drawer, List, ListItem, useMediaQuery, useTheme } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import io from 'socket.io-client';
// import './index.css';

// export default function PrimarySearchAppBar(props) {
//   const location = useLocation();
//   const desktop = useMediaQuery(useTheme().breakpoints.up('sm'));
//   const [state, setState] = React.useState({
//     bottom: false
//   });
//   const medium = useMediaQuery(useTheme().breakpoints.down('md'));
//   const [socket, setSocket] = React.useState(null);

//   React.useEffect(() => {
//     const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`);
//     setSocket(newSocket);
//     return () => newSocket.close();
//   }, [setSocket]);

//   const toggleDrawer = (anchor, value) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setState({ ...state, [anchor]: value });

//   };

  // const routoToHome = () => {
  //   props.navigate('/stores')
  // };

//   React.useEffect(() => {
//     props.getLiveOrdersLength(localStorage.getItem('userid'));
//   },
//     // eslint-disable-next-line
//     []);
//     //const ismobileScreen = useMediaQuery({ query: "(max-width: 768px)" });
//   return (
//     <>
//       <Box sx={{ flexGrow: 1 }}>
//         <AppBar position="fixed"
//         sx={{
//           background: 'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)',
//           boxShadow: 'none',
//           paddingLeft: { xs: '0px', sm: '20px', md: '90px', lg: '90px' },
//           paddingRight: { xs: '0px', md: '30px' },
//         }}>
//           <Toolbar sx={{ width: "97%", maxWidth: '100%' }}>
//             <Box
//             sx={{
//               display: { xs: 'none', sm: 'flex' },
//               paddingRight: { sm: '25px', xs: 0 },
//               justifyContent: location.pathname==='/Search'?'flex-start':'center',
//               alignItems: 'center',
//               width: '100%',
//               }}>
//               <img src={logo} alt="NearShopz-Logo"
//                 onClick={() => routoToHome()}
//                 style={{
//                   width: '206px',
//                   height: '36px',
//                   cursor: 'pointer',
//                   marginLeft:location.pathname==='/Search'&& !medium && '110px',
//                   marginRight:location.pathname==='/stores'&&'60px',
//                 // marginLeft:"140px"
//                   }} />
//             </Box>
//             <Box
//             sx={{
//               display: { xs: 'flex', sm: 'none' },
//               paddingRight: { sm: '25px', xs: 0 },
//               justifyContent: 'center',
//               alignItems: 'center',
//               width: '100%'
//             }}>
//               <img src={logo} alt="NearShopz-Logo"
//                 onClick={() => routoToHome()}
//                 style={{
//                   width: '206px',
//                   height: '36px',
//                   cursor: 'pointer',
//                   display:"flex",
//                   justifyContent:"center" ,
//                   alignItems:"center"
//                   }} />
//             </Box>
//             {desktop ?
//               ((location.pathname === '/stores' || location.pathname === '/stores/Account/Addresses'
//                 || location.pathname === '/stores/Account/MyOrders') ?
//                 <StoreSearch {...props} />
//                 :location.pathname==='/Search'? null:
//                 <ProductSearch {...props} />)
//               : null
//             }
//             <Box sx={{ flexGrow: 1 }} />
//             <Box
//             sx={{
//               display: {
//                 xs: 'flex',
//                 sm: 'flex',
//                 md: 'flex' },
//                 paddingLeft: '15px'
//               }}
//               >
//              {desktop && <Location componentkey='navbar' {...props} />}
//              {desktop &&
//               <div style={{ padding: '15px' }}>

//                 <Notifications props={props} socket={socket} />
//               </div>
//               }
//                {desktop &&
//               <div style={{ padding: '15px', width: 'auto' }}>

//                 <Account {...props} socket={socket} />
//               </div>
//               }
//                {desktop &&
//               <div style={{ padding: '15px' }}>

//                 <Cart {...props} />
//               </div>
//               }
//             </Box>
//           </Toolbar>
//           <Toolbar sx={{ display: { xs: 'flex', md: 'none' } }}>
//             {(location.pathname === '/stores' || location.pathname === '/stores/Account/Addresses'
//               || location.pathname === '/stores/Account/MyOrders') ?
//               <StoreSearch {...props} />
//               :
//               <ProductSearch {...props} />
//             }
//           </Toolbar>
//         </AppBar>
//       </Box>
      // <div className='child-div'>
      //   {props.children}
      //   <Drawer
      //     anchor={'bottom'}
      //     open={state.bottom}
      //     onClose={toggleDrawer('bottom', false)}
      //     sx={{
      //       '& .MuiDrawer-paper':
      //       {
      //         boxSizing: 'border-box',
      //         width: '100%',
      //         background: 'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)',
      //         borderTopLeftRadius: '13px',
      //         borderTopRightRadius: '13px',
      //         justifyContent: 'center',
      //         alignItems: 'center'
      //       },
      //     }}
      //   >
      //     <div
      //       style={{
      //         width: '100%',
      //         justifyContent: 'center',
      //         alignItems: 'center',
      //         display: 'flex',
      //         paddingTop: '15px',
      //         paddingBottom: '15px'
      //       }}>
      //       <span
      //       style={{
      //         width: '100%',
      //         float: 'right',
      //         textAlign: 'right',
      //         cursor: 'pointer',
      //         color: '#fff',
      //         paddingRight: '20px'
      //       }}
      //         onClick={toggleDrawer('bottom', false)}>
      //         <CloseIcon />
      //       </span>
      //     </div>
      //     <List>
      //       <ListItem
      //       style={{
      //         textAlign: 'center',
      //         paddingBottom: '15px',
      //         display: 'flex',
      //         justifyContent: 'center',
      //         alignItems: 'center',
      //         width: '100%'
      //          }}>

      //         <Cart {...props} />
      //       </ListItem>
      //       <ListItem
      //       style={{
      //         textAlign: 'center',
      //         paddingBottom: '15px',
      //         display: 'flex',
      //         justifyContent: 'center',
      //         alignItems: 'center',
      //         width: '100%'
      //          }}>
      //         <Account {...props} />
      //       </ListItem>
      //       <ListItem
      //       style={{
      //         textAlign: 'center',
      //         paddingBottom: '15px',
      //         display: 'flex',
      //         justifyContent: 'space-evenly',
      //         alignItems: 'center',
      //         width: '100%'
      //          }}>
      //         <Notifications props={props} socket={socket} />
      //       </ListItem>
      //     </List>
      //   </Drawer>
      // </div>

      // <AppBar position="fixed" sx={{
      //   display: { md: 'none', xs: 'flex', sm: 'none' },
      //   background: 'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)', boxShadow: 'none',
      //   paddingLeft: { xs: '0px', sm: '20px', md: '90px', lg: '90px' },
      //   paddingRight: { xs: '0px', md: '30px' },
      //   bottom: 0,
      //   top: 'auto',
      // }}>
      //   <Toolbar>
      //   {!desktop && <Location componentkey='navbar/xs' {...props} />}

      //   </Toolbar>
      //   <Toolbar>
      //     <Box sx={{ display: { xs: 'flex', md: 'none' }, gap:"0px",justifyContent:"space-between", alignItems: 'center', width: '100%' }}>
      //       <div style={{ paddingRight: '15px', paddingTop: '15px', paddingBottom: '15px',paddingLeft:"15px" }}>
      //         <Notifications props={props} socket={socket} />
      //       </div>
      //       <div style={{ padding: '0px' }}>
      //         <Account {...props} />
      //       </div>
      //       <div style={{ paddingLeft: '34px', paddingRight:"24px" }}>
      //         <Cart {...props} />
      //       </div>
      //     </Box>
      //   </Toolbar>
      // </AppBar>
//     </>
//   );
// }

// import * as React from "react";
// import { useLocation } from "react-router-dom";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
// import Notifications from "../Notifications";
// import Account from "../Account";
// import Cart from "../Cart/index";
// import Location from "../Location";
// import StoreSearch from "../Search/Storesearch";
// import logo from "../../Assets/Images/WhiteLogo.svg";
// import ProductSearch from "../Search/ProductSearch";
// import { Drawer, List, ListItem, useMediaQuery, useTheme } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import io from "socket.io-client";
// import "./index.css";

// export default function PrimarySearchAppBar(props) {
//   const location = useLocation();
//   const theme = useTheme();
//   const desktop = useMediaQuery(theme.breakpoints.up("sm"));
//   const medium = useMediaQuery(theme.breakpoints.down("md"));
//   const [state, setState] = React.useState({
//     bottom: false,
//   });
//   const [socket, setSocket] = React.useState(null);

//   React.useEffect(() => {
//     const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`);
//     setSocket(newSocket);
//     return () => newSocket.close();
//   }, [setSocket]);

//   const toggleDrawer = (anchor, value) => (event) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setState({ ...state, [anchor]: value });
//   };

//   const routeToHome = () => {
//     props.navigate("/stores");
//   };

//   React.useEffect(() => {
//     props.getLiveOrdersLength(localStorage.getItem("userid"));
//     // eslint-disable-next-line
//   }, []);

//   return (
//     <>
//       <Box sx={{ flexGrow: 1,paddingRight:"70px" }}>
//         <AppBar
//           position="fixed"
//           sx={{
//             background: "linear-gradient(180deg, #85BE49 0%, #69A85C 100%)",
//             boxShadow: "none",
//             paddingLeft: { xs: "0px", sm: "20px", md: "90px", lg: "90px" },
//             paddingRight: { xs: "0px", md: "30px" },
//           }}
//         >
//           <Toolbar sx={{ width: "97%", maxWidth: "100%" }}>
//             <Box
//               sx={{
//                 display: { xs: "none", sm: "flex" },
//                 paddingRight: { sm: "25px", xs: 0 },
//                 justifyContent:
//                   location.pathname === "/Search" ? "flex-start" : "center",
//                 alignItems: "center",
//                 width: "100%",
//               }}
//             >
//               <img
//                 src={logo}
//                 alt="NearShopz-Logo1"
//                 onClick={() => routeToHome()}
//                 style={{
//                   width: "216px",
//                   height: "36px",
//                   cursor: "pointer",
//                   marginLeft:
//                     location.pathname === "/Search" && !medium && "110px",
//                   marginRight:
//                     location.pathname === "/stores" && !medium && "60px",
//                 }}
//               />
//             </Box>
//             <Box
//               sx={{
//                 display: { xs: "flex", sm: "none" },
//                 paddingRight: { sm: "25px", xs: 0 },
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: "100%",
//               }}
//             >
//               <img
//                 src={logo}
//                 alt="NearShopz-Logo"
//                 onClick={() => routeToHome()}
//                 style={{
//                   width: "100%",
//                   height: "36px",
//                   cursor: "pointer",
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                 }}
//               />
//             </Box>
            // {/* Display the appropriate search component based on the path and screen size */}
            // {/* {desktop && (
            //   (location.pathname === '/stores' || location.pathname === '/stores/Account/Addresses' || location.pathname === '/stores/Account/MyOrders')
            //     ? <StoreSearch {...props} />
            //     : (location.pathname !== '/Search' && <ProductSearch {...props} />)
            // )} */}
//             <Box sx={{ flexGrow: 1 }} />
//             <Box
//               sx={{
//                 display: {
//                   xs: "flex",
//                   sm: "flex",
//                   md: "flex",
//                 },
//                 paddingLeft: "15px",
//               }}
//             >
//               {desktop && <Location componentkey="navbar" {...props} />}
//               {desktop && (
//                 <div style={{ padding: "15px" }}>
//                   <Notifications props={props} socket={socket} />
//                 </div>
//               )}
//               {desktop && (
//                 <div style={{ padding: "15px", width: "auto" }}>
//                   <Account {...props} socket={socket} />
//                 </div>
//               )}
//               {desktop && (
//                 <div style={{ padding: "15px" }}>
//                   <Cart {...props} />
//                 </div>
//               )}
//             </Box>
//           </Toolbar>
//           <Toolbar sx={{ display: { xs: "flex", md: "none" } }}>
//             {location.pathname === "/stores" ||
//             location.pathname === "/stores/Account/Addresses" ||
//             location.pathname === "/stores/Account/MyOrders" ? (
//               <StoreSearch {...props} />
//             ) : (
//               <ProductSearch {...props} />
//             )}
//           </Toolbar>
//         </AppBar>
//       </Box>
//       <div className="child-div">
//         {props.children}
//         <Drawer
//           anchor={"bottom"}
//           open={state.bottom}
//           onClose={toggleDrawer("bottom", false)}
//           sx={{
//             "& .MuiDrawer-paper": {
//               boxSizing: "border-box",
//               width: "100%",
//               background: "linear-gradient(180deg, #85BE49 0%, #69A85C 100%)",
//               borderTopLeftRadius: "13px",
//               borderTopRightRadius: "13px",
//               justifyContent: "center",
//               alignItems: "center",
//             },
//           }}
//         >
//           <div
//             style={{
//               width: "100%",
//               justifyContent: "center",
//               alignItems: "center",
//               display: "flex",
//               paddingTop: "15px",
//               paddingBottom: "15px",
//             }}
//           >
//             <span
//               style={{
//                 width: "100%",
//                 float: "right",
//                 textAlign: "right",
//                 cursor: "pointer",
//                 color: "#fff",
//                 paddingRight: "20px",
//               }}
//               onClick={toggleDrawer("bottom", false)}
//             >
//               <CloseIcon />
//             </span>
//           </div>
//           <List>
//             <ListItem
//               style={{
//                 textAlign: "center",
//                 paddingBottom: "15px",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: "100%",
//               }}
//             >
//               <Cart {...props} />
//             </ListItem>
//             <ListItem
//               style={{
//                 textAlign: "center",
//                 paddingBottom: "15px",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: "100%",
//               }}
//             >
//               <Account {...props} />
//             </ListItem>
//             <ListItem
//               style={{
//                 textAlign: "center",
//                 paddingBottom: "15px",
//                 display: "flex",
//                 justifyContent: "space-evenly",
//                 alignItems: "center",
//                 width: "100%",
//               }}
//             >
//               <Notifications props={props} socket={socket} />
//             </ListItem>
//           </List>
//         </Drawer>
//       </div>
//       <AppBar
//         position="fixed"
//         sx={{
//           display: { md: "none", xs: "flex", sm: "none" },
//           background: "linear-gradient(180deg, #85BE49 0%, #69A85C 100%)",
//           boxShadow: "none",
//           paddingLeft: { xs: "0px", sm: "20px", md: "90px", lg: "90px" },
//           paddingRight: { xs: "0px", md: "30px" },
//           bottom: 0,
//           top: "auto",
//         }}
//       >
//         <Toolbar>
//           {!desktop && <Location componentkey="navbar/xs" {...props} />}
//         </Toolbar>
//         <Toolbar>
//           <Box
//             sx={{
//               display: { xs: "flex", md: "none" },
//               gap: "0px",
//               justifyContent: "space-between",
//               alignItems: "center",
//               width: "100%",
//             }}
//           >
//             <div
//               style={{
//                 paddingRight: "15px",
//                 paddingTop: "15px",
//                 paddingBottom: "15px",
//                 paddingLeft: "15px",
//               }}
//             >
//               <Notifications props={props} socket={socket} />
//             </div>
//             <div style={{ padding: "0px" }}>
//               <Account {...props} />
//             </div>
//             <div style={{ paddingLeft: "34px", paddingRight: "24px" }}>
//               <Cart {...props} />
//             </div>
//           </Box>
//         </Toolbar>
//       </AppBar>
//     </>
//   );
// }


import * as React from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Notifications from "../Notifications";
import Account from "../Account";
import Cart from "../Cart/index";
import Location from "../Location";
import StoreSearch from "../Search/Storesearch";
import logo from "../../Assets/Images/WhiteLogo.svg";
import ProductSearch from "../Search/ProductSearch";
import { Drawer, List, ListItem, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import io from "socket.io-client";
import "./index.css";

export default function PrimarySearchAppBar(props) {
  const location = useLocation();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const medium = useMediaQuery(theme.breakpoints.down("sm"));
  const large = useMediaQuery(theme.breakpoints.down("lg"));
  const [state, setState] = React.useState({
    bottom: false,
  });
  const [socket, setSocket] = React.useState(null);

  React.useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`);
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

    const toggleDrawer = (anchor, value) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: value });
  };

  const routoToHome = () => {
    props.navigate("/stores");
  };

  // React.useEffect(() => {
  //   props.getLiveOrdersLength(localStorage.getItem("userid"));
  // }, [props]);
  //const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isTabletOrAbove = useMediaQuery(theme.breakpoints.up('sm'));
  const screen = useMediaQuery(useTheme().breakpoints.up("lg"));
  const extralarge = useMediaQuery(useTheme().breakpoints.up("xl"));
  const extralargeDown = useMediaQuery(useTheme().breakpoints.down("xl"));
  const mediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          background: "linear-gradient(180deg, #85BE49 0%, #69A85C 100%)",
          boxShadow: "none",
          //paddingX: { xs: 0, sm: 2, md: 9 },
          paddingLeft:location.pathname === "/Search" && isDesktop ? "20px" :"0px"
        }}
      >
        <Toolbar disableGutters
  sx={{
   "&.MuiToolbar-root":{
                width:isTablet && location.pathname === '/Search'  ? "300px" : extralarge || screen ? "100%": "100%",
                minHeight: isTablet ? "75px" : isMobile ? "60px": "70px",
              },
    maxWidth: "100%",
    
    paddingLeft: "0px",
    paddingRight: "0px",
  }}>
          {/* Logo Section */}
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
              marginLeft: isDesktop && location.pathname !== '/Search' && "-60px" ,
              justifyContent: location.pathname === "/Search" ? "flex-start" : "center",
              width: screen ? "71%": "100%",
              paddingRight: {  sm: 1, xs: 0,lg :0, xl:0,md:0 },
            }}
          >
            <img
              src={logo}
              alt="NearShopz Logo"
              onClick={() => routoToHome()}
              //onClick={routeToHome}
              style={{
                width: isTablet ? "155px" : screen || extralarge ? "209px" : "216px",
                height: "36px",
                cursor: "pointer",
                marginLeft: location.pathname === "/Search" && isTabletOrAbove ? "20px" : screen ? "30px": isTablet ?"25px" : "56px",
                marginRight: location.pathname === "/stores" && !isTabletOrAbove ? "60px" : "0px",
              }}
            />
          </Box>

          {/* Mobile Logo Section */}
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop:"20px"
            }}
          >
            <img
              src={logo}
              alt="NearShopz Logo"
              onClick={() => routoToHome()}
              style={{
                width: "250px",
                height: "36px",
                cursor: "pointer",
              }}
            />
          </Box>

          {/* Search Bar for Tablet and Desktop Screens */}
          {screen ? (
            <Box sx={{width:"100%"}}>
          {isTabletOrAbove && (
            location.pathname === '/stores' || 
            location.pathname === '/stores/Account/Addresses' || 
            location.pathname === '/stores/Account/MyOrders'
              ? <StoreSearch {...props} />
              : (location.pathname !== '/Search' && <ProductSearch {...props} />)

          )}
          </Box>
        ):(
          <>
             {isTabletOrAbove && (
            location.pathname === '/stores' || 
            location.pathname === '/stores/Account/Addresses' || 
            location.pathname === '/stores/Account/MyOrders'
              ? <StoreSearch {...props} />
              : (location.pathname !== '/Search' && <ProductSearch {...props} />)
          )}
          </>
        )}

          <Box sx={{ flexGrow: 1,display:"flex",justifyContent:"space-between" }} />

          {/* Tablet and Desktop Icons */}
          <Box sx={{ display: isTabletOrAbove ? 'flex' : 'none', paddingLeft: 2,gap: location.pathname === "/Search" && mediumDown   ?  "30px" : screen ? "35px" : isTabletOrAbove ? "22px" :"20px",paddingRight: screen ? 5 :  location.pathname === "/Search" && screen ? "20px": 2}}>
            <Location componentkey="navbar" {...props} />
            <Box sx={{ padding: isDesktop ? "16px" : "8px" }}>
              <Notifications props={props} socket={socket} />
            </Box>
            <Box sx={{ padding: isDesktop ? "16px" : "8px"  }}>
              <Account {...props} socket={socket} />
            </Box>
            <Box sx={{ padding: isDesktop ? "16px" : "8px"  }}>
              <Cart {...props} />
            </Box>
          </Box>
        </Toolbar>

        {/* Mobile Search Bar */}
        {isMobile && (
          <Toolbar sx={{ display: { xs: "flex", md: "none" }, width: "100%",minHeight:"10px",marginTop:"20px" }}>
            {location.pathname === "/stores" ||
            location.pathname === "/stores/Account/Addresses" ||
            location.pathname === "/stores/Account/MyOrders" ? (
              <StoreSearch {...props} />
            ) : (location.pathname !== '/Search' && <ProductSearch {...props} />)}
          </Toolbar>
        )}
      </AppBar>
    </Box>
      {/* Drawer for Bottom Navigation (Mobile) */}
      <div className='child-div'>
        {props.children}
        <Drawer
          anchor={"bottom"}
          open={state.bottom}
          onClose={toggleDrawer("bottom", false)}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
              background: "linear-gradient(180deg, #85BE49 0%, #69A85C 100%)",
              borderTopLeftRadius: "13px",
              borderTopRightRadius: "13px",
              justifyContent: "center",
              alignItems: "center",
            },
            paddingBottom: desktop ? "0px" : "40px",
          }}
        >
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              paddingTop: '30px',
              paddingBottom: '15px'
            }}>
            <span
            style={{
              width: '100%',
              float: 'right',
              textAlign: 'right',
              cursor: 'pointer',
              color: '#fff',
              paddingRight: '20px'
            }}
              onClick={toggleDrawer('bottom', false)}>
              <CloseIcon />
            </span>
          </div>
          <List>
            <ListItem
            style={{
              textAlign: 'center',
              paddingBottom: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
               }}>

              <Cart {...props} />
            </ListItem>
            <ListItem
            style={{
              textAlign: 'center',
              paddingBottom: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
               }}>
              <Account {...props} />
            </ListItem>
            <ListItem
            style={{
              textAlign: 'center',
              paddingBottom: '15px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%'
               }}>
              <Notifications props={props} socket={socket} />
            </ListItem>
          </List>
        </Drawer>
      </div>

      <AppBar
        position="fixed"
        sx={{
          display: { md: "none", xs: "flex", sm: "none" },
          background: "linear-gradient(180deg, #85BE49 0%, #69A85C 100%)",
          boxShadow: "none",
          paddingLeft: { xs: "0px", sm: "20px", md: "90px", lg: "90px" },
          paddingRight: { xs: "0px", md: "30px" },
          bottom: 0,
          top: "auto",
        }}
      >
        <Toolbar>
        {!desktop && <Location componentkey='navbar/xs' {...props} />}

        </Toolbar>
        <Toolbar>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap:"0px",justifyContent:"space-between", alignItems: 'center', width: '100%' }}>
            <div style={{ paddingRight: '15px', paddingTop: '15px', paddingBottom: '15px',paddingLeft:"15px" }}>
              <Notifications props={props} socket={socket} />
            </div>
            <div style={{ padding: '0px' }}>
              <Account {...props} />
            </div>
            <div style={{ paddingLeft: '34px', paddingRight:"24px" }}>
              <Cart {...props} />
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
