
import React from 'react';
import { Grid, SvgIcon } from '@mui/material';
import { CANCELLATION_POLICY_HEADING, CANCELLATION_POLICY_LINK, CANCELLATION_POLICY_NOTE, CANCELLATION_POLICY_SUB_TEXT } from './Policytext';
import Card from './Card';
import { ReactComponent as CancellationIcon } from '../../Assets/Icons/CancellationIcon.svg';
import { makeStyles } from '@mui/styles';

const CancellationPolicyCard = (props) => {

    const redirectToCancelation =()=>{
        props.navigate('/refundandcancelationpolicy')
    }

    const useStyles = makeStyles(() => ({
        root: {
            color: '#000',
            fontFeatureSettings: 'clig off, liga off',
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '1.375rem',
            paddingLeft:"22px",
            wordBreak:"break-word"
        },
        noteDiv: {
            paddingTop:'1rem',
            fontSize:'0.75rem',
            fontWeight:400
        },
        noteText: {
            color: 'rgba(255, 4, 4, 0.75)',
        },
        subText: {
            color: 'rgba(0, 0, 0, 0.50)',
            fontSize:'0.75rem',
            fontWeight:400
        },
        policyLink:{
            paddingTop:'0.56rem',
            color:'#6AA92A',
            fontSize:'0.75rem',
            fontWeight:600,
            cursor:'pointer'
        }
    }));

    const classes = useStyles();

  return (
    <Card hasBorder={true} borderPosition={"bottom"}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={0.25} md={0.25} lg={0.25} sx={{display:"flex",alignItems:"center"}}>
              <SvgIcon component={CancellationIcon} inheritViewBox />
            </Grid>
            <Grid item xs={11.5} md={11.5} lg={11.5} className={classes.root}>
              {CANCELLATION_POLICY_HEADING}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} className={classes.noteDiv}>
          <span className={classes.noteText}>{"Note: "}</span>
          {CANCELLATION_POLICY_NOTE}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          paddingTop={"0.63rem"}
          className={classes.subText}
        >
          {CANCELLATION_POLICY_SUB_TEXT}
        </Grid>
        <Grid item xs={12} md={12} lg={12} className={classes.policyLink}>
          <div onClick={()=>redirectToCancelation()}>{CANCELLATION_POLICY_LINK}</div>
        </Grid>
      </Grid>
    </Card>
  );
}

export default CancellationPolicyCard