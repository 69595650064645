import React from 'react';
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const RootStyle = styled('span')(({ theme, ownerState }) => {
  const { color, variant } = ownerState;

  const styleFilled = (color) => ({
    color: color === 'Order-Placed' ? 'rgb(124, 179, 66)' :
      color === 'Order-Accepted' ? '#FFB800' :
        color === 'Order-Ready' ? 'rgba(0, 191, 165, 1)' :
        color === 'Out-For-Delivery' ? 'rgba(0, 191, 163, 1)' :
          color === 'Order-Cancelled' ? 'rgb(234, 67, 53)' :
          color === 'Order-Rejected' ? 'rgb(234, 67, 53)' :
            color === 'Shopping-In-Progress' ? 'rgb(130, 119, 23)' : 
            color === 'Completed' ? 'rgba(0, 191, 165, 1)' :'#fff',
    backgroundColor: color === 'Order-Placed' ? 'rgba(124, 179, 66, 0.1)' :
      color === 'Order-Accepted' ? 'rgba(255, 184, 0,0.1)' :
        color === 'Order-Ready' ? 'rgba(0, 191, 165, 0.1)' :
        color === 'Out-For-Delivery' ? 'rgba(0, 191, 163, 0.2)' :
          color === 'Order-Cancelled' ? 'rgba(234, 67, 53,0.1)' :
          color === 'Order-Rejected' ? 'rgba(234, 67, 53,0.1)' :
            color === 'Shopping-In-Progress' ? 'rgba(130, 119, 23,0.1)' : 
            color === 'Completed'?'rgba(0, 191, 165, 0.1)':'#fff',
  });

  return {
    height: 31,
    width: '100%',
    lineHeight: '16px',
    borderRadius: 10,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    color: theme.palette.grey[800],
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    backgroundColor: theme.palette.grey[300],

    ...(color !== 'default'
      ? {
        ...(variant === 'filled' && { ...styleFilled(color) }),
      }
      : {
        ...(variant === 'filled' && { ...styleFilled(color) }),
      })
  };
});

// ----------------------------------------------------------------------

export default function StatusLabel({ color = 'default', variant = 'filled', children, ...other }) {
  return (
    <RootStyle ownerState={{ color, variant }} {...other}>
      {children}
    </RootStyle>
  );
}

StatusLabel.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'default',
    'placed',
    'accepted',
    'shopping',
    'ready',
    'outfordelivery',
    'cancelled',
    'completed'
  ]),
  variant: PropTypes.oneOf(['filled', 'outlined', 'ghost'])
};