import React from 'react';
import { Box, Grid } from '@mui/material';
import ProductCard from '../../Components/ProductCard';

const Category2Products = (props) => {
    React.useEffect(() => {
        
          props.props.getCategory2Products({storeid:localStorage.getItem('storeid'),
          categoryid2:localStorage.getItem('category2product'),
        userid:localStorage.getItem('userid')});
      }
        // eslint-disable-next-line
        , []);
    return (
        <Box flex={4} sx={{ paddingTop: '25px',paddingBottom:"30px" }}>
            <Grid container spacing={2} sx={{flexGrow:1}}>
                {
                     props.props.isStoreDetailsLoading && props.props.isStoreDetailsLoading === true ?
                     'Loading' :
                     (props.props.isStoreDetailsSuccess && props.props.isStoreDetailsSuccess === true &&
                         props.props.StoreDetails &&
                         props.props.StoreDetails !== null &&
                         props.props.StoreDetails.storeStatus === "active" && props.props.StoreDetails.storeWorking === true) ?
               ( props.props.isCategory2ProductsLoading && props.props.isCategory2ProductsLoading === true ?
                    'loading' :
                    props.props.isCategory2Products && props.props.isCategory2Products === true &&
                        props.props.Category2Products && props.props.Category2Products !== null && props.props.Category2Products.items&&
                        props.props.Category2Products.items.length > 0 ?
                        props.props.Category2Products.items.map((item) =>
                                    <Grid item style={{ paddingBottom: '25px' }}>
                                        <ProductCard
                                            id={item.id}
                                            productname={item.productname && item.productname}
                                            image={item.image && item.image.primary && item.image.primary}
                                            price={item.price && item.price}
                                            markedupProductPrice={item?.markedupProductPrice&&item?.markedupProductPrice}
                                            strikeOffPrice={item?.strikeOffPrice&&item?.strikeOffPrice}
                                            promoprice={item.promoprice && item.promoprice}
                                            storeid={localStorage.getItem('storeid')}
                                            storename={localStorage.getItem('storeName')}
                                            uom={item.uom && item.uom}
                                            quantity={item.quantity}
                                            bxgy={item.bxgy}
                                            storeoffline={false}
                                            {...props} />
                                    </Grid>
                        )
                        : props.props.isCategory2ProductsFailed && props.props.isCategory2ProductsFailed === true &&
                        'OOPS')
                        :(
                            props.props.isCategory2ProductsLoading && props.props.isCategory2ProductsLoading === true ?
                    'loading' :
                    props.props.isCategory2Products && props.props.isCategory2Products === true &&
                        props.props.Category2Products && props.props.Category2Products !== null && props.props.Category2Products.items&&
                        props.props.Category2Products.items.length > 0 ?
                        props.props.Category2Products.items.map((item) =>
                                    <Grid item style={{ paddingBottom: '25px' }}>
                                        <ProductCard
                                            id={item.id}
                                            productname={item.productname && item.productname}
                                            image={item.image && item.image.primary && item.image.primary}
                                            price={item.price && item.price}
                                            markedupProductPrice={item?.markedupProductPrice&&item?.markedupProductPrice}
                                            strikeOffPrice={item?.strikeOffPrice&&item?.strikeOffPrice}
                                            promoprice={item.promoprice && item.promoprice}
                                            storeid={localStorage.getItem('storeid')}
                                            storename={localStorage.getItem('storeName')}
                                            uom={item.uom && item.uom}
                                            quantity={item.quantity}
                                            bxgy={item.bxgy}
                                            storeoffline={true}
                                            {...props} />
                                    </Grid>
                        )
                        : props.props.isCategory2ProductsFailed && props.props.isCategory2ProductsFailed === true &&
                        'OOPS'
                        )
                }
            </Grid>
        </Box>
    )
}

export default Category2Products