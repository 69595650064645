import React from "react";
import { logo } from "../../Assets/image";
import landingpageimage from "../../Assets/Images/landingpageimage.webp";
import LocationForm from "../Forms/LocationForm";
import { useMediaQuery, useTheme } from "@mui/material";
import ExploreButton from "../Buttons/ExploreButton";
import "./style.css";

const Banner = (props) => {
  const desktop = useMediaQuery(useTheme().breakpoints.up("md"));
  const screen = useMediaQuery(useTheme().breakpoints.up("lg"));
  return (
    <>
      {desktop ? (
        <div className="big-wrapper light">
          <img
            src={landingpageimage}
            alt="NearShopz"
            className="shape"
            loading="lazy"
            title="NearShopz"
            width="210px"
            height="auto"
          />
          <div className="showcase-area">
            <div className="containerdiv">
              <div className="left">
                <div className="logo">
                  <img
                    src={logo}
                    alt="NearShopzLogo"
                    className="nearshopzlogo"
                    loading="lazy"
                    title="NearShopzLogo"
                    width="243.13px"
                    height="60px"
                  />
                </div>
                <div className="big-title">
                  <h1>
                    Your All-in-One Delivery
                    <br />
                    App For{" "}
                    <span className="shopsnearby">
                      Everyday <br />
                      Needs
                    </span>
                  </h1>
                </div>
                <p class="text">
                  Order Groceries, Food, Essentials & More:
                  <span className="oneatatime">
                    &nbsp;Fast Delivery App – NearShopz
                  </span>
                </p>
                {screen ? (
                  <>
                    <ExploreButton {...props} />
                  </>
                ) : null}
              </div>
              <div className="right">
                <LocationForm {...props} />
              </div>
              {!screen ? (
                <>
                <div style={{padding:"30px 140px"}}>
                  <ExploreButton {...props} />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <LocationForm {...props} />
      )}
    </>
  );
};

export default Banner;
