import React from "react";
import { useStyles } from "./style";
import checkicon from "../../Assets/Images/checkicon.svg";

const PlaceOrder = (props) => {

  const classes = useStyles();
  return (

    <div className={classes.placeorderDiv}>
      <img src={checkicon} className={classes.checkicon} alt="check" />
      <div className={classes.successmessage}>Order Successful</div>
      <div className={classes.keepbrowsing}>
        <div
          className={classes.keepbrowsingbutton}
          onClick={() => {
            props.navigate("/stores");
            props.resetCheckoutProps();
          }}
        >
          {"Keep Shopping"}
        </div>
      </div>
    </div>
  );
};

export default PlaceOrder;
