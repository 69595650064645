import React from 'react';
import Nonotifications from '../../Assets/Images/Nonotifications.svg';
import './index.css';

const PageNotFound = (props) => {
  return (
    <div className='main-div'>
        <div className='pagenotfound-div'>
        <img src={Nonotifications} alt='404' className='pagenotfound-image'/>
        <div className='pagenotfound-heading'>
            {'404'}
        </div>
        <div className='pagenotfound-desc'>
            {'Page Not Found'}
        </div>
        <div className='gotohome-button' onClick={()=>props.navigate('/')}>
            {'Go to home'}
        </div>
        </div>
    </div>
  )
}

export default PageNotFound