import { Grid } from '@mui/material';
import React from 'react';
import { useStyles } from './style';
import ProductAddButton from '../ProductAddButton';
import ProductCard from '../ProductCard'
import ImageCarousel from '../ImageCarousel';
import CenteredCircularProgress from './CenteredCircularProgress';

const ProductDetails = (props) => {
    
    const classes = useStyles();

    return (
        <>
            {props.props.isProductDetailsLoading &&
                props.props.isProductDetailsLoading === true ?
                <CenteredCircularProgress/>
                
                :
                (props.props.isProductDetails && props.props.isProductDetails === true &&
                    props.props.ProductDetails && props.props.ProductDetails !== null ?
                    (
                        <Grid container>
                            <Grid container className={classes.root}>
                                <Grid item xs={12} md={4} >
                                    <div style={{filter:props?.props?.StoreDetails?.storeWorking === false&&"grayscale(100%)" }}>
                                    <ImageCarousel
                                    storeoffline={props.storeoffline} 
                                    data={props.props.ProductDetails.image} {...props} />
                                    </div>
                                
                                    
                                </Grid>
                                <Grid item md={0.5} />
                                <Grid item xs={12} md={7.5}>
                                    <Grid container>
                                        <Grid item xs={12} md={12} className={classes.productname}>
                                            <div style={{marginTop:"15px"}}>
                                                {props.props.ProductDetails.productname && props.props.ProductDetails.productname}
                                            </div>
                                            <div className={classes.description}>
                                                {props.props.ProductDetails.desc && props.props.ProductDetails.desc}
                                            </div>
                                            <div className={classes.productadd}>
                                                <span className={classes.price}>
                                                    <div>
                                                        {((props.props.ProductDetails.promoprice !== null)&& (props.props.ProductDetails.promoprice !== "")) ?
                                                            <>
                                                                <span className={classes.productprice}>
                                                                    <b>{'₹' + props.props.ProductDetails.markedupProductPrice}</b>
                                                                </span>
                                                            </>
                                                            :
                                                            <span className={classes.productprice}>
                                                                <b>{'₹' + props?.props?.ProductDetails?.markedupProductPrice}</b>
                                                            </span>
                                                        }
                                                        <span className={classes.productpriceuom}>
                                                            &nbsp; {'per '}{props.props.ProductDetails.quantity && props.props.ProductDetails.quantity}
                                                            {props.props.ProductDetails.uom && props.props.ProductDetails.uom}
                                                        </span>
                                                        <div style={{ fontSize: '12px', color: 'rgb(66,69,97,0.6)' }}>
                                                            **Final price may vary depending on the actual weight of the item provided by the shop
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    paddingTop: '20px',
                                                    display: 'flex', justifyContent: 'left', alignItems: 'center', textAlign: 'left',
                                                }}>
                                                    {props.storeoffline===false?
                                                <span className={classes.button}>
                                                    <ProductAddButton 
                                                        storeid={props.props.ProductDetails.storeid.id}
                                                        productname={props.props.ProductDetails.productname}
                                                        storename={props.props.ProductDetails.storeName ? props.props.ProductDetails.storeName : localStorage.getItem('storeName')}
                                                        userid={localStorage.getItem('userid')}
                                                        id={`${localStorage.getItem('productid')?localStorage.getItem('productid'):props.props.ProductDetails.id}`}
                                                        image={props.props.ProductDetails.image.primary}
                                                        price={props.props.ProductDetails.markedupProductPrice}
                                                        markedupProductPrice={props?.props?.ProductDetails?.markedupProductPrice}
                                                        strikeOffPrice={props?.props?.ProductDetails?.strikeOffPrice}
                                                        promoprice={props.props.ProductDetails.promoprice}
                                                        quantity={props.props.ProductDetails.quantity}
                                                        // props={props}
                                                        {...props} />
                                                </span>
                                                :null
}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {props.props.isProductDetails && props.props.isProductDetails === true &&
                                props.props.ProductDetails &&
                                props.props.ProductDetails !== null &&
                                props.props.ProductDetails.relatedProducts &&
                                props.props.ProductDetails.relatedProducts !== null &&
                                props.props.ProductDetails.relatedProducts.items &&
                                props.props.ProductDetails.relatedProducts.items.length > 0 &&
                                <>
                                    <Grid item xs={12} md={12} className={classes.relatedproductsheading}>
                                        {'Related Items'}
                                    </Grid>
                                    <Grid item xs={12} md={12} style={{ paddingTop: '15px' }}>
                                        <div style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }} />
                                    </Grid>
                                    <Grid item xs={12} md={12} className={classes.relatedproducts}>
                                        <Grid container>
                                            {props.props.isProductDetails && props.props.isProductDetails === true &&
                                                props.props.ProductDetails &&
                                                props.props.ProductDetails.relatedProducts &&
                                                props.props.ProductDetails.relatedProducts.items.map((relateditem) =>
                                                    <Grid item md={2.35} xs={6} style={{ paddingBottom: '25px' }}>
                                                        <div style={{ paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                                                            <ProductCard
                                                                id={relateditem.id}
                                                                productname={relateditem.productname && relateditem.productname}
                                                                image={relateditem.image && relateditem.image.primary && relateditem.image.primary}
                                                                price={relateditem.price && relateditem.price}
                                                                markedupProductPrice={relateditem?.markedupProductPrice && relateditem?.markedupProductPrice}
                                                                strikeOffPrice={relateditem?.strikeOffPrice&&relateditem?.strikeOffPrice}
                                                                promoprice={relateditem.promoprice && relateditem.promoprice}
                                                                storeid={props?.props?.ProductDetails?.storeid && props?.props?.ProductDetails?.storeid}
                                                                storename={localStorage.getItem('storeName')}
                                                                uom={relateditem.uom && relateditem.uom}
                                                                quantity={relateditem.quantity}
                                                                bxgy={relateditem.bxgy}
                                                                storeoffline={props.storeoffline}
                                                                props={props.props}
                                                            />
                                                        </div>
                                                    </Grid>
                                                )}
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    ) :
                    props.props.isProductDetailsFailed && props.props.isProductDetailsFailed === true &&
                    "OOPS")
            }
        </>
    )
}

export default ProductDetails