 const CancelationPolicyDetails = [
  {
    index: 1,
    subhead: "Introduction",
    policytext: [
      "1.1 This Refund and Cancellation Policy (Policy) delineates the terms and conditions governing refund requests and order cancellations on NearShopz, (NearShopz), a hyperlocal delivery platform operated by 4Labs Technologies Pvt. Ltd.",
      "1.2 By utilizing our platform and services, you acknowledge that you have perused, comprehended, and consent to adhere to this Policy. If you dissent from this Policy, kindly abstain from utilizing our services. ",
    ],
  },
  {
    index: 2,
    subhead: "Refund Policy  ",
    policytext: [
      "2.1  Refunds may be facilitated under the following circumstances:Non-Delivery: In case an order is undelivered.Defective or Damaged Items: If items delivered are defective or damaged. Overcharges: Should you be charged an amount exceeding the correct order total due to system errors.",
      "2.2. To initiate a refund, please liaise with our customer support within 24 hours of the order completion time",
      "2.3. Refund requests are subject to verification, and NearShopz reserves the right to decline a refund if the request does not meet the eligibility criteria.",
      "2.4. Refunds will be processed to the original payment method utilized for the order.",
      "Net Banking, Credit Card, and Wallet Payments: Refunded within (((((5-7 days)))).UPI Payments: Refunded within 2 hours",
    ],
  },
  {
    index: 3,
    subhead:"Cancellation Policy",
    policytext:[
        "3.1.Users may cancel an order under the following circumstances:Users are permitted to cancel the order only within 60 seconds of order placement.NearShopz reserves the right to impose penalties for cancellations initiated by the platform for reasons unrelated to the platform but not limited to:Incorrect or unreachable address provided by the Buyer.Inability to contact the Buyer by phone or email during delivery or order booking.",
        "3.2. Once a delivery provider accepts an order, cancellation may not be viable, or it may incur fees or penalties at the discretion of the delivery provider.",
        "3.3. To cancel an order, users should utilize the cancellation feature in the application or reach out to our customer support."
    ]
  },
  {
    index: 4,
    subhead:"User Responsibilities ",
    policytext:[
        "4.1. Users are accountable for furnishing accurate order details and ensuring the correct delivery location.",
        "4.2. Users are urged to verify order details before confirming an order, as order errors are ineligible for refunds.",
        "4.3. Users must furnish accurate payment information for successful transactions. "
    ]
  },

  {
    index: 5,
    subhead:"Delivery Provider Responsibilities",
    policytext:[
        "5.1. Delivery providers must execute orders accurately and within the specified timeframe.",
        "5.2. In instances of defects, damage, or non-delivery, delivery providers are responsible for addressing these issues per this Policy. ",
    ]
  },
  {
    index: 6,
    subhead:"Dispute Resolution",
    policytext:[
        "6.1. Any dispute, controversy, or claim arising from or relating to these terms and conditions, or their breach, termination, or invalidity, shall be resolved through good-faith negotiations between the parties.",
        "6.2. If a dispute cannot be resolved through negotiations within [number of days] days of written notification, the parties agree to submit the dispute to mediation administered by or any mutually agreed-upon mediation service.",
        "6.3. If mediation fails to resolve the dispute within 30 days from its commencement, either party may initiate legal proceedings per the governing law and jurisdiction specified in these terms and conditions. "
    ]
  },
  {
    index: 7,
    subhead:"Contact Information",
    policytext:[
        "For inquiries or concerns regarding this Policy, please reach out to us at:support@nearshopz.com"
    ]
  },

];
export default CancelationPolicyDetails