import { makeStyles } from "@mui/styles";
// import { buttongreen } from "../../Assets/Colors";

export const useStyles = makeStyles(() => ({
    card: {
        background: '#ffffff',
        boxShadow: '0px 8px 44px rgba(105, 168, 92, 0.15)',
        borderRadius: '13px',
        padding: '13px',
    },
    addressHeading: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: '19.1px',
        color: '#061303',
        fontFamily: 'Nunito',
    },
    address: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '18px',
        color: '#061303',
        opacity: '0.5',
        fontFamily: 'Nunito'
    },
    storeBox: {
        background: '#FFFFFF',
        boxShadow: '0px 8px 44px rgba(105, 168, 92, 0.15)',
        borderRadius: '13px',
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '20px',
        paddingBottom: '25px',
    },
    subheading: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '13px',
        lineheight: '18px',
        color: '#061303',
        opacity: 0.5,
        paddingTop: '3px',
    },
    clearButton: {
        float: 'left',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'rgba(6, 19, 3,0.7)',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineheight: '19px',
        width: '100%',
        display: 'flex',
        cursor: 'pointer',
    },
    paymentSummaryBox: {
        background: '#FFFFFF',
        boxShadow: '0px 8px 44px rgba(0, 0, 0, 0.04)',
        borderRadius: '13px',
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    summaryStoreName: {
        color: '#061303',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineheight: '19px',
        fontFamily: 'Nunito'
    },
    summarySubheading: {
        paddingTop: '2px',
        color: '#061303',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '13px',
        lineheight: '18px',
        opacity: 0.5,
        paddingBottom: '14px',
    },
    summaryDetails: {
        display: 'flex',
        width: '100%',
        paddingBottom: '3px',
    },
    Left: {
        float: 'left',
        textAlign: 'left',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '18px',
        color: '#061303',
        opacity: 0.7,
        width: '100%',
    },
    Right: {
        float: 'right',
        textAlign: 'right',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '18px',
        color: '#061303',
        opacity: 0.7,
        width: '100%',
    },
    summaryStoreTotal: {
        display: 'flex',
        width: '100%',
        paddingTop: '11px',
        paddingBottom: '17px',
    },
    TotalLeft: {
        float: 'left',
        textAlign: 'left',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '13px',
        lineHeight: '18px',
        color: '#061303',
        opacity: 0.8,
        width: '100%',
    },
    TotalRight: {
        float: 'right',
        textAlign: 'right',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '13px',
        lineHeight: '18px',
        color: '#061303',
        opacity: 0.8,
        width: '100%',
    },
    divider: {
        opacity: 0.5,
        border: '1px solid #EAEEF7',
        width: '100%',
        height: '0px',
    },
    grandTotalBox: {
        background: '#2D93FB',
        borderRadius: '22px',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: 50,
    },
    grandTotal: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '49px',
        color: '#FFFFFF',
        fontFamily: 'Nunito'
    },
    text: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        color: 'rgba(255, 255, 255, 0.6)',
        fontFamily: 'Nunito'
    },
    checkoutaddressbutton: {
        width: '19px',
        height: '19px',
        background: '#061303',
        opacity: 0.1,
        borderRadius: '17px',
        cursor: 'pointer',
    },
    checkoutaddressbuttonclicked: {
        width: '19px',
        height: '19px',
        background: '#2D93FB',
        borderRadius: '17px',
        cursor: 'pointer',
    },
    addphonenumber: {
        background: 'rgba(234, 67, 53, 0.2)',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        color: '#EA4335',
        fontWeight: 600,
        fontSize: '12px',
        paddingLeft: '15px',
        paddingTop: '15px',
        paddingBottom: '15px',
        cursor: 'pointer',
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '20px',
        lineHeight: '25px',
        textAlign: 'center',
        color: '#000000',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    formlabel: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '25px',
        textAlign: 'left',
        color: '#000000',
        paddingBottom: '6px',
        paddingTop: '20px',
    },
    input: {
        background: '#FFFFFF',
        border: '1px solid rgba(133, 190, 73, 0.19)',
        boxSizing: 'border-box',
        borderRadius: '8px',
        height: '45px',
        width: '100%',
    },
    otpinput: {
        background: '#FFFFFF',
        border: '1px solid rgba(133, 190, 73, 0.19)',
        boxSizing: 'border-box',
        borderRadius: '8px',
        height: '45px',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '5px',
    },
    modalHeading: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '25px',
        textAlign: 'center',
        color: '#000000',
    },
    button: {
        background: `#89C74A`,
        color: '#FFFFFF',
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: '26.5px',
        padding: '13px',
        border: `1px solid #89C74A`,
        width: '100%',
        cursor: 'pointer'
    },
    buttondisabled: {
        background: '#CCCCCC',
        color: '#FFFFFF',
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: '26.5px',
        padding: '13px',
        border: `1px solid #89C74A`,
        width: '80%',
        cursor: 'pointer'
    },
    noordersDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '40px',
        width: '100%',
        height: '100%',
    },
    noordersimage: {
        maxHeight: '100%',
        maxWidth: '100%',
        width: '139px',
        height: '139px',
    },
    noordersheading: {
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        color: '#000000',
    },
    noorderstext: {
        fontWeight: 600,
        fontSize: '13px',
        textAlign: 'center',
        color: 'rgba(6, 19, 3, 0.5)',
    },
    storeDiv: {
        paddingBottom: '30px',
    },
    storeHeadingdiv: {
        display: 'flex',
        width: '100%',
    },
    storeLogoDiv: {
        width: '39px',
        height: '39px',
    },
    storeLogo: {
        width: '39px',
        height: '39px',
        borderRadius: '5px',
    },
    storeNameDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingLeft: '15px',
    },
    storeName: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#061303',
        lineHeight: '22px',
    },
    noofitems: {
        fontSize: '13px',
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.5)',
    },
    dateBox: {
        height: '54px',
        background: 'rgba(234, 238, 247,0.2)',
        border: '1px solid rgba(6, 19, 3, 0.24)',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        cursor: 'pointer',
        color: '#061303',
        overflow:'hidden',
        textOverflow:'ellipsis',
    },
    date: {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '14px',
        lineheight: '19px',
        color: 'inherit',
    },
    day: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineheight: '16px',
        color: 'inherit',
        opacity: 0.5,
        paddingTop: '2px'
    },
    dateBoxSelected: {
        height: '54px',
        background: '#2D93FB',
        borderRadius: '10px',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        color: '#fff',
    },
    dateSelected: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineheight: '19px',
        color: '#FFFFFF',
    },
    daySelected: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineheight: '16px',
        color: '#FFFFFF',
        opacity: 0.7,
        paddingTop: '2px'
    },
    specialInstructionsDiv: {
        background: 'rgba(234, 238, 247, 0.2)',
        border: '1px solid rgba(6, 19, 3, 0.24)',
        borderRadius: '5px',
        height: '54px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingLeft: '18px',
        color: 'rgba(6, 19, 3, 0.4)',
    },
    left: {
        float: 'left',
    },
    right: {
        width: '100%',
        textAlign: 'left',
    },
    timeHeading: {
        float: 'left',
        textAlign: 'left',
        justifyContent: 'left',
        alignItems: 'center',
        color: '#061303',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineheight: '19px',
        width: '100%',
        display: 'flex',
    },
    time: {
        float: 'right',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        boxSizing: 'border-box',
        padding: '10px',
    },
    specialInstruction: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineheight: '19px',
        color: '#061303',
        opacity: 0.8,
    },
    inputBox: {
        background: '#FFFFFF',
        border: '1px solid rgba(133, 190, 73, 0.35)',
        boxSizing: 'border-box',
        borderRadius: '8px',
        height: '79px',
        width: '100%',
    },
    bookSlotButton: {
        float: 'right',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'flex',
        width: '100%',
        background: '#2D93FB',
        border: 'none',
        borderRadius: '26.5px',
        boxSizing: 'border-box',
        height: '45px',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineheight: '19px',
        color: '#FFFFFF',
    },
    timeBox: {
        borderRadius: '10px',
        height: '34px',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '12px',
        color: '#000000',
        background: '#EAEDEF',
        cursor: 'pointer',
    },
    timeBoxSelected: {
        borderRadius: '10px',
        height: '34px',
        width: '100%',
        border: '1px solid #2D93FB',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '12px',
        color: '#fff',
        background: '#2D93FB',
        cursor: 'pointer',
    },
    dividerDiv: {
        border: '0.5px solid #69A85C',
        opacity: 0.5,
    },
    disablebutton: {
        fontFamily: 'Nunito',
        background: '#CCCCCC',
        width: '70%',
        color: '#fff',
        height: '42px',
        borderRadius: '12px',
        fontSize: '18px',
        fontWeight: 700,
        border: 'none',
        cursor: 'pointer',
    },
    prevButton: {
        width: '133px',
        height: '45px',
        background: '#89C74A',
        borderRadius: '26.5px',
        border: 'none',
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'Nunito',
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    nextbutton: {
        width: '133px',
        height: '45px',
        background: '#89C74A',
        borderRadius: '26.5px',
        border: 'none',
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'Nunito',
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    nextbuttondisabled: {
        width: '133px',
        height: '45px',
        background: '#CCCCCC',
        borderRadius: '26.5px',
        border: 'none',
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'Nunito',
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    placeorderDiv: {
        background: 'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)',
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    checkicon: {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '98px',
        height: '98px',
    },
    successmessage: {
        paddingTop: '26px',
        color: '#fff',
        fontSize: '18px',
        fontWeight: 600
    },
    keepbrowsing: {
        bottom: 0,
        paddingBottom: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: '26px',
    },
    keepbrowsingbutton: {
        background: 'rgba(255, 255, 255, 0.2)',
        color: '#fff',
        borderRadius: '26.5px',
        height: '45px',
        width: '196px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 600,
        cursor: 'pointer'
    },
    loadicon: {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '44px',
        height: '44px',
    },
    payment: {
        background: '#F7FAFF',
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    loadmessage: {
        color: '#69A85C',
        fontSize: '18px',
        fontWeight: 600,
        paddingTop: '26px',
    },
}));
