import React from 'react';

const getMobileOperatingSystem=()=> {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }
  const DetectAndServe=()=>{
      let os = getMobileOperatingSystem();
      if (os === "Android") {
          window.location.href = "https://play.google.com/store/apps/details?id=com.nearshopz.customer"; 
      } else if (os === "iOS") {
          window.location.href = "https://apps.apple.com/in/app/nearshopz/id1626241460";
      } else if (os === "Windows Phone") {
          window.location.href = "https://play.google.com/store/apps/details?id=com.nearshopz.customer";
      } else {
          window.location.href = "https://play.google.com/store/apps/details?id=com.nearshopz.customer";
      }
  }
const ReferFriends = () => {
    React.useEffect(()=>{
        DetectAndServe()
    },[])
    return (
        <div>
            
        </div>
    )
}

export default ReferFriends
