import React from "react";
import { Grid, useMediaQuery, useTheme, Typography } from "@mui/material";
import nearshopzmap from "../../Assets/Images/nearshopzmap.svg";
import downloadappimage from "../../Assets/Images/downloadappimage.png";
import "./index.css";

const MiddleSection = () => {
  const smallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  const address =
    "5th floor, Yamuna Building, Phase 3, Technopark, Trivandrum - 695581, Kerala, India";

  const formattedAddress = smallScreen
    ? address
        .replace(/Phase 3, /, "Phase 3,<br />")
        .replace(/Trivandrum - 695581, /, "Trivandrum - 695581,<br />")
    : address;
  return (
    <>
      {/* <Grid container justifyContent={"center"} md={12}> */}
      <Grid item xs={12} textAlign={"center"}>
        <h1 className="mainHeading" style={{ paddingBottom: "0px" }}>
          Contact Us
        </h1>
      </Grid>
      <div className="centerItems">
        <div className="addresspadding">
          <img
            src={nearshopzmap}
            alt={"Nearshopz-Contact-Us"}
            style={{ width: "100%" }}
          />
        </div>
        <div className="addresspadding">
          <div className="address-heading">
            NEARSHOPZ APP
            <div className="address">
              <span dangerouslySetInnerHTML={{ __html: formattedAddress }} />
            </div>
            <div className="address">
              Contact Support : 77368 77750 <br />
              Email: support@nearshopz.com
            </div>
          </div>
        </div>
      </div>
      {/* </Grid> */}
      {smallScreen ? (
        <Grid
          container
          spacing={1}
          sx={{
            paddingTop: "15px",
            fontSize: "30px",
            paddingLeft: { xs: "20px", sm: "30px" },
            paddingRight: { xs: "20px", sm: "30px" },
          }}
        >
          {/* <Grid item xs={12} md={5}>
                <img src={downloadappimage} alt="NearShopz" />
              </Grid> */}
          <Grid
            item
            xs={12}
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              color: "rgba(29, 77, 79, 1)",
              paddingTop: "15px",
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              //fontFamily="Roboto"
              sx={{
                fontWeight: "600",
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px",
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
                lineHeight: "25px",
              }}
              // className="termsTitle"
            >
              {"Shop essentials"}
              <br />
              {" in a few taps."}
            </Typography>
          </Grid>
          {/* <Grid
                item
                xs={12}
                textAlign={"left"}
                sx={{
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "rgba(29, 77, 79, 1)",
                 paddingTop:"0px"
                }}
              >
              <Typography
              variant="h5"
              gutterBottom
              //fontFamily="Roboto"
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            > {"in a few taps."}</Typography> 
              </Grid> */}
          <Grid item xs={12} sx={{ paddingTop: "0px" }}>
            <Grid container sx={{ paddingTop: "5px" }}>
              <Grid
                item
                xs={10}
                sx={{
                  color: "rgba(29, 77, 79, 0.7)",
                  fontWeight: 500,
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      gutterBottom
                      //fontFamily="Roboto"
                      sx={{
                        paddingBottom: "0px",
                        fontSize: {
                          xs: "18px",
                          sm: "20px",
                          md: "22px",
                        },
                        textAlign: "left",
                        lineHeight: "25px",
                      }}
                      // className="termsTitle"
                    >
                      {" "}
                      Shop for groceries, pharmacy, baby, pet, wellness and
                      electronics
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  paddingTop: "5px",
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#85BE49",
                }}
              >
                <Typography
                  variant="h3"
                  gutterBottom
                  //fontFamily="Roboto"
                  sx={{
                    paddingBottom: "0px",
                    fontSize: {
                      xs: "19px",
                      sm: "22px",
                      // md: "24px"
                    },
                    textAlign: "left",
                    lineHeight: "25px",
                  }}
                  // className="termsTitle"
                >
                  {"and get your order delivered at your doorstep!"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} className="download-app-section" sx={{width:"100%"}}>
          <Grid item xs={12} md={7}>
            <Grid
              container
              sx={{
                paddingBottom: "35px",
                paddingTop: {
                  sm: "40px",
                  md: "50px",
                  lg: "60px",
                  xl: "80px",
                },
                padding: {
                  sm: "30px",
                  md: "40px",
                  lg: "50px",
                  xl: "60px",
                },
              }}
            >
              <Grid
                item
                xs={12}
                textAlign={"left"}
                sx={{
                  fontSize: {
                    sm: "30px",
                    md: "35px",
                    lg: "40px",
                    xl: "60px",
                  },
                  fontWeight: 700,
                  color: "rgba(29, 77, 79, 1)",
                }}
              >
                {"GET STARTED  "}
              </Grid>
              <Grid
                item
                xs={12}
                textAlign={"left"}
                sx={{
                  fontSize: {
                    sm: "30px",
                    md: "35px",
                    lg: "40px",
                    xl: "45px",
                  },
                  fontWeight: 600,
                  color: "rgba(29, 77, 79, 1)",
                  paddingTop: { md: "5px", sm: "0px" },
                }}
              >
                {"TODAY!"}
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      color: "rgba(29, 77, 79, 0.7)",
                      fontSize: {
                        sm: "26px",
                        md: "30px",
                        lg: "32px",
                        xl: "36px",
                      },
                      fontWeight: 500,
                      paddingTop: {
                        sm: "16px",
                        md: "20px",
                        lg: "30px",
                        xl: "40px",
                      },
                      textAlign: "justify",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        {"Shop local, shop fresh, shop convenient! "}
                      </Grid>
                      {/* <Grid item xs={12}>
                                                {'shop convenient! '}
                                            </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  paddingTop: "25px",
                  fontSize: {
                    sm: "24px",
                    md: "26px",
                    lg: "28px",
                    xl: "30px",
                  },
                  fontWeight: 500,
                  color: "#85BE49",
                }}
              >
                {
                  "Get started with Nearshopz today and have your groceries, daily essentials, and everything you need delivered right to your doorstep. "
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sm={12}
            sx={{
              display: { sm: "none", md: "block", xs: "none" },
              paddingLeft: { xl: "20px", lg: "30px" },
            }}
          >
            <img src={downloadappimage} alt="NearShopz" />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MiddleSection;
