/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { ReactComponent as CloseForInput } from "../../Assets/Icons/Close for input.svg";
import { ReactComponent as BackArrow } from "../../Assets/Icons/BackArrow.svg";
import Navbar from "../Navbar/index";
import Grid from "@mui/material/Grid";
import RecentSearches from "./RecentSearches";
import GlobalSearchResults from "./GlobalSearchResults";
import PopularProducts from "./PopularProducts";
import MatchingResults from "./MatchingResults";
import "./index.css";
import CenteredCircularProgress from "./CenteredCircularProgress";
import { useMediaQuery } from "react-responsive";

const SearchProductAndStore = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [showPopularProducts, setShowPopularProducts] = useState(true); 
  const [showMatchingResults, setShowMatchingResults] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [productname, setSeletcedProductname] = useState("");
  //const placeholders = [];
  const placeholders = ["Store Name", "Grocery", "Food"];
  const [filteredStores, setFilteredStores] = useState([]);


  useEffect(() => {
    props.getStores(
      localStorage.getItem("lattitude"),
      localStorage.getItem("longitude")
    );
    props.getPopularProducts();
   
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [localStorage.getItem("lattitude"), localStorage.getItem("longitude")]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    setShowMatchingResults(false);

    if (inputValue !== "" || null) {
      const filteredStores = props.AllStores.filter((store) =>
        store.storeName.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredStores(filteredStores);
      props.serachProductsGlobally(
        props?.AllStores.map((c) => c?.id),
        event.target.value
      );
    } else {
      setFilteredStores([]);
    }
  };

  const handleClear = () => {
    setInputValue("");
    setShowMatchingResults(false);
    setShowPopularProducts(true);
  };

  const handleProductClick = (product) => {
    let storeIDs = props?.AllStores.map((c) => c?.id);
    props.getProductSearch(storeIDs, product);
    setSelectedProduct(product);
    setShowPopularProducts(true);
    setShowMatchingResults(true);
    setSeletcedProductname(product);
  };

  const handlepopularProductClick = (productname) => {
    props.serachProductsGlobally(
      props?.AllStores.map((c) => c?.id),
      productname
    );
    let storeIDs = props?.AllStores.map((c) => c?.id);
    props.getProductSearch(storeIDs, productname);
    setSelectedProduct(productname);
    setShowPopularProducts(true);
    setShowMatchingResults(true);
    setSeletcedProductname(productname);
  };

  const handleRecenetItemClick =(productname)=>{
    props.serachProductsGlobally(
      props?.AllStores.map((c) => c?.id),
      productname
    );
    let storeIDs = props?.AllStores.map((c) => c?.id);
    props.getProductSearch(storeIDs, productname);
    setSelectedProduct(productname);
    setShowPopularProducts(true);
    setShowMatchingResults(true);
    setSeletcedProductname(productname);
  }
   const [loading,setLoading] = useState(false)
  // const lat = localStorage.getItem("lattitude") 
  // const lng = localStorage.getItem("longitude")
  // const routeToHome = () => {
  //   setLoading(true)
  //   setTimeout(()=>{
  //     props.getStores(localStorage.getItem("lattitude") ,localStorage.getItem("longitude"))
  //     props.getBusinessTypes()
  //     setLoading(false)
  //     props.navigate("/stores");

  //   },1000)
  // };
  
  const routeToHome = () => {
    setLoading(true);
    setTimeout(() => {
      props.getStores(localStorage.getItem("lattitude"), localStorage.getItem("longitude"));
      props.getBusinessTypes();
      setLoading(false);
      props.navigate("/stores");
    }, 1000);
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 2000);

    return () => clearInterval(intervalId);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
 const ismobileScreen = useMediaQuery({ query: "(max-width: 600px)" });
 const largeScreen = useMediaQuery({query:"(min-width:700px)"})
  return (
    <>
    {!loading ? (
      <div className="serach_parent_div" >
        <Navbar className="wid-100" {...props} />
        <Grid
          style={{ width: "100%",  paddingTop: ismobileScreen ? "0px": largeScreen ?  "15px" : "10px", overflowX: "hidden"}} 
        >
          <div className= {ismobileScreen ?  "horizontal-scroll1" : null }>
          <div className="search_Container">
            <div className="search_cover">
              <div className="header">
                <div>
                  <BackArrow
                    className="wid-100"
                    onClick={() => routeToHome()}
                  />
                </div>
                <h6 className="search-heading-text">
                  Search Food, Groceries, etc
                </h6>
              </div>
            
              <div className="search_Input">
                <TextField
                  value={inputValue}
                  onChange={handleChange}
                  variant="outlined"
                  // placeholder={`Try ${
                  //   truncateText(
                  //     props?.Account?.recentSearches?.[placeholderIndex],
                  //     15
                  //   ) || "searching..."
                  // }`}
                  placeholder={`Try ${
                    truncateText( placeholders[placeholderIndex], 15)
                  }`}
                  // placeholder={`Try ${
                  //   ["storename", "grocery", "foodtype"][placeholderIndex % 3]
                  // }`}
              
                  fullWidth
                  className={`placeholder-transition-${placeholderIndex}`}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                    endAdornment: inputValue && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClear} aria-label="clear">
                          <CloseForInput className="wid-100" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {!inputValue && 
              <RecentSearches props={props} 
              onRecenetItemClick={handleRecenetItemClick}
              //className= {ismobileScreen ?  "horizontal-scroll1" : null }
              />}
              {showMatchingResults && (
                <MatchingResults
                  selectedProduct={selectedProduct}
                  matchingProductList={props?.productsSearchDetails}
                  setSeletcedProductname={productname}
                  props={props}
                />
              )}
              {!inputValue &&
                !showMatchingResults &&
                props?.popularProductsList && (
                  <PopularProducts
                    popularProductsList={props?.popularProductsList}
                    onPopularProductClick={handlepopularProductClick}
                    props={props}
                  />
                )}
              {inputValue && !showMatchingResults && (
                <GlobalSearchResults
                  inputValue={inputValue}
                  searchProductsGlobalList={
                    props?.searchProductsGlobalList || []
                  }
                  onProductClick={handleProductClick}
                  filteredStores={filteredStores}
                  props={props}
                  //className= {ismobileScreen ?  "horizontal-scroll1" : null }
                />
              )}
            </div>
            </div>
          </div>
        </Grid>
      </div>
      ):(
        <div>
         <CenteredCircularProgress/>
        </div>
      )}
    </>
  );
};

export default SearchProductAndStore;
