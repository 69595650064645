import React from 'react'

const Campaign = (props) => {
  React.useEffect(()=>{
    window.location.href='https://milaap.org/fundraisers/support-ashraya-charitable-society/deeplink?deeplink_type=paytm';
  },
  [])
  return (
    <div></div>
  )
}

export default Campaign