import { useLocation } from "react-router";
import { Box, Grid, useMediaQuery, useTheme, CircularProgress } from "@mui/material";
import ProductCard from "../../Components/ProductCard";
import { useStyles } from "./style";
import Carousel, { consts } from "react-elastic-carousel";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SwipeableCarousel from "./SwipeableCarousel";
import "./style.css";
import React, { useEffect, useState } from "react";

function myArrow({ type, onClick, isEdge }) {
  const pointer =
    type === consts.PREV ? (
      <KeyboardArrowLeftIcon fontSize="small" />
    ) : (
      <KeyboardArrowRightIcon />
    );

  return (
    <button
      style={{
        backgroundColor: "transparent",
        border: "none",
        width: "20px",
        borderRadius: "50%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
      disabled={isEdge}
    >
      {pointer}
    </button>
  );
}

const Departments = (props) => {
  const location = useLocation();
  const { productId, productname, image, markedupProductPrice, promoprice, storeId, uom, quantity, bxgy,strikeOffPrice } = location.state || {};
  const classes = useStyles();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("md"));
  const [loading, setLoading] = useState(true);

  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 2, itemsToShow: 2 },
    { width: 550, itemsToShow: 4 },
    { width: 767, itemsToShow: 4 },
    { width: 900, itemsToShow: 5 },
    { width: 1100, itemsToShow: 5 },
  ];

  const setId = (id) => {
    localStorage.setItem("departmentid", id);
  };

  const routeToDepartmentProducts = () => {
    props.navigate(`/stores/categories/departmentproducts`);
  };

  const routeToCategories = () => {
    props.navigate(`/stores/categories/category`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        if (props.params.storeid) {
          await Promise.all([
            props.props.getDepartments(props.params.storeid),
            props.props.getStoreDetails(props.params.storeid, true),
          ]);
        } else {
          await Promise.all([
            props.props.getStoreDetails(localStorage.getItem("storeid")),
            props.props.getDepartments(localStorage.getItem("storeid"), false),
          ]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }; 
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ismobileScreen =  useMediaQuery(useTheme().breakpoints.down("xl"));
  console.log("huuu",props.props.Departments)
  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <Box flex={4} sx={{ paddingTop: { md: "25px", xs: 0 }, margin: 0 }}>
          {props?.props?.productsSearchDetails?.items?.length > 0 && (
            <>
              {productname && markedupProductPrice && (
                <Grid item xs={8} sm={8} md={10} alignItems="center" className={classes.departmentname}>
                  {"Search Result"}
                </Grid>
              )}
              <Grid style={{ paddingTop: "10px", paddingBottom: "35px", boxSizing: "border-box" }}>
                <div style={{ paddingLeft: ismobileScreen ? "0px": "20px", boxSizing: "border-box" }}>
                  {productname && markedupProductPrice && (
                    <ProductCard
                      key={productId}
                      id={productId}
                      productname={productname}
                      image={image}
                      price={markedupProductPrice}
                      markedupProductPrice={markedupProductPrice}
                      strikeOffPrice={strikeOffPrice}
                      promoprice={promoprice}
                      storeid={storeId}
                      storename={localStorage.getItem("storeName")}
                      uom={uom}
                      quantity={quantity}
                      bxgy={bxgy}
                      storeoffline={false}
                      {...props}
                    />
                  )}
                </div>
              </Grid>
            </>
          )}
          <Grid container spacing={2} sx={{ flexGrow: 0.5, alignItems: "center",paddingBottom:"30px" }}>
            {props.props.isStoreDetailsSuccess &&
            props.props.isStoreDetailsSuccess === true &&
            props.props.StoreDetails &&
            props.props.StoreDetails.storeStatus === "active" &&
            props.props.StoreDetails.storeWorking === true ? (
              props.props.isDepartments &&
              props.props.isDepartments === true &&
              props.props.Departments &&
              props.props.Departments.length > 0 ? (
                props.props.Departments.map(({ departmentProducts, name, id, storeid, childCategoryPresent }) =>
                  departmentProducts.items && departmentProducts.items.length > 0 && (
                    <React.Fragment key={id}>
                      <Grid item xs={8} sm={8} md={10} alignItems="center" className={classes.departmentname} style={{paddingLeft:"8px"}}>
                        {name}
                      </Grid>
                      <Grid item xs={4} sm={4} md={2} className={classes.viewallgrid}>
                        <span
                          onClick={() => {
                            setId(id);
                            childCategoryPresent === false ? routeToDepartmentProducts() : routeToCategories();
                          }}
                          className={classes.viewall}
                        >
                          View all
                        </span>
                      </Grid>
                      {fullScreen ? (
                        <SwipeableCarousel {...props}>
                          {departmentProducts.items.map((item,index) => (
                            <Grid item key={item.id} 
                            >
                              <div style={{ padding: "5px", paddingBottom: "25px", paddingLeft:"8px", justifyContent: "space-around" }}>
                                <ProductCard
                                  id={item.id}
                                  productname={item.productname}
                                  image={item.image?.primary}
                                  price={item?.price}
                                  markedupProductPrice={item?.markedupProductPrice}
                                  strikeOffPrice={item?.strikeOffPrice}
                                  promoprice={item.promoprice}
                                  storeid={storeid}
                                  storename={localStorage.getItem("storeName")}
                                  uom={item.uom}
                                  quantity={item.quantity}
                                  bxgy={item.bxgy}
                                  storeoffline={false}
                                  {...props}
                                />
                              </div>
                            </Grid>
                          ))}
                        </SwipeableCarousel>
                      ) : (
                        <Carousel
                          renderArrow={myArrow}
                          itemPosition="START"
                          breakPoints={breakPoints}
                          showEmptySlots={true}
                          itemPadding={[0]}
                          outerSpacing={0}
                          pagination={false}
                        >
                          {departmentProducts.items.map((item,index) => (
                            
                            <Grid item key={item.id} 
                            >
                              
                              <div style={{ padding: "5px",  paddingBottom: "25px",paddingLeft:"8px",  justifyContent: "space-around" }}>
                                <ProductCard
                                  id={item.id}
                                  productname={item.productname}
                                  image={item.image?.primary}
                                  price={item.price}
                                  markedupProductPrice={item?.markedupProductPrice}
                                  strikeOffPrice={item?.strikeOffPrice}
                                  promoprice={item.promoprice}
                                  storeid={storeid}
                                  storename={localStorage.getItem("storeName")}
                                  uom={item.uom}
                                  quantity={item.quantity}
                                  bxgy={item.bxgy}
                                  storeoffline={false}
                                  {...props}
                                />
                              </div>
                            </Grid>
                          ))}
                        </Carousel>
                      )}
                    </React.Fragment>
                  )
                )
              ) : (
                props.props.isDepartmentsFailed && props.props.isDepartmentsFailed === true && "OOPS"
              )
            ) : (
              props.props.isDepartments &&
              props.props.isDepartments === true &&
              props.props.Departments &&
              props.props.Departments.length > 0 ? (
                props.props.Departments.map(({ departmentProducts, name, id, storeid, childCategoryPresent }) =>
                  departmentProducts.items && departmentProducts.items.length > 0 && (
                    <React.Fragment key={id}>
                      <Grid item xs={8} sm={8} md={10} alignItems="center" className={classes.departmentname}>
                        {name}
                      </Grid>
                      <Grid item xs={4} sm={4} md={2} className={classes.viewallgrid}>
                        <span
                          onClick={() => {
                            setId(id);
                            childCategoryPresent === false ? routeToDepartmentProducts() : routeToCategories();
                          }}
                          className={classes.viewall}
                        >
                          View all
                        </span>
                      </Grid>
                      {fullScreen ? (
                        <SwipeableCarousel {...props}>
                          {departmentProducts.items.map((item) => (
                            <Grid item key={item.id}>
                              <div style={{ padding: "5px", paddingBottom: "25px", paddingLeft:"8px", justifyContent: "space-around" }}>
                                <ProductCard
                                  id={item.id}
                                  productname={item.productname}
                                  image={item.image?.primary}
                                  price={item.price}
                                  markedupProductPrice={item?.markedupProductPrice}
                                  strikeOffPrice={item?.strikeOffPrice}
                                  promoprice={item.promoprice}
                                  storeid={storeid}
                                  storename={localStorage.getItem("storeName")}
                                  uom={item.uom}
                                  quantity={item.quantity}
                                  bxgy={item.bxgy}
                                  storeoffline={false}
                                  {...props}
                                />
                              </div>
                            </Grid>
                          ))}
                        </SwipeableCarousel>
                      ) : (
                        <Carousel
                          renderArrow={myArrow}
                          itemPosition="START"
                          breakPoints={breakPoints}
                          showEmptySlots={true}
                          itemPadding={[0]}
                          outerSpacing={0}
                          pagination={false}
                        >
                          {departmentProducts.items.map((item) => (
                            <Grid item key={item.id}>
                              <div style={{ padding: "5px", paddingRight:"5px",paddingLeft:"8px" , justifyContent: "space-around" }}>
                                <ProductCard
                                  id={item.id}
                                  productname={item.productname}
                                  image={item.image?.primary}
                                  price={item.price}
                                  strikeOffPrice={item?.strikeOffPrice}
                                  markedupProductPrice={item?.markedupProductPrice}
                                  promoprice={item.promoprice}
                                  storeid={storeid}
                                  storename={localStorage.getItem("storeName")}
                                  uom={item.uom}
                                  quantity={item.quantity}
                                  bxgy={item.bxgy}
                                  storeoffline={false}
                                  {...props}
                                />
                              </div>
                            </Grid>
                          ))}
                        </Carousel>
                      )}
                    </React.Fragment>
                  )
                )
              ) : (
                props.props.isDepartmentsFailed && props.props.isDepartmentsFailed === true && "OOPS"
              )
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Departments;
