import React from 'react';
import LandingPage from '../../Layouts/LandingPage';
import { Navigate } from "react-router-dom";

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        if (!localStorage.getItem('token')) {
            if (localStorage.getItem('location'))
                return <Navigate to='/stores' />
            else if (!localStorage.getItem('location'))
                return <Navigate to='/' />
        }
        else
            return <Navigate to='/stores' />
    }
    render() {
        return (
            <>
                {
                    localStorage.getItem('token') ?
                        <Navigate to='/stores' />
                        :
                        <LandingPage
                            navigate={this.props.navigate}
                            params={this.props.params}
                            {...this.props}
                        />
                }
            </>
        )
    }
}

export default MainPage