import React from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import { UPLOAD_IMAGE } from '../../Redux/Actions/Queries';
import client from '../../Configurations/apollo';
import { useStyles } from './style';


const UploadImage = (props) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
        cid: 0,
        message: [],
        imageupload: false,
        open: false,
        files: [],
        refundoption: false,
        replacementption: false,
        itemImage: '',
    });
    const handleSave = (files) => {
        //Saving files to state for further use and closing Modal.
        setState({
            ...state,
            files: files,
            open: false
        }); 
        client
            .mutate({
                mutation: UPLOAD_IMAGE,
                variables: {
                    file: files[0],
                    type: 4,
                    orderid: `${props.orderID && props.orderID}`
                },
            })
            .then((res) => {
                if (res.data) {
                    setState({
                        ...state,
                        itemImage: res.data.signS3.Location
                    })
                    handleClose();
                    props.selectItemImage(res.data.signS3.Location);
                }
            })
            .catch((err) => {
                console.log("err",err)
            });

    }
    const handleClose = () => {
        setState({
            ...state,
            open: false
        });
    }
    const handleOpen = () => {
        setState({
            ...state,
            open: true,
        });
    }

    return (
        <div>
            {props.SelectedItemsImage ?
                <img src={props.SelectedItemsImage} alt="product" style={{ width: '100px', height: '100px', maxWidth: '100%', maxHeight: '100%' }} /> :
                <button
                    className={classes.submitbutton}
                    onClick={handleOpen}>
                    Select Image
                </button>
            }
            <DropzoneDialog
                open={state.open}
                onSave={handleSave}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                showPreviews={true}
                maxFileSize={5000000}
                onClose={handleClose}
            />
        </div>
    )
}

export default UploadImage
