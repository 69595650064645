import React from 'react';
import './style.css';

const Category2Carousel = (props) => {

    const [open, setOpen] = React.useState(false);
    const [categoryopen, setCategoryOpen] = React.useState(false);
    const [category1open, setCategory1Open] = React.useState(false);
    const setNoCategory1Id = (id) => {
        localStorage.setItem("category1id", id);
        setCategory1Open(!open);
        props.props.getCategory2list({ storeid: localStorage.getItem('storeid'), categoryid: id, userid: localStorage.getItem('userid') });
        props.navigate(`/stores/categories/category2`);
    };
    const setCategory2Id = (id) => {
        localStorage.setItem("category2id", id);
        props.props.getCategory2Products({
            storeid: localStorage.getItem('storeid'),
            categoryid2: localStorage.getItem('category2product'),
            userid: localStorage.getItem('userid')
        });
        props.navigate(`/stores/categories/category2products`);
    };
    React.useEffect(() => {
        if (!props.props.isDepartments) {
            if (props.params.storeid)
                props.props.getDepartments(props.params.storeid);
            else
                props.props.getDepartments(localStorage.getItem('storeid'));
        }
    },
        // eslint-disable-next-line
        [])
    return (
        <>
            <div className='category-list-container'>
                <div className='horizontal-scroll-list'>
                    <div className='category-container-div'>
                        {props.isCategories2 && props.isCategories2 === true &&
                            props.Categories2 && props.Categories2 !== null && props.Categories2.length > 0 &&
                            props.Categories2.map(({ name, id, childCategoryPresent, category2Products }) =>
                                category2Products.items.length > 0 &&
                                    childCategoryPresent === false ?
                                    <div className='category-padding'>
                                        <div className='category-list-div' onClick={() => setCategory2Id(id)}>
                                            <div className='category-name-div'>
                                                {name}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='category-padding'>
                                        <div className='category-list-div' onClick={() => setNoCategory1Id(id)}>
                                            <div className='category-name-div'>
                                                {name}
                                            </div>
                                        </div>
                                    </div>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Category2Carousel