import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { db } from "../db.js";
import { useLiveQuery } from "dexie-react-hooks";
import { useStyles } from "./style.js";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import {
  Alert,
  Badge,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
} from "@mui/material";
import CartList from "./CartList";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./index.css";

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const { cart } = db;
  const cartdata = useLiveQuery(() => cart.toArray(), []);
  const [state, setState] = React.useState({
    data: {},
    CheckAmount: [],
    cartid: "",
    storeid: "",
    storename: "",
    storelogo: "",
    storelength: "",
    deleteStore: false,
    error: "",
    restrictordernum: 0,
    right: false,
  });

  const [clearcartopen, setClearCartOpen] = React.useState(false);

  const handleClearCartClose = () => {
    setClearCartOpen(false);
  };

  const toggleDrawer = (anchor, value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (value === true) {
      props.getCart(localStorage.getItem("userid"));
    }
    setState({ ...state, [anchor]: value });
  };

  const clearCart = () => {
    props.clearcart();
    setClearCartOpen(false);
  };

  const handleErrorClose = () => {
    props.createcartreset();
  };

  const list = (anchor) => (
    <Box
      sx={{
        backgroundColor: "#EAEDEF",
        height: "auto",
        padding: { sm: "10px", md: "15px" },
      }}
      role="presentation"
    >
      <div
        style={{
          padding: "10px",
        }}
      >
        <>
          <CartList {...props} />
        </>
      </div>
    </Box>
  );

  const calculateTotalNumberOfItems = (data) => {
    let noofitems = 0;
    data.map((c) => (noofitems = noofitems + c?.products?.length));
    return noofitems;
  };

  const cart_OnClick = () => {
    setState({ ...state, right: true });
  };

  return (
    <div>
      <React.Fragment key={"right"}>
        <button className="notification-button" onClick={cart_OnClick}>
          <Badge
            badgeContent={
              cartdata &&
              cartdata !== null &&
              calculateTotalNumberOfItems(cartdata)
            }
            color="warning"
          >
            <ShoppingCartOutlinedIcon />
          </Badge>
          <span>Cart</span>
        </button>
      </React.Fragment>
      {props.cartFailed &&
        props.cartFailed !== "" &&
        props.cartFailed ===
          "Products from only 5 stores can be added to cart" && (
          <Snackbar
            open={true}
            autoHideDuration={5000}
            onClose={handleErrorClose}
            sx={{ height: "100%", width: "fit-content" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            severity="error"
          >
            <Alert
              onClose={handleErrorClose}
              severity="error"
              sx={{
                backgroundColor: "#d32f2f",
                borderRadius: "6px",
                color: "#fff",
                boxShadow:
                  "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
              }}
            >
              {"Only 5 stores can be added to cart !"}
            </Alert>
          </Snackbar>
        )}
      <Drawer
        anchor={"right"}
        open={state.right}
        onClose={toggleDrawer("right", false)}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            // maxWidth:'500px',
            width: { xs: "100%", sm: "100%", md: 500, lg: 900, xl: 500 },
            backgroundColor: "#EAEDEF",
          },
        }}
      >
        <>
          <div
            style={{
              paddingTop: "40px",
              paddingLeft: "10px",
              paddingBottom: "18px",
              background: "#ffffff",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#000000",
            }}
          >
            <div
              style={{
                paddingBottom: "10px",
                width: "100%",
              }}
            >
              <Grid container style={{ width: "100%" }}>
                <Grid item xs={0.5} />
                <Grid
                  item
                  xs={2}
                  sm={1}
                  justifyContent={"center"}
                  textAlign={"center"}
                  alignItems={"center"}
                  sx={{paddingTop:{xs:"5px"}}}
                >
                  <span
                    className={classes.leftarrow}
                    onClick={toggleDrawer("right", false)}
                  >
                    <KeyboardBackspaceIcon />
                  </span>
                </Grid>
                {/* <Grid item xs={1} /> */}
                <Grid
                  item
                  xs={7}
                  sm={8}
                  justifyContent={"left"}
                  textAlign={"center"}
                  alignItems={"center"}
                  style={{ width: "100%" }}
                >
                  <div className="text-shopping">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span className="shoppingcart-01">{"Shopping Cart"}</span>
                      <span
                        style={{
                          color: "rgba(0,0,0,0.5)",
                          fontSize: "12px",
                          lineHeight: "16px",
                          textAlign: "left",
                        }}
                      >
                        {cartdata && cartdata !== null && cartdata.length > 0
                          ? `You have items from ${
                              cartdata && cartdata !== null
                                ? cartdata.length
                                : "0"
                            } stores`
                          : "You have no items in your cart"}
                      </span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      paddingLeft: "15px",
                      paddingTop: "5px",
                    }}
                  >
                    <ShoppingCartTwoToneIcon fontSize="large" />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          {list("right")}
        </>
      </Drawer>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "26.5px",
            backgroundColor: `#EAEEF7`,
            width: "100%",
            padding: "25px",
          },
        }}
        maxWidth={"sm"}
        open={clearcartopen}
        onClose={handleClearCartClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          {"Are you sure you want to delete all the products in the cart?"}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "8px",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
            >
              <button className={classes.buttonCancel} onClick={clearCart}>
                {"Yes"}
              </button>
              <button className={classes.button} onClick={handleClearCartClose}>
                {"No"}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
