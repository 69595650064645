import React from "react";
import Contact from "./Contact.js";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useStyles } from "./style.js";
import { CircularProgress, Grid } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import "./index.css";
import { useMediaQuery, useTheme } from "@mui/material";

const Schema = Yup.object().shape({
  oldpassword: Yup.string()
    .trim()
    .matches(/^\S*$/, "Space is not allowed")
    .required("This field is required"),
  password: Yup.string()
    .trim()
    .matches(/^\S*$/, "Space is not allowed")
    /* eslint-disable no-useless-escape */
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      "Password must contain one number uppercase and lowercase letters and special character"
    )
    .min(8, "Password is too short - should be 8 characters minimum")
    .required("This field is required"),
  confirmpassword: Yup.string()
    .trim()
    .required("This field is required")
    .oneOf([Yup.ref("password"), null], "Both password needs to be same"),
});
const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .matches(/^\S*$/, "Space is not allowed")
    /* eslint-disable no-useless-escape */
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      "Password must contain one number uppercase and lowercase letters and special character"
    )
    .min(8, "Password is too short - should be 8 characters minimum")
    .required("This field is required"),
  confirmpassword: Yup.string()
    .trim()
    .required("This field is required")
    .oneOf([Yup.ref("password"), null], "Both password needs to be same"),
});
const EmailvalidationSchema = Yup.object().shape({
  email: Yup.string().email().required("Please Enter The Email").trim(),
});
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim("Space not allowed")
    .matches(/^\S+$/, "Space not allowed")
    .strict(true)
    .matches(/^[a-zA-Z]+$/, "Only Alphabets Are Allowed")
    .matches(/^[a-zA-Z0-9]*$/, "Space not allowed")
    .max(20, "length exceeded")
    .required("Please Enter First Name"),
  lastName: Yup.string()
    .trim("Space not allowed")
    .matches(/^\S+$/, "Space not allowed")
    .strict(true)
    .matches(/^[a-zA-Z]+$/, "Only Alphabets Are Allowed")
    .matches(/^[a-zA-Z0-9]*$/, "Space not allowed")
    .max(20, "length exceeded")
    .required("Please Enter Last Name"),
});
let userID = localStorage.getItem("userid");

const MyAccount = (props) => {
  const classes = useStyles();
  const [edit, setEdit] = React.useState(false);
  const [Emailedit, setEmailEdit] = React.useState(false);
  const [PasswordEdit, setPassword] = React.useState(false);

  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  // const [showPassword3, setShowPassword3] = React.useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickShowPassword1 = (event) => {
    event.preventDefault();
    setShowPassword1((prev) => !prev);
  };
  const handleClickShowPassword2 = (event) => {
    event.preventDefault();
    setShowPassword2((prev) => !prev);
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  // const handleClickShowPassword3 = (event) => {
  //     event.preventDefault();
  //     setShowPassword3((prev) => !prev);
  // };
  // const handleMouseDownPassword3 = (event) => {
  //     event.preventDefault();
  // };

  React.useEffect(
    () => {
      if (
        !localStorage.getItem("userid") ||
        localStorage.getItem("userid") == null
      )
        return null;
      else props.getUserDetails(localStorage.getItem("userid"));
    },
    // eslint-disable-next-line
    []
  );

  const PasswordFormik = useFormik({
    initialValues: {
      email: props.Account.email,
      oldpassword: "",
      password: "",
      confirmpassword: "",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      if (values.oldpassword === values.password) {
        alert("Old password and new password must not be the same.");
      } else if (values.password !== values.confirmpassword) {
        alert("New and Confirm passwords should be same");
      } else {
        props.updatepassword(userID, values.oldpassword, values.password);
        // setPassword(false);
      }
    },
  });

  const AddPasswordFormik = useFormik({
    initialValues: {
      password: "",
      confirmpassword: "",
    },
    validationSchema: PasswordSchema,
    onSubmit: (values) => {
      if (values.password !== values.confirmpassword) {
        alert("New and Confirm passwords should be same");
      } else {
        props.addpassword(userID, values.password);
        // setPassword(false);
      }
    },
  });

  const Emailformik = useFormik({
    initialValues: {
      email: props.Account.email,
    },
    validationSchema: EmailvalidationSchema,
    onSubmit: (values) => {
      props.addemail(userID, values.email);
      setEmailEdit(false);
    },
  });

  const formik = useFormik({
    initialValues: {
      firstName: props.Account && props.Account.firstName,
      lastName: props.Account && props.Account.lastName,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.EditAccount(userID, values.firstName, values.lastName);
      setEdit(false);
      formik.touched.firstName = false;
      formik.touched.lastName = false;
    },
  });
  const handleClickOpen = () => {
    setEdit(!edit);
  };
  const handleEmailOpen = () => {
    setEmailEdit(!Emailedit);
  };
  const handlePasswordOpen = () => {
    setPassword(!PasswordEdit);
    props.clearPasswordProps();
  };

  React.useEffect(
    () => {
      if (props.UpdatedPassword && props.UpdatedPassword === true) {
        setPassword(false);
      }
    },
    // eslint-disable-next-line
    [props.UpdatedPassword]
  );
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {props.isAccountLoading && props.isAccountLoading === true ? (
            <CircularProgress />
          ) : props.isAccount &&
            props.isAccount === true &&
            props.Account &&
            props.Account !== null ? (
            <>
              {edit === false ? (
                <Grid
                  container
                  spacing={2}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Grid
                    item
                    xs={1}
                    md={0}
                    sx={{ display: { md: "none", xs: "flex" } }}
                  />
                  <Grid item xs={10} md={5}>
                    <div className={classes.formlabel}>
                      <p className={classes.label1}>{"*"}</p>
                      <p className={classes.label2}>&nbsp;{"First Name"}</p>
                    </div>
                    <div type="text" className={classes.valueDiv}>
                      {props.Account.firstName}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    md={0}
                    sx={{ display: { md: "none", xs: "flex" } }}
                  />
                  <Grid
                    item
                    xs={1}
                    md={0}
                    sx={{ display: { md: "none", xs: "flex" } }}
                  />
                  <Grid
                    item
                    xs={10}
                    md={5}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div className={classes.formlabel}>
                      <p className={classes.label1}>{"*"}</p>
                      <p className={classes.label2}>&nbsp;{"Last Name"}</p>
                    </div>
                    <div type="text" className={classes.valueDiv}>
                      {props.Account.lastName}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    md={0}
                    sx={{ display: { md: "none", xs: "flex" } }}
                  />
                  <Grid
                    item
                    xs={1}
                    md={0}
                    sx={{ display: { md: "none", xs: "none" } }}
                  />
                  <Grid
                    item
                    xs={10}
                    md={2}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      paddingTop: isSmallScreen ? "36px" : "16px",
                    }}
                  >
                    {!isSmallScreen ? (
                      <div className={classes.formlabel}>&nbsp; </div>
                    ) : null}

                    <button className="button" onClick={handleClickOpen}>
                      <EditIcon fontSize="inherit" />
                    </button>
                  </Grid>
                </Grid>
              ) : (
                <div>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={10}
                      md={5}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <div className={classes.formlabel}>
                        <p className={classes.label1}>{"*"}</p>
                        <p className={classes.label2}>&nbsp;{"First Name"}</p>
                      </div>
                      <div type="text">
                        <TextField
                          id="firstnametextfield"
                          fullWidth
                          inputProps={{
                            style: {
                              width: "100%",
                              background: "rgba(133, 190, 73, 0.2)",
                              border: "0.1px solid rgba(133, 190, 73, 0.2)",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              height: "45px",
                              textAlign: "left",
                            },
                          }}
                          color="success"
                          className={classes.input}
                          type="text"
                          name="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstName)
                          }
                          helperText={
                            formik.touched["firstName"] &&
                            formik.errors["firstName"]
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      md={5}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <div className={classes.formlabel}>
                        <p className={classes.label1}>{"*"}</p>
                        <p className={classes.label2}>&nbsp;{"Last Name"}</p>
                      </div>
                      <div type="text">
                        <TextField
                          id="lastnametextfield"
                          fullWidth
                          inputProps={{
                            style: {
                              width: "100%",
                              background: "rgba(133, 190, 73, 0.2)",
                              border: "0.1px solid rgba(133, 190, 73, 0.2)",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              height: "45px",
                              textAlign: "left",
                            },
                          }}
                          color="success"
                          className={classes.input}
                          type="text"
                          name="lastName"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.lastName &&
                            Boolean(formik.errors.lastName)
                          }
                          helperText={
                            formik.touched.lastName && formik.errors.lastName
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      md={2}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        paddingTop: isSmallScreen ? "36px" : "16px",
                      }}
                    >
                      {!isSmallScreen ? (
                        <div className={classes.formlabel}>&nbsp; </div>
                      ) : null}
                      <button
                        type="button"
                        onClick={formik.handleSubmit}
                        className="button"
                      >
                        <CheckIcon fontSize="inherit" />
                      </button>
                    </Grid>
                  </Grid>
                </div>
              )}
              <Grid
                container
                spacing={2}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Grid
                  item
                  xs={1}
                  md={0}
                  sx={{ display: { md: "none", xs: "flex" } }}
                />
                <Grid
                  item
                  xs={10}
                  md={5}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <div className={classes.formlabel01}>
                    <p className={classes.label1}>{"*"}</p>
                    <p className={classes.label2}>&nbsp;{"Mobile Number"}</p>
                  </div>
                  <div>
                    <Contact props={props} />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={0}
                  sx={{ display: { md: "none", xs: "flex" } }}
                />
                <Grid
                  item
                  xs={1}
                  md={0}
                  sx={{ display: { md: "none", xs: "flex" } }}
                />
                {Emailedit === false ? (
                  <>
                    <Grid
                      item
                      xs={10}
                      md={5}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <div className={classes.formlabel}>
                        <p className={classes.label1}>{"*"}</p>
                        <p className={classes.label2}>&nbsp;{"Email"}</p>
                      </div>
                      <div type="text" className={classes.valueDiv}>
                        {props.Account.email}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "none" } }}
                    />
                    <Grid
                      item
                      xs={10}
                      md={2}
                      style={{ paddingTop: isSmallScreen ? "36px" : "16px" }}
                    >
                      {!isSmallScreen ? (
                        <div className={classes.formlabel}>&nbsp; </div>
                      ) : null}
                      <button className="button" onClick={handleEmailOpen}>
                        <EditIcon fontSize="inherit" />
                      </button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={10}
                      md={5}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Grid
                        item
                        xs={1}
                        md={0}
                        sx={{ display: { md: "none", xs: "flex" } }}
                      />
                      <div className={classes.formlabel}>
                        <p className={classes.label1}>{"*"}</p>
                        <p className={classes.label2}>&nbsp;{"Email"}</p>
                      </div>
                      <div type="text">
                        <TextField
                          fullWidth
                          inputProps={{
                            style: {
                              width: "100%",
                              background: "rgba(133, 190, 73, 0.2)",
                              border: "0.1px solid rgba(133, 190, 73, 0.2)",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              height: "45px",
                              textAlign: "left",
                            },
                          }}
                          color="success"
                          className={classes.input}
                          variant="outlined"
                          name="email"
                          value={Emailformik.values.email}
                          onChange={Emailformik.handleChange}
                          error={
                            Emailformik.touched.email &&
                            Boolean(Emailformik.errors.email)
                          }
                          helperText={
                            Emailformik.touched.email &&
                            Emailformik.errors.email
                          }
                          required
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "none" } }}
                    />
                    <Grid
                      item
                      xs={10}
                      md={2}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        paddingTop: isSmallScreen ? "36px" : "16px",
                      }}
                    >
                      {!isSmallScreen ? (
                        <div className={classes.formlabel}>&nbsp; </div>
                      ) : null}
                      <button
                        type="button"
                        onClick={Emailformik.handleSubmit}
                        className="button"
                      >
                        <CheckIcon fontSize="inherit" />
                      </button>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  justifyContent: "left",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Grid
                  item
                  xs={1}
                  md={0}
                  sx={{ display: { md: "none", xs: "flex" } }}
                />
                {PasswordEdit === false ? (
                  <>
                    <Grid item xs={10} md={5}>
                      <div className={classes.formlabel01}>
                        <p className={classes.label1}>{"*"}</p>
                        <p className={classes.label2}>&nbsp;{"Password"}</p>
                      </div>
                      <div type="text" className={classes.valueDiv}>
                        {"***********************"}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={10}
                      md={2}
                      style={{ paddingTop: isSmallScreen ? "36px" : "60px" }}
                    >
                      {/* {!isSmallScreen ? (
                        <div className={classes.formlabel}>&nbsp; </div>
                      ) : null} */}
                      <button className="button" onClick={handlePasswordOpen}>
                        <EditIcon fontSize="inherit" />
                      </button>
                    </Grid>
                  </>
                ) : props.Account && props.Account.password === null ? (
                  <>
                    <Grid item xs={10} md={5}>
                      <div className={classes.formlabel}>
                        <p className={classes.label1}>{"*"}</p>
                        <p className={classes.label2}>&nbsp;{"New Password"}</p>
                      </div>
                      <div type="text">
                        <TextField
                          autoComplete="new-password"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          id="newpasswordtextfield"
                          fullWidth
                          variant="outlined"
                          type={showPassword2 ? "text" : "password"}
                          name="password"
                          onChange={AddPasswordFormik.handleChange}
                          value={AddPasswordFormik.values.password}
                          className={classes.input}
                          InputProps={{
                            style: {
                              width: "100%",
                              background: "rgba(133, 190, 73, 0.2)",
                              border: "0.1px solid rgba(133, 190, 73, 0.2)",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              height: "45px",
                              textAlign: "left",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword2}
                                  onMouseDown={handleMouseDownPassword2}
                                >
                                  {showPassword2 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            AddPasswordFormik.touched.password &&
                            AddPasswordFormik.errors.password
                              ? true
                              : false
                          }
                          helperText={
                            <span style={{ width: "100%" }}>
                              {AddPasswordFormik.touched.password &&
                                AddPasswordFormik.errors.password}
                            </span>
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={10}
                      md={5}
                      style={{ paddingTop: { isSmallScreen: "30px" } }}
                    >
                      <div className={classes.formlabel}>
                        <p className={classes.label1}>{"*"}</p>
                        <p className={classes.label2}>
                          &nbsp;{"Confirm Password"}
                        </p>
                      </div>
                      <div type="text">
                        <TextField
                          autoComplete="new-password"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          id="confirmpasswordtextfield"
                          fullWidth
                          variant="outlined"
                          type={showPassword2 ? "text" : "password"}
                          name="confirmpassword"
                          // onBlur={handleBlur}
                          onChange={AddPasswordFormik.handleChange}
                          value={AddPasswordFormik.values.confirmpassword}
                          className={classes.input}
                          InputProps={{
                            style: {
                              width: "100%",
                              background: "rgba(133, 190, 73, 0.2)",
                              border: "0.1px solid rgba(133, 190, 73, 0.2)",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              height: "45px",
                              textAlign: "left",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword2}
                                  onMouseDown={handleMouseDownPassword2}
                                >
                                  {showPassword2 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            AddPasswordFormik.touched.confirmpassword &&
                            AddPasswordFormik.errors.confirmpassword
                              ? true
                              : false
                          }
                          helperText={
                            AddPasswordFormik.touched.confirmpassword &&
                            AddPasswordFormik.errors.confirmpassword
                              ? AddPasswordFormik.errors.confirmpassword
                              : null
                          }
                        />
                        {/* &nbsp;{" "} */}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={10}
                      md={2}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      {/* <div>&nbsp;</div> */}
                      {!isSmallScreen ? <div>&nbsp;</div> : null}
                      <button
                        type="button"
                        onClick={AddPasswordFormik.handleSubmit}
                        className="button"
                      >
                        <CheckIcon fontSize="inherit" />
                      </button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={10} md={5}>
                      <div className={classes.formlabel}>
                        <p className={classes.label1}>{"*"}</p>
                        <p className={classes.label2}>&nbsp;{"Old Password"}</p>
                      </div>
                      <div type="text">
                        <TextField
                          autoComplete="new-password"
                          id="oldpasswordtextfield"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          fullWidth
                          variant="outlined"
                          type={showPassword1 === true ? "text" : "password"}
                          name="oldpassword"
                          // onBlur={PasswordFormik.handleBlur}
                          onChange={PasswordFormik.handleChange}
                          value={PasswordFormik.values.oldpassword}
                          color="success"
                          className={classes.input}
                          InputProps={{
                            style: {
                              width: "100%",
                              background: "rgba(133, 190, 73, 0.2)",
                              border: "0.1px solid rgba(133, 190, 73, 0.2)",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              height: "45px",
                              textAlign: "left",
                            },

                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{
                                    cursor: "pointer",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  onClick={handleClickShowPassword1}
                                >
                                  {showPassword1 ? (
                                    <VisibilityIcon
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ cursor: "pointer" }}
                                    />
                                  )}
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            PasswordFormik.touched.oldpassword &&
                            PasswordFormik.errors.oldpassword
                              ? true
                              : props.passworderror && props.errorMessage
                              ? true
                              : false
                          }
                          helperText={
                            PasswordFormik.touched.oldpassword &&
                            PasswordFormik.errors.oldpassword
                              ? PasswordFormik.errors.oldpassword
                              : props.passworderror && props.errorMessage
                              ? props.errorMessage
                              : null
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid item xs={10} md={5}>
                      <div className={classes.formlabel}>
                        <p className={classes.label1}>{"*"}</p>
                        <p className={classes.label2}>&nbsp;{"New Password"}</p>
                      </div>
                      <div type="text">
                        <TextField
                          autoComplete="new-password"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          id="newpasswordtextfield"
                          fullWidth
                          variant="outlined"
                          type={showPassword2 ? "text" : "password"}
                          name="password"
                          onChange={PasswordFormik.handleChange}
                          value={PasswordFormik.values.password}
                          className={classes.input}
                          sx={{
                            marginBottom:
                              PasswordFormik.touched.password &&
                              PasswordFormik.errors.password
                                ? "20px"
                                : "0px",
                          }}
                          InputProps={{
                            style: {
                              width: "100%",
                              background: "rgba(133, 190, 73, 0.2)",
                              border: "0.1px solid rgba(133, 190, 73, 0.2)",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              height: "45px",
                              textAlign: "left",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword2}
                                  onMouseDown={handleMouseDownPassword2}
                                >
                                  {showPassword2 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            PasswordFormik.touched.password &&
                            PasswordFormik.errors.password
                              ? true
                              : false
                          }
                          helperText={
                            <span style={{ width: "100%" }}>
                              {PasswordFormik.touched.password &&
                                PasswordFormik.errors.password}
                            </span>
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={10}
                      md={5}
                      sx={{ paddingTop: { xs: "10px" } }}
                    >
                      <div className={classes.formlabel}>
                        <p className={classes.label1}>{"*"}</p>
                        <p className={classes.label2}>
                          &nbsp;{"Confirm Password"}
                        </p>
                      </div>
                      <div type="text">
                        <TextField
                          autoComplete="new-password"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          id="confirmpasswordtextfield"
                          fullWidth
                          variant="outlined"
                          type={showPassword2 ? "text" : "password"}
                          name="confirmpassword"
                          onChange={PasswordFormik.handleChange}
                          value={PasswordFormik.values.confirmpassword}
                          className={classes.input}
                          InputProps={{
                            style: {
                              width: "100%",
                              background: "rgba(133, 190, 73, 0.2)",
                              border: "0.1px solid rgba(133, 190, 73, 0.2)",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              height: "45px",
                              textAlign: "left",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword2}
                                  onMouseDown={handleMouseDownPassword2}
                                >
                                  {showPassword2 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            PasswordFormik.touched.confirmpassword &&
                            PasswordFormik.errors.confirmpassword
                              ? true
                              : false
                          }
                          helperText={
                            PasswordFormik.touched.confirmpassword &&
                            PasswordFormik.errors.confirmpassword
                              ? PasswordFormik.errors.confirmpassword
                              : null
                          }
                        />
                        &nbsp;{" "}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={1}
                      md={0}
                      sx={{ display: { md: "none", xs: "flex" } }}
                    />
                    <Grid
                      item
                      xs={10}
                      md={2}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      {!isSmallScreen ? <div>&nbsp;</div> : null}
                      <button
                        type="button"
                        onClick={PasswordFormik.handleSubmit}
                        className="button"
                      >
                        <CheckIcon fontSize="inherit" />
                      </button>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          ) : props.isAccountFailed && props.isAccountFailed === true ? (
            "OOPS"
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MyAccount;
