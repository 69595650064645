import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import BookSlot from "./BookSlot";
import AddCookingInstructions from "./CookingInfo/AddCookingInstructions";
import ChooseAddressSection from "./Address";
import StoreInfo from "./StoreInformationHead/StoreInfo";
import PaymentDetails from "./ItemsDetails/PaymentDetails";
import CheckoutHeader from "../Navbar/CheckoutHeader";
import AddDeliveryInstructions from "./DeliveryInfo/AddDeliveryInstructions";
import CancellationPolicyCard from "./CancellationPolicyCard";
import PaymentType from "./PaymentType";
import EmptyCart from "./CartEmptyPage";
import Pay from "./Pay/pay";
import Contact from "../CheckoutComponents/Contact/index";
import { Divider } from "@mui/material";
import { db } from "../db";
import './paymentScreen.css';

const PaymentScreen = (props) => {

  const [showAddPhoneNumber, setShowAddPhoneNumber] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { cart } = db;
      const cartdata = await cart.toArray();
      if (cartdata && cartdata[0] && cartdata[0].products) {
        props.updatefullcart(
          localStorage.getItem("userid"),
          cartdata[0].storeid,
          cartdata[0].products.map((p) => ({
            productid: p.productid,
            productPrice: p.productprice,
            quantity: p.cartquantity,
            price:
              p.productprice &&
              parseFloat(p.productprice * Number(p.cartquantity)).toFixed(2),
          }))
        );
      }
    };

    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    props?.getAvailableAddresses(localStorage.getItem("userid"));
    props?.getAddress(localStorage.getItem("userid"));
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchUserDetails = async () => {
      await props?.getUserDetails(localStorage.getItem("userid"));
      // Check if isAccount is true and phoneNumber is null
      if (
        props?.isCheckoutPhoneNumber === true &&
        props?.placeorderphonenumber === null
      ) {
        setShowAddPhoneNumber(true);
      } else {
        setShowAddPhoneNumber(false);
      }
    };

    fetchUserDetails();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  const selectedAddress = props?.Cart?.availableAddress?.find(
    (item) => item._id === props?.Cart?.addressid
  );
  let concated_Address = selectedAddress
    ? `${selectedAddress.houseNumber} ${selectedAddress.landmark} ${selectedAddress.locationDescription}`
    : "";
  const handlePreviousPage = () => {
    props.navigate(`/stores/categories`);
  };
  console.log("console",props?.Cart ) 
  return (
    <div>
      {(props.isAccount === true && props.Account.phoneNumber === undefined) ||
        null ? (
        <div> {showAddPhoneNumber && <Contact props={props} />}</div>
      ) : null}

      <CheckoutHeader navigate={props.navigate} props={props}>
        {
          // (props?.isCartLoading === false && props?.Cart && props?.Cart.length === 0) ||
          //   props?.Cart === null ? (
          (props?.Cart && props?.Cart.length === 0  ) ? (
            <EmptyCart navigate={props.navigate} props={props} />
          ) : 
          
          (
            <Grid
              container
              sx={{
                width: "100%",
                justifyContent: "center",
                padding: { xs: "5px", sm: "10px", md: "30px" },
              }}
              spacing={2}
            >
              <Grid item xs={12}>
                <Grid id="conatiner" container spacing={2}>
                  <Grid id="firstchild" item xs={12} md={7}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        md={12}
                        sx={{ paddingTop: "19px", width: "100%" }}
                      >
                        <StoreInfo
                          // id={props?.Cart?.storeid?.id}
                          // storename={props?.Cart?.storeid?.storeName}
                          // storetype={props?.Cart?.storeid?.businessType}
                          // storeimage={props?.Cart?.storeid?.storeLogoUrl}
                          // storecontact={props?.Cart?.storeid?.storeManagerMobile}
                          // storelocation={props?.Cart?.storeid?.storeLocation}
                          {...props}
                        />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sx={{ paddingTop: "19px", width: "100%" }}
                      >
                        <ChooseAddressSection
                          // addresslist={props?.Cart?.availableAddress}
                          {...props}
                        />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sx={{ paddingTop: "19px", width: "100%" }}
                      >
                        <PaymentDetails
                          // itemTotal={props?.Cart?.totalPrice}
                          // deliveryCharge={props?.Cart?.deliveryCharge}
                          // packingCharge={props?.Cart?.packingCharge}
                          // handlingCharge={props?.Cart?.handlingCharge}
                          // deliveryTip={props?.Cart?.deliveryTip}
                          // GST={props?.Cart?.GST}
                          // total={props?.Cart?.total}
                          // isCouponApplied={props?.Cart?.couponIsApplied}
                          // couponCode={props?.Cart?.couponCode}
                          // couponDiscount={props?.Cart?.discountPrice}
                          // totalPayable={props?.Cart?.totalPayable}
                          {...props}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sx={{ paddingTop: "19px", width: "100%" }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item xs={6}>
                        <div
                          style={{
                            color: "#061303",
                            textAlign: "left",
                            fontFamily: "Nunito",
                            fontSize: "0.875rem",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                            opacity: "0.7",
                            cursor: "pointer",
                          }}
                          onClick={() => handlePreviousPage()}
                        >
                          Previous
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <Pay props={props} concated_Address={concated_Address} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={0.5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Divider orientation="vertical" variant="middle" flexItem />
                  </Grid>
                  <Grid id="second child" item xs={12} md={4}>
                    <Grid container spacing={2}>
                      <Grid item sx={{ paddingTop: "19px", width: "100%" }}>
                        <BookSlot

                          // deliveryDays={props?.Cart?.storeid?.availableDeliveryDays}
                          {...props}
                        />
                      </Grid>
                      <Grid item sx={{ paddingTop: "19px", width: "100%" }}>
                        <AddCookingInstructions {...props} />
                      </Grid>
                      <Grid item sx={{ paddingTop: "19px", width: "100%" }}>
                        <AddDeliveryInstructions {...props} />
                      </Grid>
                      <Grid item sx={{ paddingTop: "19px", width: "100%" }}>
                        <CancellationPolicyCard {...props} />
                      </Grid>
                      <Grid item sx={{ paddingTop: "19px", width: "100%" }}>
                        <PaymentType {...props} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
      </CheckoutHeader>

    </div>
  );
};

export default PaymentScreen;
