import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    root: {
        paddingBottom: '20px',
        boxShadow: 'rgb(0 0 0 / 4%) 0px 3px 5px -1px, rgb(0 0 0 / 4%) 0px 6px 10px 0px, rgb(0 0 0 / 4%) 0px 1px 18px 0px',
        textAlign: 'center',
        borderRadius: '10px',
        border: 'none',
        color: 'black',
        fontWeight: '600',
        backgroundColor: 'white',
        position:'relative',
        zIndex:1,
        cursor:'pointer',
        userSelect:'none',
        maxWidth:'9.375rem',
        width: '150px',
        opacity:1,
    },
    discountcontainer:{
        borderRadius:'12px',
        paddingBottom:'15px',
        width:'100%',
    },
    discount:{
        backgroundColor: '#EA4335',
        width:'75%',
        borderRadius: '10px 0px',
        color:'white',
        textAlign:'center',
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
        fontSize:'12px',
        height:'22px'
    },
    emptydiscount:{
        backgroundColor:'white',
        width:'50%',
        borderRadius: '20px 0px',
        color:'white',
        textAlign:'center',
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
        fontSize:'12px',
        height:'22px'
    },
    imagecontainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '15px',
    },
    image:{
        width:'100px',
        height: '100px',
        maxWidth:'100%',
        maxHeight:'100%',
    },
    disabledimage:{
        width:'100px',
        height: '100px',
        maxWidth:'100%',
        maxHeight:'100%',
        filter:`saturate(10%)`,
    },
    productnamecontainer: {
        width: '100%',  
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection:'column',
    },
    productname: {
        textOverflow: 'ellipsis',
        whiteSpace: ' nowrap',
        overflow: 'hidden',
        width: '80%',
        height:'100%',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'inline-block',
    },
    price: {
        width: '100%', 
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        paddingTop:'7px',
        fontWeight:700
    },
    actualprice:{
        textDecorationLine:'line-through',
        fontSize: '12px',
        color:'#000000',
        opacity:0.3,
        paddingRight:'5px',
    },
    sellingpricegreen:{
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        color: '#34A853',
        fontSize:'14px',
        paddingLeft:'3px',
    },
    sellingpriceblack:{
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        color:"black",
        fontSize:'14px', 
        paddingLeft:'3px',
    },
    uom:{
        fontSize: '14px',
        color:'#EA4335',
        // opacity:0.3,
        paddingBottom:'15px',
        height: '15px',
        fontWeight: 800,
    },
    emptyuom:{
        height: '15px',
    },
    addtocartbutton: {
        position: 'absolute',
        bottom: '-20px',
        left: '15%',
        width: '70%',
        zIndex:2,
    },
}));
