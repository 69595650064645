import React from "react";
import Banner from "../../Components/Banner";
import Whyus from "../../Components/Whyus";
import AlertBar from "../../Components/AlertBar";
import loadable from "@loadable/component";
import GetApp from "../../Components/GetApp";

const Features = loadable(() => import("../../Components/Features"));
const Aboutus = loadable(() => import("../../Components/Aboutus"));
const Footer = loadable(() => import("../../Components/Footer"));

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (this.props.isLogInSuccess === true && this.props.isLogin) {
      localStorage.setItem("userid", this.props.Login.userid);
      localStorage.setItem("token", this.props.Login.token);
      // localStorage.setItem('lattitude', this.props.Login.primaryLatitude !== null &&
      //   this.props.Login.primaryLatitude);
      // localStorage.setItem('longitude', this.props.Login.primaryLongitude && this.props.Login.primaryLongitude);
      this.props.navigate("/stores");
    } else if (this.props.isSignUpSuccess && this.props.Signup) {
      localStorage.setItem("userid", this.props.Signup.userid);
      localStorage.setItem("token", this.props.Signup.token);
      // localStorage.setItem('lattitude', this.props.Signup.primaryLatitude &&
      //   this.props.Signup.primaryLatitude);
      // localStorage.setItem('longitude', this.props.Signup.primaryLongitude && this.props.Signup.primaryLongitude);
      this.props.navigate("/stores");
    } else if (this.props.isVerifyOTP && this.props.VerifyOTP) {
      localStorage.setItem(
        "userid",
        this.props.VerifyOTP.userid && this.props.VerifyOTP.userid
      );
      localStorage.setItem(
        "token",
        this.props.VerifyOTP.token && this.props.VerifyOTP.token
      );
      this.props.navigate("/stores");
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.isLogInSuccess !== this.props.isLogInSuccess &&
      this.props.isLogInSuccess === true
    ) {
      localStorage.setItem("userid", this.props.Login.userid);
      localStorage.setItem("token", this.props.Login.token);
      // localStorage.setItem('lattitude', this.props.Login.primaryLatitude &&
      //   this.props.Login.primaryLatitude);
      // localStorage.setItem('longitude', this.props.Login.primaryLongitude && this.props.Login.primaryLongitude);
      // localStorage.setItem('location', this.props.Login.primaryLocation && this.props.Login.primaryLocation);
      this.props.navigate("/stores");
    } else if (prevProps.isSignUpSuccess !== this.props.isSignUpSuccess) {
      localStorage.setItem("userid", this.props.Signup.userid);
      localStorage.setItem("token", this.props.Signup.token);
      this.props.navigate("/stores");
    } else if (prevProps.isVerifyOTP !== this.props.isVerifyOTP) {
      localStorage.setItem(
        "userid",
        this.props.VerifyOTP.userid && this.props.VerifyOTP.userid
      );
      localStorage.setItem(
        "token",
        this.props.VerifyOTP.token && this.props.VerifyOTP.token
      );
      this.props.navigate("/stores");
    }
  }
  render() {
    return (
      <>
        <Banner {...this.props}/>
        <Features />
        <Aboutus />
        <Whyus {...this.props} />
        <GetApp />
        <Footer
          navigate={this.props.navigate}
          params={this.props.params}
          {...this.props} />
        {this.props.isLogInFailed === true &&
          <AlertBar type={'error'} message={this.props.loginerror} {...this.props} props={this.props} />
        }
        {this.props.isSignUpFailed === true &&
          <AlertBar type={'error'} message={this.props.signuperror} {...this.props} props={this.props} />
        }
        {this.props.isOTPFailed && this.props.isOTPFailed === true &&
          <AlertBar type={'error'} message={this.props.errorMessage.message} {...this.props} props={this.props} />
        }
        {this.props.isMobileSignupFailed && this.props.isMobileSignupFailed === true &&this.props.mobileerrorMessage!=="Mobile Number not verified"&&
          <AlertBar type={'error'} message={this.props.mobileerrorMessage} {...this.props} props={this.props} />}
        {this.props.isVerifyOTPFailed && this.props.isVerifyOTPFailed === true &&
          <AlertBar type={'error'} message={this.props.verifyOTPerror} {...this.props} props={this.props} />}
        {this.props.isReset && this.props.isReset === true &&
          <AlertBar type={'success'} message={`${this.props.ResetPassword}! Please check spam folder incase email not found in inbox`} {...this.props} props={this.props} />}
        {this.props.isResetFailed && this.props.isResetFailed === true &&
          <AlertBar type={'error'} message={this.props.reseterrorMessage} {...this.props} props={this.props} />}
      </>
    );
  }
}

export default LandingPage;
