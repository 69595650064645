import React from "react";
import { useStyles } from "./style.js";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { addressvalidationSchema } from "./utils.js";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import HomeIcon from "@mui/icons-material/Home";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { Grid } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PinDropIcon from "@mui/icons-material/PinDrop";
import "./index.css";

const EditAddress = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [editlocation, setEditLocation] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const formik = useFormik({
    initialValues: {
      housename: props.houseNumber,
      landmark: props.landmark,
      pincode: props.pincode,
    },
    validationSchema: addressvalidationSchema,
    onSubmit: (values) => {
      props.EditAddress(
        localStorage.getItem("userid"),
        props.id,
        values.housename,
        values.pincode,
        props.houseNumber,
        props.locationDescription,
        props.Coordinates &&
          props.Coordinates.data &&
          props.Coordinates.data.googlemapplacedetails &&
          props.Coordinates.data.googlemapplacedetails.lat
          ? props.Coordinates.data.googlemapplacedetails.lat
          : props.locationLat,
        props.Coordinates &&
          props.Coordinates.data &&
          props.Coordinates.data.googlemapplacedetails &&
          props.Coordinates.data.googlemapplacedetails.lng
          ? props.Coordinates.data.googlemapplacedetails.lng
          : props.locationLng,
        props.locationLat,
        props.locationLng,
        props.landmark,
        values.landmark,
        props.type
      );
      handleClose();
    },
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const selectLocation = (placeid, description) => {
    setEditLocation(false);
    setInputValue("");
    formik.values.pincode = description;
    props.suggestioncordinates(placeid);
  };
  React.useEffect(
    () => {
      props.setEditAddressFields(
        props.locationDescription,
        props.locationLat,
        props.locationLng,
        props.Landmark,
        props.houseNumber
      );
      setEditLocation(false);
      formik.setFieldValue("housename", props.houseNumber);
      formik.setFieldValue("landmark", props.Landmark);
      formik.setFieldValue("pincode", props.locationDescription);
    },
    // eslint-disable-next-line
    [props.houseName]
  );
  return (
    <>
      <EditIcon
        style={{
          cursor: "pointer",
          color: "#85BE49",
          fontSize: fullScreen ? "1.3rem" : "1.5rem",
        }}
        color="#85BE49"
        onClick={handleClickOpen}
      />
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            width: "100%",
          },
        }}
        fullScreen={fullScreen}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          className={classes.modalHeading}
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "#fff", // Keep background consistent when scrolling
          }}
        >
          <span
            style={{
              width: "25px",
              float: "right",
              color: "#ffffff",
              borderRadius: "50%",
              fontSize: "14px",
              height: "25px",
              background: "#BDBDBD",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CloseIcon
              style={{ cursor: "pointer", fontSize: "14px" }}
              onClick={handleClose}
            />
          </span>
          <p className="EditHeading">{"Edit Address"}</p>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ width: "100%" }}>
            <div
              style={{
                paddingBottom: "20px",
                width: "100%",
                position: "relative",
                zIndex: 1,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "45px",
                  backgroundColor: "rgba(105, 168, 92, 0.1)",
                  borderRadius: "13px",
                  border: "1px solid rgba(0,0,0,0.1)",
                  boxShadow: "0px 8px 44px rgba(0, 0, 0, 0.04)",
                  color: "rgba(105, 168, 92, 0.5)",
                  fontSize: "14px",
                  paddingLeft: "5px",
                  // paddingTop: '5px',
                  // paddingBottom: '5px',
                  paddingRight: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#85BE49",
                    borderRadius: "9px",
                    width: "45px",
                    height: "35px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    color: "#FFFFFF",
                  }}
                >
                  <PinDropIcon />
                </div>
                {editlocation === false ? (
                  <div className="location">
                    <input
                      label="invite link"
                      placeholder={formik.values.pincode}
                      style={{
                        background: "transparent",
                        display: "flex",
                        border: "none",
                        width: "100%",
                        textAlign: "left",
                        fontWeight: 600,
                        fontSize: fullScreen ? "13px" : "14px",
                        paddingLeft: "13px",
                        color: "rgba(105, 168, 92, 0.8)",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      type="text"
                      value={formik.values.pincode}
                      readOnly
                    />
                    <span
                      onClick={() => {
                        setEditLocation(true);
                        formik.setFieldValue("pincode", "");
                      }}
                      style={{
                        background: "transparent",
                        display: "flex",
                        border: "none",
                        width: "10%",
                        textAlign: "right",
                        fontWeight: 600,
                        fontSize: "14px",
                        paddingBottom: "5px",
                        justifyContent: "right",
                        alignItems: "center",
                        color: "rgba(105, 168, 92, 0.8)",
                        cursor: "pointer",
                      }}
                    >
                      <ModeEditIcon />
                      {/* &nbsp;{"Edit Location"} */}
                    </span>
                  </div>
                ) : (
                  <div className="location">
                    <input
                      label="invite link"
                      id="autocomplete"
                      placeholder="Search for location near you"
                      style={{
                        background: "transparent",
                        display: "flex",
                        border: "none",
                        width: "100%",
                        textAlign: "left",
                        fontWeight: 600,
                        fontSize: "14px",
                        paddingLeft: "13px",
                        color: "rgba(105, 168, 92, 0.8)",
                      }}
                      autoComplete="off"
                      name={"pincode"}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                        formik.setFieldValue("pincode", e.target.value);
                        props.autocomplete(e.target.value);
                      }}
                      value={formik.values.pincode}
                      type="text"
                      className="input"
                    />
                  </div>
                )}
              </div>
            </div>
            {inputValue !== "" &&
              props.isSuggestions &&
              props.isSuggestions === true &&
              props.Suggestions &&
              props.Suggestions.length > 0 && (
                <div className={classes.suggestionDiv}>
                  <span className={classes.suggestionsHeading}>
                    {"SEARCH RESULTS"}
                  </span>
                  {props.Suggestions.map((s) => (
                    <>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.suggestionList}
                        onClick={() => {
                          selectLocation(
                            s.place_id && s.place_id,
                            s.description && s.description
                          );
                          formik.setFieldValue("pincode", s.description);
                        }}
                      >
                        <Grid item xs={1}>
                          <FmdGoodOutlinedIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs={11}>
                          <span style={{ fontWeight: 700, fontSize: "14px" }}>
                            {s.description.split(",", 1)}
                          </span>

                          <div style={{ fontSize: "12px", color: "#BDBDBD" }}>
                            {s.description.substring(
                              s.description.indexOf(",") + 1
                            )}
                          </div>
                          {/* <Divider/> */}
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </div>
              )}
            <div className={classes.formlabel}>
              {"Building Name / House Name"}
            </div>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  width: "100%",
                  background: "#ffffff",
                  border: " 1px solid rgba(133, 190, 73, 0.19)",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  height: "45px",
                  textAlign: "left",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeIcon />
                  </InputAdornment>
                ),
              }}
              name="housename"
              value={formik.values.housename}
              onChange={formik.handleChange}
              error={
                formik.touched.housename && Boolean(formik.errors.housename)
              }
              helperText={formik.touched.housename && formik.errors.housename}
              className={classes.input}
            />
            <div className={classes.formlabel}>{"Landmark"}</div>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  width: "100%",
                  background: "#ffffff",
                  border: " 1px solid rgba(133, 190, 73, 0.19)",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  height: "45px",
                  textAlign: "left",
                },
              }}
              name="landmark"
              value={formik.values.landmark}
              onChange={formik.handleChange}
              error={formik.touched.landmark && Boolean(formik.errors.landmark)}
              helperText={formik.touched.landmark && formik.errors.landmark}
            />
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            {formik.values.housename === props.houseName &&
            formik.values.landmark === props.landmark &&
            formik.values.pincode === props.editLocation ? (
              <button className={classes.buttondisabled}>Save Address</button>
            ) : (
              <button className="savebutton" type={"submit"}>
                Save Address
              </button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default EditAddress;
