// /* eslint-disable no-unused-vars */
// import { Grid } from "@mui/material";
// import { ReactComponent as Closevector } from "../../../Assets/Icons/Close vector.svg";
// import Input from "@mui/material/Input";
// import InputAdornment from "@mui/material/InputAdornment";
// import React, { useState, forwardRef, useEffect } from "react";
// import "./index.css";
// import {message} from 'antd'


// const deliverytips = [
//   {
//     id:1,
//     tip:20
//   },
//   {
//     id:2,
//     tip:30
//   },
//   {
//     id:3,
//     tip:40
//   }
// ];

// const DeliveryTipsSection = forwardRef(({ props, isOpened, setOpen }, ref) => {
 
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [defaultOption, setRemoveDefault] = useState(true);
//   const [isOtherSelected, setOtherSelected] = useState(false);
//   const [customTipValue, setCustomTipValue] = useState(false);
//   const [dummyCustom,setDummyCustom]=useState("")

//   useEffect(() => {
//     if (isOpened && ref.current) {
//       ref.current.scrollIntoView({ behavior: 'smooth' });
//       ref.current.focus();
//     }
//   }, [isOpened, ref]);
  

//   const handleOpen = () => {
//     setOpen(!isOpened);
//   };
//   const handleDeliveryTip = (storeid, userid, delivertip) => {
    
//     var parsedDeliveryTip
//     if(delivertip===""){
//         parsedDeliveryTip=0
//     }else{
//        parsedDeliveryTip = Number(delivertip);
//     }
//     props.adddeliveryTip(storeid, userid, parsedDeliveryTip);
//   };

//   const handleAddClick = () => {
//     // Check if dummyCustom is empty
//     if (dummyCustom === "") {
//       message.error({
//         content: "Please add Delivery Tip",
//         className: "custom-class",
//         style: {
//           marginTop: "20vh",
//         },
//         duration: 2.5,
//       });
//     }
//     // Check if dummyCustom is greater than zero
//     else if (parseFloat(dummyCustom) <= 0) {
//       message.error({
//         content: "Value should be greater than zero",
//         className: "custom-class",
//         style: {
//           marginTop: "20vh",
//         },
//         duration: 2.5,
//       });
//     }
//     // Check if dummyCustom contains any letters (assuming you want to disallow non-numeric characters)
//     else if (!/^\d+(\.\d+)?$/.test(dummyCustom)) {
//       message.error({
//         content: "Value should be a valid number",
//         className: "custom-class",
//         style: {
//           marginTop: "20vh",
//         },
//         duration: 2.5,
//       });
//     }
//     // Additional condition to check for spaces between numbers
//     else if (/\d\s+\d/.test(dummyCustom)) {
//       message.error({
//         content: "Value should not contain spaces between numbers",
//         className: "custom-class",
//         style: {
//           marginTop: "20vh",
//         },
//         duration: 2.5,
//       });
//     }
//     // If all conditions pass, proceed with setting the custom tip value and calling handleDeliveryTip
//     else {
//       setCustomTipValue(true);
//       handleDeliveryTip(props?.Cart?.storeid?.id, props?.Cart?.userid?.id, dummyCustom);
//     }
//   }; 
  

//   const handleCloseClick=()=>{
//     setCustomTipValue(false)
//     setDummyCustom("")
//     handleDeliveryTip(
//       props?.Cart?.storeid?.id,
//       props?.Cart?.userid?.id,
//       0
//     )
//   }

//   return (
//     <div >
//       <Grid container style={{ marginBottom: "10px" }}>
//         <p
//           style={{
//             color: "#000",
//             fontFamily: "Nunito",
//             fontSize: "14px",
//             fontStyle: "normal",
//             fontWeight: 600,
//             lineHeight: "22px",
//           }}
//         >
//           Tip your Delivery partner
//         </p>
//       </Grid>
//       <div className="delivery-tip-container">
//       {deliverytips.map((deliverytip)=>
//      ( deliverytip.id===2&&selectedItem===null?
//         <div 
//       key={deliverytip.id}
//       className="most-tipped-delivery-tip"
//       onClick={() => {
//         setSelectedItem(deliverytip.id);
//         setOpen(false);
//         setRemoveDefault(false);
//         setCustomTipValue(false)
//         handleDeliveryTip(
//           props?.Cart?.storeid?.id,
//           props?.Cart?.userid?.id,
//           deliverytip.tip
//         );
//       }}>
//         <span>
//         {`₹ ${deliverytip.tip}`}
//         </span>
//         <span className="most-tipped-text-box">
//           {'Most Tipped'}
//         </span>
//         </div>
//         :
//       <div 
//       key={deliverytip.id}
//       className={selectedItem===deliverytip.id?"delivery-tip-box-selected":"delivery-tip-box"}
//       onClick={() => {
//         setSelectedItem(deliverytip.id);
//         setOpen(false);
//         setRemoveDefault(false);
//         setCustomTipValue(false)
//         handleDeliveryTip(
//           props?.Cart?.storeid?.id,
//           props?.Cart?.userid?.id,
//           deliverytip.tip
//         );
//       }}>
//         {`₹ ${deliverytip.tip}`}
//         </div>)
//       )}
//       <div 
//        className={selectedItem==="other"?"delivery-tip-box-selected":"delivery-tip-box"}
//         onClick={() => {
//           setSelectedItem("other");
//           handleOpen();
//           setDummyCustom('')
//           if (isOtherSelected) {
//             setOtherSelected(false);
//           } else {
//             setSelectedItem("other");
//             setOpen(!isOpened);
//             setRemoveDefault(false);
//             setOtherSelected(true);
//           }
//         }}>
//         {'Other'}
//         {isOpened && (
//             <Closevector
//               style={{ alignItems:'flex-end',top:0,marginLeft:"1rem"}}
//               onClick={() => {
//                 setOpen(false);
//                 setSelectedItem(null);
//                 setOtherSelected(false);
//               }}
//             />
//           )}
//         </div>
//       </div>
//       {isOpened && (
//         <Grid container style={{ width: "100%",paddingTop:'24px' }}>
//           <Grid item xs={12} style={{ display: "flex" }}>
//             <Input
//               inputRef={ref}
//               disabled={!!customTipValue}
//               style={{ width: "100%",fontSize:'12px' }}
//               inputProps={{
//                 type: "text",
//                 inputMode: "numeric",
//               }}
//               sx={{
//                 ":hover": {
//                   borderBottomColor: "#CDCDCD",
//                   ":before": { borderBottomColor: "#CDCDCD" },

//                   ":after": { borderBottomColor: "#CDCDCD" },
//                 },
//               }}
//               value={dummyCustom}
//               onChange={(event) => setDummyCustom(event.target.value)}
//               endAdornment={
//                 customTipValue?
//                 <InputAdornment position="end">
//                 <button
//                   style={{
//                     color: " #6AA92A",
//                     textAlign: "right",
//                     fontFamily: "Nunito",
//                     fontSize: "12px",
//                     fontStyle: "normal",
//                     fontWeight: 600,
//                     lineHeight: "22px",
//                     cursor: "pointer",
//                     background: "transparent",
//                     border: "none",
//                   }}
//                   onClick={handleCloseClick}
//                 >
//                   Remove
//                 </button>
//               </InputAdornment>
//                 :
//                 <InputAdornment position="end">
//                   <button
//                     style={{
//                       color: " #6AA92A",
//                       textAlign: "right",
//                       fontFamily: "Nunito",
//                       fontSize: "12px",
//                       fontStyle: "normal",
//                       fontWeight: 600,
//                       lineHeight: "22px",
//                       cursor: "pointer",
//                       background: "transparent",
//                       border: "none",
//                     }}
//                     onClick={handleAddClick}
//                   >
//                     Add
//                   </button>
//                 </InputAdornment>
//               }
//             />
//           </Grid>
//         </Grid>
//       )}    
//     </div>
//   );

// });

// export default DeliveryTipsSection;



// import { Grid } from "@mui/material";
// import { ReactComponent as Closevector } from "../../../Assets/Icons/Close vector.svg";
// import Input from "@mui/material/Input";
// import InputAdornment from "@mui/material/InputAdornment";
// import React, { useState, forwardRef, useEffect } from "react";
// import "./index.css";
// import { message } from 'antd';

// const deliverytips = [
//   {
//     id: 1,
//     tip: 20,
//   },
//   {
//     id: 2,
//     tip: 30,
//   },
//   {
//     id: 3,
//     tip: 40,
//   },
// ];

// const DeliveryTipsSection = forwardRef(({ props, isOpened, setOpen }, ref) => {
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [defaultOption, setRemoveDefault] = useState(true);
//   const [isOtherSelected, setOtherSelected] = useState(false);
//   const [customTipValue, setCustomTipValue] = useState(false);
//   const [dummyCustom, setDummyCustom] = useState("");

//   useEffect(() => {
//     if (isOpened && ref.current) {
//       ref.current.scrollIntoView({ behavior: "smooth" });
//       ref.current.focus(); // Set focus to the input field
//     }
//   }, [isOpened, ref]);

//   const handleOpen = () => {
//     setOpen(!isOpened);
//   };

//   const handleDeliveryTip = (storeid, userid, delivertip) => {
//     var parsedDeliveryTip = delivertip === "" ? 0 : Number(delivertip);
//     props.adddeliveryTip(storeid, userid, parsedDeliveryTip);
//   };

//   const handleAddClick = () => {
//     if (dummyCustom === "") {
//       message.error({
//         content: "Please add Delivery Tip",
//         className: "custom-class",
//         style: { marginTop: "20vh" },
//         duration: 2.5,
//       });
//     } else if (parseFloat(dummyCustom) <= 0) {
//       message.error({
//         content: "Value should be greater than zero",
//         className: "custom-class",
//         style: { marginTop: "20vh" },
//         duration: 2.5,
//       });
//     } else if (!/^\d+(\.\d+)?$/.test(dummyCustom)) {
//       message.error({
//         content: "Value should be a valid number",
//         className: "custom-class",
//         style: { marginTop: "20vh" },
//         duration: 2.5,
//       });
//     } else if (/\d\s+\d/.test(dummyCustom)) {
//       message.error({
//         content: "Value should not contain spaces between numbers",
//         className: "custom-class",
//         style: { marginTop: "20vh" },
//         duration: 2.5,
//       });
//     } else {
//       setCustomTipValue(true);
//       handleDeliveryTip(props?.Cart?.storeid?.id, props?.Cart?.userid?.id, dummyCustom);
//     }
//   };

//   const handleCloseClick = () => {
//     setCustomTipValue(false);
//     setDummyCustom("");
//     handleDeliveryTip(props?.Cart?.storeid?.id, props?.Cart?.userid?.id, 0);
//   };

//   return (
//     <div>
//       <Grid container style={{ marginBottom: "10px" }}>
//         <p
//           style={{
//             color: "#000",
//             fontFamily: "Nunito",
//             fontSize: "14px",
//             fontStyle: "normal",
//             fontWeight: 600,
//             lineHeight: "22px",
//           }}
//         >
//           Tip your Delivery partner
//         </p>
//       </Grid>
//       <div className="delivery-tip-container">
//         {deliverytips.map((deliverytip) =>
//           deliverytip.id === 2 && selectedItem === null ? (
//             <div
//               key={deliverytip.id}
//               className="most-tipped-delivery-tip"
//               onClick={() => {
//                 setSelectedItem(deliverytip.id);
//                 setOpen(false);
//                 setRemoveDefault(false);
//                 setCustomTipValue(false);
//                 handleDeliveryTip(props?.Cart?.storeid?.id, props?.Cart?.userid?.id, deliverytip.tip);
//               }}
//             >
//               <span>{`₹ ${deliverytip.tip}`}</span>
//               <span className="most-tipped-text-box">Most Tipped</span>
//             </div>
//           ) : (
//             <div
//               key={deliverytip.id}
//               className={selectedItem === deliverytip.id ? "delivery-tip-box-selected" : "delivery-tip-box"}
//               onClick={() => {
//                 setSelectedItem(deliverytip.id);
//                 setOpen(false);
//                 setRemoveDefault(false);
//                 setCustomTipValue(false);
//                 handleDeliveryTip(props?.Cart?.storeid?.id, props?.Cart?.userid?.id, deliverytip.tip);
//               }}
//             >
//               {`₹ ${deliverytip.tip}`}
//             </div>
//           )
//         )}
//         <div
//           className={selectedItem === "other" ? "delivery-tip-box-selected" : "delivery-tip-box"}
//           onClick={() => {
//             setSelectedItem("other");
//             setOtherSelected(true);
//             handleOpen(); // Open the input field
//             setDummyCustom("");
//           }}
//         >
//           Other
//           {isOpened && (
//             <Closevector
//               style={{ alignItems: "flex-end", top: 0, marginLeft: "1rem" }}
//               onClick={() => {
//                 setOpen(false);
//                 setSelectedItem(null);
//                 setOtherSelected(false);
//               }}
//             />
//           )}
//         </div>
//       </div>
//       {isOpened && (
//         <Grid container style={{ width: "100%", paddingTop: "24px" }}>
//           <Grid item xs={12} style={{ display: "flex" }}>
//             <Input
//               inputRef={ref} // Attach the ref here to focus the field
//               disabled={!!customTipValue}
//               style={{ width: "100%", fontSize: "12px" }}
//               inputProps={{
//                 type: "text",
//                 inputMode: "numeric",
//               }}
//               sx={{
//                 ":hover": {
//                   borderBottomColor: "#CDCDCD",
//                   ":before": { borderBottomColor: "#CDCDCD" },
//                   ":after": { borderBottomColor: "#CDCDCD" },
//                 },
//               }}
//               value={dummyCustom}
//               onChange={(event) => setDummyCustom(event.target.value)}
//               endAdornment={
//                 customTipValue ? (
//                   <InputAdornment position="end">
//                     <button
//                       style={{
//                         color: " #6AA92A",
//                         textAlign: "right",
//                         fontFamily: "Nunito",
//                         fontSize: "12px",
//                         fontStyle: "normal",
//                         fontWeight: 600,
//                         lineHeight: "22px",
//                         cursor: "pointer",
//                         background: "transparent",
//                         border: "none",
//                       }}
//                       onClick={handleCloseClick}
//                     >
//                       Remove
//                     </button>
//                   </InputAdornment>
//                 ) : (
//                   <InputAdornment position="end">
//                     <button
//                       style={{
//                         color: " #6AA92A",
//                         textAlign: "right",
//                         fontFamily: "Nunito",
//                         fontSize: "12px",
//                         fontStyle: "normal",
//                         fontWeight: 600,
//                         lineHeight: "22px",
//                         cursor: "pointer",
//                         background: "transparent",
//                         border: "none",
//                       }}
//                       onClick={handleAddClick}
//                     >
//                       Add
//                     </button>
//                   </InputAdornment>
//                 )
//               }
//             />
//           </Grid>
//         </Grid>
//       )}
//     </div>
//   );
// });

// export default DeliveryTipsSection;




import React, { useState, forwardRef, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { ReactComponent as Closevector } from "../../../Assets/Icons/Close vector.svg";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { message } from 'antd';
import "./index.css";

const deliverytips = [
  {
    id: 1,
    tip: 20,
  },
  {
    id: 2,
    tip: 30,
  },
  {
    id: 3,
    tip: 40,
  },
];

const DeliveryTipsSection = forwardRef(({ props, isOpened, setOpen }, ref) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [defaultOption, setRemoveDefault] = useState(true);
  const [isOtherSelected, setOtherSelected] = useState(false);
  const [customTipValue, setCustomTipValue] = useState(false);
  const [dummyCustom, setDummyCustom] = useState("");

  // Use ref to target the input field
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpened && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
      ref.current.focus();
    }
  }, [isOpened, ref]);

  const handleOpen = () => {
    setOpen(!isOpened);
  };

  const handleDeliveryTip = (storeid, userid, delivertip) => {
    var parsedDeliveryTip = delivertip === "" ? 0 : Number(delivertip);
    props.adddeliveryTip(storeid, userid, parsedDeliveryTip);
  };

  const handleAddClick = () => {
    if (dummyCustom === "") {
      message.error({
        content: "Please add Delivery Tip",
        className: "custom-class",
        style: { marginTop: "20vh" },
        duration: 2.5,
      });
    } else if (parseFloat(dummyCustom) <= 0) {
      message.error({
        content: "Value should be greater than zero",
        className: "custom-class",
        style: { marginTop: "20vh" },
        duration: 2.5,
      });
    } else if (!/^\d+(\.\d+)?$/.test(dummyCustom)) {
      message.error({
        content: "Value should be a valid number",
        className: "custom-class",
        style: { marginTop: "20vh" },
        duration: 2.5,
      });
    } else if (/\d\s+\d/.test(dummyCustom)) {
      message.error({
        content: "Value should not contain spaces between numbers",
        className: "custom-class",
        style: { marginTop: "20vh" },
        duration: 2.5,
      });
    } else {
      setCustomTipValue(true);
      handleDeliveryTip(props?.Cart?.storeid?.id, props?.Cart?.userid?.id, dummyCustom);
    }
  };

  const handleCloseClick = () => {
    setCustomTipValue(false);
    setDummyCustom("");
    handleDeliveryTip(props?.Cart?.storeid?.id, props?.Cart?.userid?.id, 0);
  };

  useEffect(() => {
    // Focus the input when 'Other' is selected
    if (selectedItem === "other" && inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedItem]);

  return (
    <div>
      <Grid container style={{ marginBottom: "10px" }}>
        <p
          style={{
            color: "#000",
            fontFamily: "Nunito",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "22px",
          }}
        >
          Tip your Delivery partner
        </p>
      </Grid>
      <div className="delivery-tip-container">
        {deliverytips.map((deliverytip) =>
          deliverytip.id === 2 && selectedItem === null ? (
            <div
              key={deliverytip.id}
              className="most-tipped-delivery-tip"
              onClick={() => {
                setSelectedItem(deliverytip.id);
                setOpen(false);
                setRemoveDefault(false);
                setCustomTipValue(false);
                handleDeliveryTip(props?.Cart?.storeid?.id, props?.Cart?.userid?.id, deliverytip.tip);
              }}
            >
              <span>{`₹ ${deliverytip.tip}`}</span>
              <span className="most-tipped-text-box">{"Most Tipped"}</span>
            </div>
          ) : (
            <div
              key={deliverytip.id}
              className={selectedItem === deliverytip.id ? "delivery-tip-box-selected" : "delivery-tip-box"}
              onClick={() => {
                setSelectedItem(deliverytip.id);
                setOpen(false);
                setRemoveDefault(false);
                setCustomTipValue(false);
                handleDeliveryTip(props?.Cart?.storeid?.id, props?.Cart?.userid?.id, deliverytip.tip);
              }}
            >
              {`₹ ${deliverytip.tip}`}
            </div>
          )
        )}
        <div
          className={selectedItem === "other" ? "delivery-tip-box-selected" : "delivery-tip-box"}
          onClick={() => {
            setSelectedItem("other");
            handleOpen();
            setDummyCustom("");
            if (isOtherSelected) {
              setOtherSelected(false);
            } else {
              setSelectedItem("other");
              setOpen(!isOpened);
              setRemoveDefault(false);
              setOtherSelected(true);
            }
          }}
        >
          {"Other"}
          {isOpened && (
            <Closevector
              style={{ alignItems: "flex-end", top: 0, marginLeft: "1rem" }}
              onClick={() => {
                setOpen(false);
                setSelectedItem(null);
                setOtherSelected(false);
              }}
            />
          )}
        </div>
      </div>
      {isOpened && (
        <Grid container style={{ width: "100%", paddingTop: "24px" }}>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Input
              inputRef={inputRef} // Reference to the input field
              disabled={!!customTipValue}
              style={{ width: "100%", fontSize: "12px" }}
              inputProps={{
                type: "text",
                inputMode: "numeric",
              }}
              sx={{
                ":hover": {
                  borderBottomColor: "#CDCDCD",
                  ":before": { borderBottomColor: "#CDCDCD" },
                  ":after": { borderBottomColor: "#CDCDCD" },
                },
              }}
              value={dummyCustom}
              onChange={(event) => setDummyCustom(event.target.value)}
              endAdornment={
                customTipValue ? (
                  <InputAdornment position="end">
                    <button
                      style={{
                        color: " #6AA92A",
                        textAlign: "right",
                        fontFamily: "Nunito",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "22px",
                        cursor: "pointer",
                        background: "transparent",
                        border: "none",
                      }}
                      onClick={handleCloseClick}
                    >
                      Remove
                    </button>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <button
                      style={{
                        color: " #6AA92A",
                        textAlign: "right",
                        fontFamily: "Nunito",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "22px",
                        cursor: "pointer",
                        background: "transparent",
                        border: "none",
                      }}
                      onClick={handleAddClick}
                    >
                      Add
                    </button>
                  </InputAdornment>
                )
              }
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
});

export default DeliveryTipsSection;
