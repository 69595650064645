import {
    Box
  } from "@mui/material";
  import React from "react";
  import StoreCart from '../../Components/StoreCart';
  import { useMediaQuery } from 'react-responsive';
  
  const Rightbar = () => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
      <>
      {isSmallScreen?
      null
      :
      <Box flex={2}  sx={{ display: { xs: "none", sm: "block" },paddingTop:'30px' }} position="sticky">
        
      <Box 
      style={{width:'100%',height:`calc(${100}vh-${60}px)`}}
      width={400}  flex={1}>
        <StoreCart/>
      </Box>
    </Box>
      }
   
      </>
    );
  };
  
  export default Rightbar;