import React from 'react';
import { useStyles } from './style';
import errorproduct from '../../Assets/Images/errorproduct.svg';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};
const Product = (props) => {
    const classes = useStyles();
    const [sliderRef, setSliderRef] = React.useState(null)
    return (
        <div>
            <div style={{ width: '100%', display: 'flex' }}>
                <span style={{
                    width: '100%', paddingBottom: '15px', fontSize: '13px', fontWeight: 1000, float: 'left', textAlign: 'left',
                    alignItems: 'center'
                }}>
                    {'Items'}
                </span>
                <span style={{ width: '100%', display: 'flex', float: 'right', textAlign: 'right', paddingBottom: '15px', justifyContent: 'right' }}>
                    <button className={classes.arrowButton} onClick={sliderRef?.slickPrev}>
                        <KeyboardArrowLeftIcon />
                    </button>
                    <button className={classes.arrowButton} onClick={sliderRef?.slickNext}>
                        <KeyboardArrowRightIcon />
                    </button>
                </span>
            </div>
            <Slider ref={setSliderRef} {...settings}>
                {props.OrderDetails.products.map(({ productid, quantity ,shopAssistantQuantity}) =>
                    <div style={{ paddingBottom: '15px', width: '100%' }}>
                        <div className={classes.cartProductBox}>
                            <div className={classes.cartProductDetails}>
                                <span style={{
                                    float: 'left', paddingLeft: '20px', paddingRight: '20px',
                                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    {productid.image && (productid.image.primary === null ||
                                        productid.image.primary === 'null' ||
                                        productid.image.primary === undefined ||
                                        productid.image.primary === 'undefined'
                                    ) ?
                                        <img src={errorproduct} className={classes.cartImage} alt="product" />
                                        :
                                        <img src={productid.image && productid.image.primary} className={classes.cartImage} alt="product" />
                                    }
                                </span>
                                <span style={{
                                    float: 'right', fontWeight: 700,
                                    fontSize: '14px',
                                    lineHeight: '19px',
                                    fontFamily: 'Nunito',
                                    color: ' #061303', display: "inline-block",
                                    flexDirection: 'column',
                                    width: '100%', overflow: 'hidden',
                                    textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}>
                                    {productid.productname && productid.productname}
                                    <span>
                                        <div className={classes.cartProductDetails} style={{ paddingTop: '20px', width: '100%' }}>
                                            <span style={{
                                                float: 'left',
                                                fontWeight: 700,
                                                fontSize: '18px',
                                                lineHeight: '22px',
                                                fontFamily: 'Nunito',
                                                width: '100%'
                                            }}>
                                                <span style={{
                                                    fontWeight: 700,
                                                    fontSize: '18px',
                                                    lineHeight: '22px',
                                                    fontFamily: 'Nunito',
                                                    color: '#69A85C',
                                                    width: '100%'
                                                }}>
                                                    ₹
                                                   { parseFloat(
                                                            productid?.markedupProductPrice
                                                        ).toFixed(2)}
                                                </span>
                                                {productid.quantity && productid?.uom &&
                                                    <span style={{
                                                        fontWeight: 700,
                                                        fontSize: '12px',
                                                        lineHeight: '22px',
                                                        fontFamily: 'Nunito',
                                                        color: 'rgba(6, 19, 3, 0.4)',
                                                        width: '100%'
                                                    }}>
                                                        {' per'}{productid.quantity && productid.quantity}{productid.uom && productid.uom}
                                                    </span>
                                                }
                                            </span>
                                            { (shopAssistantQuantity!==null&&shopAssistantQuantity===0)?
                                                  <span style={{color:'red'}}>
                                                    {"Item not Found"}
                                                </span>
                                                  :
                                            <span 
                                            style={{
                                                float: 'right',
                                                fontWeight: 700,
                                                fontSize: '14px',
                                                lineHeight: '19px',
                                                fontFamily: 'Nunito',
                                                color: '#061303',
                                                width: '100%',
                                                display: "flex",
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                gap:"7px"
                                            }}>
                                                x
                                                <span 
                                                style={{
                                                    float: 'right',
                                                    marginLeft: '2px',
                                                    fontWeight: 700,
                                                    fontSize: '14px',
                                                    lineHeight: '16px',
                                                    fontFamily: 'Nunito',
                                                    color: ' #000000',
                                                    width: '26px',
                                                    height: '26px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    alignItems: 'center',
                                                    background: '#EAF6E1',
                                                    border: '0.5px solid rgba(0, 0, 0, 0.03)',
                                                    borderRadius: '14px'
                                                }}>
                                                   <span 
                                                    style={{textDecoration:`${(shopAssistantQuantity!==null&&shopAssistantQuantity!==quantity)?'line-through':'none'}`,color:'rgba(0,0,0,0.5)'}}>
                                                        {quantity}
                                                        </span>
                                                        &nbsp;{(shopAssistantQuantity!==null&&shopAssistantQuantity!==quantity)&&shopAssistantQuantity}
                                                </span>
                                            </span>
                                            }
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </Slider>
        </div>
    )
}

export default Product