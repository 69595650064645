// export const MAIN_HEADING = "Terms and Conditions";
// export const DESCRIPTION = `Thank you for visiting our website, NearShopz.in ("Website") or downloading our mobile application ("App"). While the NearShopz partners finish your personal tasks, please spare the time to read the following terms and conditions. You can learn about our services from the Website and use our services through the mobile application or App (the Website and the App referred to herein as "NearShopz Platform"). 

// For the purposes of these Terms of Use, the term “NearShopz” or “Us” or “We” or “Our” or “Company” refers to NearShopz Technologies Private Limited. The term “You” or “Your” refers to the user or visitor of the Website and/or App. When You use our services, you will be subject to the terms, guidelines and policies applicable to such service and as set forth in these Terms of Use. As long as you comply with these Terms of Use, we grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use our Platforms and services. `;
// export const termsandconditions = [
//     {
//         index:1,
//         heading:"Acceptance of Terms",
//         description:`These Terms of Use sets forth legally binding terms for Your use of our Platforms and services. By using the Platforms, You agree to be bound by these Terms of Use, whether You are a “Visitor” (which means that you simply browse our Platforms) or you are a “Subscriber” (which means you have registered with NearShopz as a seller). If You do not accept these terms, you should leave the Website and/or App and discontinue the use of the service immediately. 
//         We may modify these Terms of Use from time to time, and such modification shall be effective upon its posting on our Platforms. You agree to be bound by any modification to these terms when You use the Platforms after any such modification is posted; it is therefore important that You review these Terms regularly.  `,
//     },
//     {
//         index:2,
//         heading:"General Registration Requirements ",
//         description:`In consideration of your use of our Platforms, you represent that You are of legal age to form a binding contract and are not a person barred from receiving services under any law in force in India or other applicable jurisdiction. 

//         You also agree to: 
        
//         (a) provide true, accurate, current and complete information about yourself while registering on our Platforms to avail the Services; and 
        
//         (b) maintain and promptly update Your registration data to keep it true, accurate, current and complete. 
        
//         If You provide any information that is untrue, inaccurate, not current or incomplete, or we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, We reserve the right to suspend or terminate Your account and refuse any and all current or future use of our Platforms (or any portion thereof) at any time. 
        
//         There is limited content available on our Platforms. All of such content is provided to you “AS IS” for Your information and personal use only and may not be downloaded, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of NearShopz. NearShopz reserves all rights not expressly granted in and to the Website/App and the Content. 
        
//         You may access our Platforms as available for Your information and personal use. 
        
//         You will be required to enter a valid phone number while registering on our Platforms as a subscriber. By registering Your phone number with us, you consent to be contacted by us via phone calls, SMS notifications or instant messages, in case of any subscription/service updates. If we do so, each communication we send You will contain instructions permitting you to "opt-out" of receiving future communications. In addition, if at any time You wish not to receive any future communications or You wish to have Your name deleted from our mailing lists, please contact us as indicated below. If You are registered with the DND National registry, you may not receive any promotional messages from Us. `,
//     },
//     {
//         index:3,
//         heading:"Subscriber Account and Security",
//         description:`If You register with NearShopz, you may be required to complete a verification process as part of setting up Your account. Once set up, you are responsible for maintaining the confidentiality of Your account information and are fully responsible for all activities that occur through Your account. Should there be instances of any unauthorized use of Your account or any other breach of security, please notify Us to stop processing requests from Your account, until further instructions. You agree, undertake and confirm that your use of the Platform shall be strictly governed by the following binding principles: 

//         1.  You shall not host, display, upload, modify, publish, transmit, update or share any information or image which: 
        
//         (a) belongs to another person and over which you have no right; 
        
//         (b) is grossly harmful, harassing, blasphemous, defamatory, bigotry, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating to or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever, or unlawfully threatening or harassing, including but not limited to ‘indecent representation of women’ within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986; 
        
//         (c) is false, inaccurate  or misleading in any way; 
        
//         (d) is patently offensive to the online community, such as sexually explicit content or content that promotes obscenity, pedophilia, racism, bigotry, hatred, or physical harm of any kind against any group or individual; 
        
//         (e) harasses or advocates harassment of another person; 
        
//         (f) involves the transmission of ‘junk mail’, ‘chain letters’, unsolicited mass mailing, or ‘spamming’; including spamming by ways of unrelated feedbacks on surveys 
        
//         (g) promotes illegal activity or conduct that is abusive, threatening, obscene, defamatory, or libelous; 
        
//         (h) infringes upon or violates any third party's rights [including but not limited to intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address, or phone number) or rights of publicity]; 
        
//         (i) promotes an illegal or unauthorized copy of another person's copyrighted work (see “Copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material) such as providing pirated computer programs or links, information to circumvent manufacturer-installed copy-protect devices, or pirated music or links to pirated music files; 
        
//         (j) contains restricted or password-only access pages, hidden pages or images or URLs leading to any other pages (those not linked to or from another accessible page); 
        
//         (k) provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;  
        
//         (l) provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, providing or creating computer viruses; 
        
//         (m) contains unauthorized videos, photographs or images of another person (whether a minor or an adult); 
        
//         (n) tries to gain unauthorized access or exceeds the scope of authorized access to the Platform, profiles, blogs, communities, account information, bulletins, friend requests, or other areas of the Platform, or solicits passwords or personal identifying information for commercial or unlawful purposes from other users on the Platform; 
        
//         (o) engages in commercial activities and/or sales such as contests, sweepstakes, barter, advertising, pyramid schemes, or the buying or selling of ‘virtual’ items related to the Platform without our prior written consent. 
        
//         Throughout the ToU, Nearshopz’s prior written consent means a communication coming from Nearshopz’s Legal Department in response to your request and specifically addressing the activities or conduct for which you have sought authorization; 
        
//         (p) solicits gambling or engages in any gambling activity which we, at our sole discretion, believe is or could be construed as being illegal; 
        
//         (q) interferes with another’s use and enjoyment of the Platform; 
        
//         (r) refers to any website/URL which, at our sole discretion, contains material that is inappropriate for the Platform or any other website and content that is prohibited or violates the letter and spirit of ToU; 
        
//         (s) harms minors in any way; 
        
//         (t) infringes any patent, trademark, copyright, proprietary rights, third-party’s trade secrets, rights of publicity, or privacy, is fraudulent, or involves the sale of counterfeit or stolen items; 
        
//         (u) violates any law for the time being in force; 
        
//         (v) deceives or misleads the addressee/ users about the origin of messages or communicates any information which is grossly offensive or menacing in nature; 
        
//         (w) impersonates another person; 
        
//         (x) contains software viruses or any other computer codes, files, or programs designed to interrupt, destroy, or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs, or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept, or expropriate any system, data, or personal information; 
        
//         (y) threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any offence or prevents investigation of any offence or is insulting any other nation; offends the religious and national sentiments of the nation as included in ‘Zero Tolerance to Profanity, Hurting National and Religious Sentiments’ policy. 
        
//           
        
//         (z) shall, directly or indirectly, offer or attempt to offer trade or attempt to trade in any item which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force; 
        
//         (aa) shall create liability for us or cause us to lose (in whole or part) the services of our Internet Service Provider (“ISPs”) or other suppliers. `,
//     },
//     {
//         index:4,
//         heading:"Services and Payment",
//         description:`NearShopz’s services constitute a technology platform that connects You to a third party NearShopz partner/ Merchant to schedule and complete your everyday requirements and errands ("Services"). NearShopz does not run your tasks or errands by itself nor does it actively supervise the completion of Your tasks. 

//         When You use the services of a service provider/NearShopz partner/merchant, you will incur a charge. NearShopz will facilitate your payment for the services and the service provider/partner/merchant fee through integrated payment platforms. To the extent that NearShopz receives charges on behalf of the service provider/partner/ merchant, it acts as an agent of the service provider/partner/merchant and all payments made by You for services rendered are deemed to be payments made to the service provider/partner/merchant. Payments are inclusive of taxes. `,
//     },
//     {
//         index:5,
//         heading:"User Information",
//         description:`Our Services depends on the information You provide to us. You are solely responsible for and in control of the Information You provide to us. 

//         If You use the Website or the App, You agree that information about Your use of the NearShopz Platforms through Your mobile telecommunication device may be communicated to us, and we may obtain information about Your mobile carrier, Your mobile device, or Your physical location. In addition, use of our Platforms through a mobile telecommunication device may cause data to be displayed on and through Your mobile device. By accessing our Platforms using a mobile telecommunication device, You represent that to the extent You import any of Your NearShopz data to Your mobile telecommunication device that You have authority to share the transferred data with Your mobile carrier or other access provider. In the event You change or deactivate Your mobile account, you must promptly update Your NearShopz account information to ensure that Your messages are not sent to the person that acquires Your old number and failure to do so is Your responsibility. You acknowledge that You are responsible for all charges and necessary permissions related to accessing our Platforms through Your mobile access provider. Therefore, you should check with Your provider to find out if our Platforms are available for Your specific mobile devices. 
        
//         NearShopz reserves the right to collect user data including name, contact information and other details to facilitate the Services or use of its Platform to avail Services. All information collected from You are on a bona fide basis. Misuse and misrepresentation of identity or contact details will lead to automated termination of services or the use of the platform without the obligation of a prior notice to You. 
        
//         Compilation of user accounts and user accounts bearing contact number and e-mail addresses are owned by NearShopz. 
        
//         In the case where the system is unable to establish unique identity of the user against a valid mobile number or e-mail address, the account shall be indefinitely suspended. NearShopz reserves the full discretion to suspend a user's account in the above event and does not have the liability to share any account information whatsoever. 
        
//         We may disclose to third parties certain data related to You, without providing Your personal details such as name, residential and e-mail address, mobile number, unless: (1) You expressly permit us to do so, or any other person You may specifically designate to disclose such information or (2) We are required to disclose such information by any applicable law or legal process. `,
//     },
//     {
//         index:6,
//         heading:"Prohibited Conduct ",
//         description:`You agree not to use the Platforms inappropriately, or otherwise transmit information, data, text, software, music, sound, photographs, graphics, video, messages, tags, or other materials ("Content" means the transmission of information, data, text, software, music, sound, photographs, graphics, video, messages, tags or other materials generated by a user) that: 

//         is patently offensive and/or promotes racism, bigotry, hatred or physical harm of any kind against any group or individual 
        
//         harasses or advocates harassment of another person 
        
//         exploits people in a sexual or violent manner 
        
//         contains nudity, violence, pornography, sexually explicit material or offensive subject matter 
        
//         provides any telephone numbers, street addresses, last names or email addresses of anyone other than your own 
        
//         promotes information that you know is false or misleading or promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous 
        
//         violates any intellectual property or other proprietary right of any third party, including Content that promotes an illegal or unauthorized copy of another person’s copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files 
        
//         involves the transmission of “junk mail,” “chain letters,” or unsolicited mass mailing, instant messaging, “spimming,” or “spamming” 
        
//         contains restricted or password only access pages or hidden pages or images (those not linked to or from another accessible page) 
        
//         furthers or promotes any criminal activity or enterprise or provides instructional information about illegal activities including, but not limited to making or buying illegal weapons, violating someone’s privacy, or providing or creating computer viruses 
        
//         solicits passwords or personal identifying information from other Subscribers 
        
//         involves commercial activities and/or sales without NearShopz’s prior written consent, such as contests, sweepstakes, barter, advertising, and/or pyramid schemes 
        
//         includes a photograph of another person that you have posted without that person’s consent or otherwise constitutes an invasion of an individual’s privacy or infringement of publicity rights 
        
//         denigrates, ridicules, or demeans another person; or contains a virus or other harmful component.
//         You agree not to use or launch any automated system, including without limitation, “robots,” “spiders,” or “offline readers,” that accesses our Platforms. `,
//     },
//     {
//         index:7,
//         heading:"Termination / Suspension of Account or Services",
//         description:`You agree that NearShopz may at any time and for any reason, terminate your access to our Platforms, or restrict or suspend your access to all or any part of the Website or the App at any time, for any or no reason, with or without prior notice, and without liability. We may also terminate the rendering of any service without prior notice or liability. `,
//     },
//     {
//         index:8,
//         heading:" Preservation/Disclosure",
//         description:`You acknowledge, consent and agree that NearShopz may access, preserve and disclose Your account information if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary to: 

//         comply with legal process nationally or internationally 
        
//         enforce these Terms 
        
//         respond to your requests for service or complete your tasks 
        
//         protect the rights, property or personal safety of NearShopz, its subscribers and the public, or 
        
//         pursuant to the terms of the Privacy Policy. `,
//     },
//     {
//         index:9,
//         heading:"Security Components ",
//         description:`You understand that our Platforms and software embodied within the Platforms may include security components that permit digital materials to be protected, and that use of these materials is subject to usage rules set by NearShopz and/or content providers who provide content to NearShopz. You may not attempt to override, disable, circumvent or otherwise interfere with any such security components and usage rules embedded in our Platforms. `,
//     },
//     {
//         index:10,
//         heading:"Proprietary Rights ",
//         description:`All materials on our Platforms, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, artwork, software and other elements (collectively, “Material”) are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by NearShopz. You acknowledge and agree that all content on our Platforms is made available for limited, non-commercial, personal use only. Except as specifically provided herein or elsewhere in our Platforms, no Material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without NearShopz’s prior express written permission. You may not add, delete, distort, or otherwise modify the Material. Any unauthorized attempt to modify any Material, to defeat or circumvent any security features, or to utilize our Platforms or any part of the Material for any purpose other than its intended purposes is strictly prohibited. `,
//     },
//     {
//         index:11,
//         heading:"NearShopz and Links to Third Parties ",
//         description:`Our Platforms may contain links to other sites owned by third parties (i.e. advertisers, affiliate partners, strategic partners, or others). We are not responsible for examining or evaluating, and we do not warrant the products or offerings of, any of these businesses or individuals, or the accuracy of the content of their Websites. NearShopz does not assume any responsibility or liability for the actions, product, and content of any such Third-Party websites. Before You use any Third-Party websites, you should review the applicable terms of use and policies for such Third-Party websites. If you decide to access any such linked third party website, you do so at your own risk. 

//         We may introduce you to independent third parties to complete your tasks. Where we refer independent third-party service providers, you shall pay the fees directly to the service provider. NearShopz’s disclaims all responsibility and liability as regards the services, conduct or actions of such third party. `,
//     },
//     {
//         index:12,
//         heading:"General Terms and Conditions ",
//         description:`You agree and accept that the use of the Services provided by NearShopz is at Your sole risk and further acknowledge that the Company disclaims all representations and warranties of any kind, whether express or implied. 

//         You shall ensure that You will not indulge in any of the following activities while using the service asking the service provider to break any Police and/or government rules for any purpose (especially getting the service faster). The service provider has the right to refuse such a request by You. 
        
//         You agree and acknowledge that the use of the Services offered by NearShopz is at Your sole risk and that NearShopz disclaims all representations and warranties of any kind, whether express or implied as to condition, suitability, quality, merchantability and fitness for any purposes are excluded to the fullest extent permitted by law. 
        
//         Without prejudice to the above, NearShopz makes no representation or warranty that the Services will meet the customer’s requirements. In all circumstances NearShopz’s liability is limited to the service fee paid by You for the transaction in question. 
        
//         NearShopz will not be liable for any damages of any kind arising from the use of the Service offered by the Company, including, but not limited to direct, indirect, incidental, punitive, and consequential damages. 
        
//         NearShopz shall be entitled at any time without giving any reason to terminate the request for services from You. 
        
//         If You have any complaint in respect of the Services, you must inform NearShopz of the same in writing within 24 hours of using the Services of NearShopz. 
        
//         NearShopz shall not be liable for any conduct or behaviour or actions of third-party service providers. However, NearShopz encourages you to notify it of any complaints that you may have against the service provider that you may have hired using ____'s services. 
        
//         Partners will only run Your errands as per your instructions. In the interest of user privacy, partners do not check any bags or packages for any contents. You are solely responsible for the contents of the packages. 
        
//         Scheduling and rescheduling a task depends on the availability of NearShopz partners at the time of scheduling and at the time of running the task. Should you choose to reschedule a task at a later point in time it will be considered as a new task. 
        
//         If a partner/ service provider /merchant cannot fulfil any of your tasks, you will be notified as soon as is reasonably possible. 
        
//         Under no circumstances can we run any tasks which are immoral or unlawful in nature. NearShopz reserves the right to refuse to perform any tasks on the grounds of such tasks being immoral/unlawful/banned. NearShopz may also refuse to perform any task on the grounds that such task is prohibited under any contract to which we are party. 
        
//         To help the service provider to serve you better, please provide as much information as possible regarding the task. 
        
//         The Company is hereby authorized to use the location-based information provided by any of the telecommunication companies when You use the mobile phone to request a service. The location-based information will be used only to facilitate and improve service offerings to You(including sharing it with our affliates). 
        
//         In the event your task requires the purchase or sale of any product or service, such product or service is provided to you from a third-party merchant. Any grievance with such product or service should be raised with such merchant. You understand and acknowledge that except for the Services, NearShopz by itself does not sell or provide any such product or service. NearShopz is not responsible for the quality, merchantability or fitness of such product or service. `,
//     },
//     {
//         index:13,
//         heading:" Compliance with Applicable Law ",
//         description:`You agree that You shall not use any NearShopz Platform in order to host, display, upload, modify, publish, transmit, update, distribute, share, store or destroy material, including without limitation, NearShopz Content: 

//         (a) in violation of any applicable law or regulation, 
        
//         (b) in a manner that will infringe the copyright, trademark, trade secret or other intellectual property or proprietary rights of others or violate the privacy, publicity or other personal rights of others, 
        
//         (c) that belongs to another person and to which the user does not have any right to, 
        
//         (d) that is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another’s privacy, threatening, abusive or hateful or racially, ethnically objectionable, disparaging, relating encouraging money laundering or gambling or otherwise unlawful in any manner whatsoever, 
        
//         (e) harm minors in any way, 
        
//         (f) deceives or misleads the addressee about the origin of such message or communicates any information which is grossly offensive or menacing in nature, 
        
//         (g) impersonate another person or entity, 
        
//         (h) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of NearShopz’s computer systems or site or NearShopz’s users, customer’s computer systems or site, 
        
//         (i) threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states or of public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or insulting any other nation. `,
//     },
//     {
//         index:14,
//         heading:"Intellectual Property Rights",
//         description:`The Services provided by NearShopz is premised on a proprietary software developed and made available exclusively by NearShopz. NearShopz is the sole owner of the App, Website, and all software created to provide You with the Services. ____ provides You with a single limited license to download, use and access the App/Website on Your mobile telephone devices for the limited purpose of using the Services. The license is specifically personal, non-transferable, and non-exclusive. All content on the App or the Website, which is including, but not limited to, designs, text, graphics, images, the colorproducts, video, information, logos, button icons, software, audio files and any other content ("Content") are the exclusive and sole property of NearShopz. You may not copy, reproduce or use such Content without due attribution of ownership to NearShopz. All icons and logos are trademarks of and proprietary to NearShopz. The unauthorized copying, modification, use or publication of these marks is strictly prohibited. 

//         All Content is the exclusive copyright of NearShopz or its licensors, except the Third-Party Content and link to third party website. Systematic retrieval of NearShopz content to create or compile, directly or indirectly, a collection, compilation, database or directory (whether through robots, spiders, automatic devices or manual processes) without written permission from NearShopz is prohibited. In addition, use of the Content for any purpose not expressly permitted by NearShopz in these Terms is prohibited and may invite legal action. `,
//     },
//     {
//         index:15,
//         heading:"Acceptance of Terms",
//         description:`These Terms shall remain in full force and effect for so long as it is posted on any of our Platforms. You may terminate your use of the services at any time. 

//         NearShopz reserves the right to terminate Your account or Your access to the Platforms immediately, with or without notice to You, and without liability to You, if NearShopz believes that You have breached any of these Terms, furnished NearShopz with false or misleading information, or interfered with use of the Platform by others. `,
//     },
//     {
//         index:16,
//         heading:"Disclaimer of Warranties and liability  ",
//         description:`All the material and products on our Platforms, (including but not limited to software) and services, included on or otherwise made available to you through our Platforms are provided on "AS IS" and "AS AVAILABLE" basis, without any representation or warranties, express or implied, except otherwise specified in writing. Without prejudice to the forgoing paragraph, NearShopz does not warrant that the Website or App and services provided pursuant thereto will be constantly available, or available at all. 

//         NearShopz will not be liable to you in any way or in relation to the Contents of, or use of, or otherwise in connection with its Platforms. NearShopz does not warrant that the Website; information, Content, materials, product (including software) or services included on or otherwise made available to you through its Platforms; their servers; or electronic communication sent from us are free of viruses or other harmful components. 
        
//         SUBJECT TO APPLICABLE LAWS, IN NO EVENT WILL NearShopz OR ITS EMPLOYEES OR ITS AGENTS AGGREGATE LIABILITY ARISING FROM OR RELATED TO THE SERVICES SHALL NOT EXCEED THE PAYMENTS ACTUALLY RECEIVED AND RETAINED BY NearShopz FROM YOU AS SERVICE FEES FOR THE SERVICES RENDERED FOR ANY AND ALL CAUSES OF ACTION BROUGHT BY YOU OR YOUR AGENTS. `,
//     },
//     {
//         index:17,
//         heading:"Exclusions and Limitations",
//         description:`Those who access or use our Platforms from other jurisdictions do so at their own volition and are responsible for compliance with the local law. `,
//     },
//     {
//         index:18,
//         heading:" Indemnity",
//         description:`You agree to defend, indemnify and hold harmless NearShopz, its subsidiaries, affiliates, subcontractors, officers, directors, employees, consultants, representatives and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorneys’ fees and costs) arising from: 

//         (a) Your use of and access to our Platforms in a manner except as permitted herein; 
        
//         (b) Your violation of any of these Terms or any applicable law; 
        
//         (c) Your violation of any third party right, including without limitation any copyright, property, or privacy right; or 
        
//         This indemnification obligation will survive the termination of Your account or use of services and the App. `,
//     },
//     {
//         index:19,
//         heading:"Additional Terms",
//         description:`NearShopz may also require You to follow additional rules, guidelines or other conditions in order to participate in certain promotions or activities available through our Platforms. These additional terms are part of these Terms, and You agree to comply with them when You participate in those promotions, or otherwise engage in activities governed by such additional terms. `,
//     },
//     {
//         index:20,
//         heading:"Modification and Discontinuation",
//         description:`NearShopz reserve the right at any time to modify, edit, delete, suspend or discontinue, temporarily or permanently the Service or any of our Platforms (or any portion thereof) with or without notice. You agree that we will not be liable to you or to any third party for any such modification, editing, deletion, suspension or discontinuance of our Platforms. 

//         You may discontinue using the services at any time. You may also delete your account by writing to us at info@NearShopz.in. Deletion of your account will be effective once all outstanding credits to your account have been cleared. Please note, upon deletion of your account we may still retain some information and record of transactions as required by any law, contract or policy applicable to Us. `,
//     },
//     {
//         index:21,
//         heading:"Assignment",
//         description:`These Terms and any rights and licenses granted hereunder, may not be transferred or assigned by You, but may be assigned by NearShopz without restriction. `,
//     },
//     {
//         index:22,
//         heading:"Integration Clause",
//         description:`These Terms together with the Privacy Policy and any other legal notices published by NearShopz on its Platforms, shall constitute the entire agreement between you and NearShopz concerning its Platforms and governs Your use of our Platforms and Service, superseding any prior agreements between You and NearShopz with respect to our Platforms and Service. `,
//     },
//     {
//         index:23,
//         heading:"Waiver and Severability of Terms ",
//         description:`The failure of NearShopz to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.`,
//     },
//     {
//         index:24,
//         heading:"Governing Law and Venue",
//         description:`These Terms are governed by the laws of India. Any matters arising under these terms shall be subject to the exclusive jurisdiction of courts located in Kerala, India. `,
//     },
//     {
//         index:25,
//         heading:"Grievance Officer",
//         description:`In case of any grievance arising from the use of the Website or the App, please write to the Grievance Officer at info@nearshopz.com `,
//     },
//     {
//         index:26,
//         heading:"RETURN/REFUND POLICIES",
//         description:`  
//        (i) No returns will be offered on Items that have been delivered without any damages or defects.
            
//         (ii) If the product is wrongfully delivered or has a genuine quality/manufacturing defect, then we are open to extending full refund or re-fulfilling your order against the return of the product subject to the fact of such defect being established. 
            
//        (iii) No refunds would be given if the customer has provided wrong or incomplete shipping address
            
            
//        (iv) Refunds are processed immediately, but it may take 5-10 business days to see the funds in your bank or credit card account, depending on your bank. You won't see refunds in your Instacart account.
     
//        (v) The return will be subject to the store’s return policies, which vary by retailer.
//              `,
//     },
//     {
//         index:27,
//         heading:"CANCELLATION POLICIES",
//         description:`  
//        (i) If you have to cancel an order, please do so within 24 hours of placing the order. It is NOT possible to cancel part of your order. We recommend you cancel the entire order and re-purchase required quantity to simplify the transaction.
	
//        (ii) If you cancel your order before your product has shipped, we will refund the entire amount subject to deduction of charges if any and whereas you cancel your order after the shipment but before receipt of the item, the same will be liable for deduction of the two-way shipping costs.
            
//        (iii) All cancellations are subject to a cancellation charge/bank transaction charge of 2.5% of your total order value. 
            
//       (iv)  There are circumstances when the company might have to cancel a particular order. `,
//     },
//     ];

import React from 'react'

function termsandconditions() {
    
  return (
    <div>
         <div>
  <h1>Acceptance of Terms</h1>
  <p>
    1.1. By accessing or utilizing the NearShopz platform, inclusive of its website and mobile applications, you hereby agree to adhere to the following Terms and Conditions. Should you disagree with these terms, kindly refrain from using our services.
  </p>
  <p>
    1.2. These Terms and Conditions establish a legally binding agreement between yourself ("User," "you," "your") and NearShopz (“NearShopz”), operated by 4Labs Technologies Pvt, Ltd. By utilizing our platform, you assert that you are of legal age to enter such a contract.
  </p>
  
  <h2>Services</h2>
  <p>
    2.1. NearShopz provides a hyperlocal delivery platform facilitating connections between users and delivery providers. The range of services offered may encompass order placement, payment processing, and delivery of goods.
  </p>
  <p>
    2.2. NearShopz reserves the prerogative to amend, suspend, or discontinue services, either wholly or partially, without prior notice. We shall not be held liable for any resultant losses or inconveniences.
  </p>
  
  <h2>User Accounts</h2>
  <p>
    3.1. To access specific features and services on NearShopz, users may be mandated to create a user account. It is the user's responsibility to maintain the confidentiality of their account information.
  </p>
  <p>
    3.2. Users are obliged to furnish accurate and current information during the registration process. NearShopz bears no responsibility for inaccuracies in the information provided.
  </p>
  
  <h2>User Conduct</h2>
  <p>
    4.1. Users undertake to utilize the NearShopz platform and services in accordance with all pertinent laws and regulations.
  </p>
  <p>
    4.2. Users must refrain from conduct deemed offensive, harmful, or infringing on others' rights, including harassment, fraud, and unauthorized use of intellectual property.
  </p>
  <p>
    4.3. Inappropriate behavior and fraudulent activities may result in the suspension or termination of user accounts.
  </p>
  
  <h2>Orders and Deliveries</h2>
  <p>
    5.1. Users can place orders through the NearShopz platform for goods and services offered by merchants.
  </p>
  <p>
    5.2. By placing an order, users affirm their commitment to provide accurate delivery information, comprising the delivery address and contact particulars.
  </p>
  <p>
    5.3. Users acknowledge that delivery times stipulated on the platform are approximate and actual delivery times may deviate based on various factors such as traffic and weather conditions.
  </p>
  <p>
    5.4. NearShopz and its delivery partners will exert reasonable efforts to fulfill orders within the estimated delivery time. However, NearShopz shall not be held accountable for delays beyond its control.
  </p>
  <p>
    5.5. In instances of non-delivery attributable to user-related factors (e.g., incorrect address provided), users may incur additional fees for redelivery, or the order may be canceled, at NearShopz's discretion.
  </p>
  
  <h2>Payments</h2>
  <p>
    6.1. In case of payment disputes, encompassing unauthorized transactions, billing errors, or charge discrepancies, users are encouraged to promptly contact NearShopz customer support for resolution.
  </p>
  <p>
    6.2. NearShopz will promptly investigate payment disputes upon receipt of a complaint from the user. Users may be required to furnish relevant information and documentation to facilitate the investigation.
  </p>
  <p>
    6.3. If a payment dispute is substantiated, NearShopz will take appropriate measures to rectify the error, which may involve issuing refunds, adjusting charges, or providing compensation, as deemed necessary.
  </p>
  <p>
    6.4. Users are obligated to cooperate with NearShopz during the investigation of payment disputes and to provide accurate information to expedite resolution.
  </p>
  <p>
    6.5. NearShopz reserves the right to suspend or terminate user accounts or limit access to the platform in cases of suspected fraudulent activity or misuse of the payment system.
  </p>
  <p>
    6.6. Users acknowledge that NearShopz may be subject to the policies and procedures of third-party payment processors, and disputes related to payments processed through such entities may be governed by their terms and conditions.
  </p>
  <p>
    6.7. Users are accountable for payments associated with orders and deliveries. Payment processing is facilitated by NearShopz, and users agree to furnish accurate payment information.
  </p>
  
  <h2>Payment Terms</h2>
  <p>
    7.1. Users agree to remunerate the purchase price for goods and services ordered through the platform, alongside any applicable taxes and delivery charges.
  </p>
  <p>
    7.2. Payments for orders may be executed through accepted payment methods, encompassing credit/debit cards, net banking, digital wallets, or cash on delivery (where available).
  </p>
  <p>
    7.3. Users recognize that NearShopz may engage third-party payment processors to facilitate transactions. By effecting a payment, users assent to abide by the terms and conditions of such third-party processors.
  </p>
  <p>
    7.4. All payments are definitive and non-refundable, except as stipulated in our refund policy.
  </p>
  <p>
    7.5. In instances of cash on delivery, users must tender the exact amount due upon delivery. NearShopz and its delivery partners reserve the right to withhold delivery if the exact amount is not provided or if payment concerns arise.
  </p>
  
  <h2>Intellectual Property</h2>
  <p>
    8.1. The NearShopz website, mobile applications, and affiliated content are safeguarded by intellectual property rights, including copyrights and trademarks.
  </p>
  <p>
    8.2. Users are prohibited from reproducing, distributing, or utilizing NearShopz's intellectual property without explicit permission.
  </p>
  
  <h2>Privacy</h2>
  <p>
    9.1. User utilization of the NearShopz platform is subject to our Privacy Policy, delineating the collection, usage, and safeguarding of personal information.
  </p>
  
  <h2>Liability and Disclaimers</h2>
  <p>
    10.1. NearShopz furnishes its services on an "as is" and "as available" basis. We do not warrant that our services will be devoid of errors, secure, or uninterrupted.
  </p>
  <p>
    10.2. NearShopz bears no responsibility for the quality or safety of goods or services delivered by third-party providers.
  </p>
  
  <h2>Termination</h2>
  <p>
    11.1. NearShopz reserves the right to terminate or suspend user accounts for violations of these Terms and Conditions.
  </p>
  
  <h2>Changes to Terms and Conditions</h2>
  <p>
    12.1. NearShopz may revise these Terms and Conditions to reflect alterations in our practices. Users will be notified of significant revisions.
  </p>
  
  <h2>Governing Law and Jurisdiction</h2>
  <p>
    13.1. These Terms and Conditions are governed by and construed in accordance with the laws of India. Any disputes arising under these terms shall fall within the exclusive jurisdiction of the courts of India.
  </p>
  
  <h1>Refund and Cancellation Policy</h1>
  
  <h2>Introduction</h2>
  <p>
    1.1. This Refund and Cancellation Policy ("Policy") delineates the terms and conditions governing refund requests and order cancellations on NearShopz, ("NearShopz"), a hyperlocal delivery platform operated by 4Labs Technologies Pvt. Ltd.
  </p>
  <p>
    1.2. By utilizing our platform and services, you acknowledge that you have perused, comprehended, and consent to adhere to this Policy. If you dissent from this Policy, kindly abstain from utilizing our services.
  </p>
  
  <h2>Refund Policy</h2>
  <p>
    2.1. Refunds may be facilitated under the following circumstances:
  </p>
  <ul>
    <li>Non-Delivery: In case an order is undelivered.</li>
    <li>Defective or Damaged Items: If items delivered are defective or damaged.</li>
    <li>Overcharges: Should you be charged an amount exceeding the correct order total due to system errors.</li>
  </ul>
  <p>
    2.2. To initiate a refund, please liaise with our customer support within 24 hours of the order completion time.
  </p>
  <p>
    2.3. Refund requests are subject to verification, and NearShopz reserves the right to decline a refund if the request does not meet the eligibility criteria.
  </p>
  <p>
    2.4. Refunds will be processed to the original payment method utilized for the order.
  </p>
  <ul>
    <li>Net Banking, Credit Card, and Wallet Payments: Refunded within 5-7 days.</li>
    <li>UPI Payments: Refunded within 2 hours.</li>
  </ul>
  
  <h2>Cancellation Policy</h2>
  <p>
    3.1. Users may cancel an order under the following circumstances:
  </p>
  <ul>
    <li>Users are permitted to cancel the order only within 60 seconds of order placement.</li>
    <li>NearShopz reserves the right to impose penalties for cancellations initiated by the platform for reasons unrelated to the platform but not limited to:</li>
    <ul>
      <li>Incorrect or unreachable address provided by the Buyer.</li>
      <li>Inability to contact the Buyer by phone or email during delivery or order booking.</li>
    </ul>
    <li>Cancellations initiated by the NearShopz platform incur no cancellation charges for the Buyer.</li>
  </ul>
  <p>
    3.2. Once a delivery provider accepts an order, cancellation may not be viable, or it may incur fees or penalties at the discretion of the delivery provider.
  </p>
  <p>
    3.3. To cancel an order, users should utilize the cancellation feature in the application or reach out to our customer support.
  </p>
  
  <h2>User Responsibilities</h2>
  <p>
    4.1. Users are accountable for furnishing accurate order details and ensuring the correct delivery location.
  </p>
  <p>
    4.2. Users are urged to verify order details before confirming an order, as order errors are ineligible for refunds.
  </p>
  <p>
    4.3. Users must furnish accurate payment information for successful transactions.
  </p>
  
  <h2>Delivery Provider Responsibilities</h2>
  <p>
    5.1. Delivery providers must execute orders accurately and within the specified timeframe.
  </p>
  <p>
    5.2. In instances of defects, damage, or non-delivery, delivery providers are responsible for addressing these issues per this Policy.
  </p>
  
  <h2>Dispute Resolution</h2>
  <p>
    6.1. Any dispute, controversy, or claim arising from or relating to these terms and conditions, or their breach, termination, or invalidity, shall be resolved through good-faith negotiations between the parties.
  </p>
  <p>
    6.2. If a dispute cannot be resolved through negotiations within [number of days] days of written notification, the parties agree to submit the dispute to mediation administered by or any mutually agreed-upon mediation service.
  </p>
  <p>
    6.3. If mediation fails to resolve the dispute within 30 days from its commencement, either party may initiate legal proceedings per the governing law and jurisdiction specified in these terms and conditions.
  </p>
  
  <h2>Contact Information</h2>
  <p>
    For inquiries or concerns regarding this Policy, please reach out to us at:
  </p>
  <p>
    support@nearshopz.com  
  </p>
</div>
 
    </div>
  )
}

export default termsandconditions