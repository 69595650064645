import React, { useState, useEffect } from "react";
import grocerybanner from "../../Assets/Images/grocerycover.svg";
import nostores from "../../Assets/Images/trolley.jpg";
import { useMediaQuery } from "react-responsive";

const StoreHeader = (props) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });

  const isStoreLoaded =
    props.props &&
    props.props.isStoreDetailsSuccess &&
    props.props.StoreDetails;

  const isStoreOffline =
    isStoreLoaded &&
    (props.props.StoreDetails.storeStatus !== "active" ||
      !props.props.StoreDetails.storeWorking);
      const ismobileScreen = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <>
      {isSmallScreen ? (
        <div
          style={{
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            position: "sticky",
            zIndex: 1,
            background: `url(${grocerybanner})`,
            height: "150px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            opacity: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              zIndex: 2,
              top: 0,
              opacity: 1,
              paddingTop: ismobileScreen ? "60px": "35px",
            }}
          >
            {props.props.isStoreDetailsSuccess &&
            props.props.isStoreDetailsSuccess === true &&
            props.props.StoreDetails &&
            props.props.StoreDetails !== null &&
            props.props.StoreDetails.storeStatus === "active" &&
            props.props.StoreDetails.storeWorking === true ? (
              <span>
                {localStorage.getItem("storeLogo") &&
                (localStorage.getItem("storeLogo") === null ||
                  localStorage.getItem("storeLogo") === "null" ||
                  localStorage.getItem("storeLogo") === undefined ||
                  localStorage.getItem("storeLogo") === "undefined") ? (
                  <img
                    src={nostores}
                    alt="logo"
                    style={{
                      width: "78px",
                      height: "78px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "14px",
                      dropShadow: "0px 8px 44px rgba(0, 0, 0, 0.04)",
                      opacity: "100%",
                    }}
                  />
                ) : (
                  <img
                    src={localStorage.getItem("storeLogo")}
                    alt="logo"
                    style={{
                      width: "78px",
                      height: "78px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "14px",
                      opacity: "100%",
                    }}
                  />
                )}
              </span>
            ) : (
              <span>
                {localStorage.getItem("storeLogo") &&
                (localStorage.getItem("storeLogo") === null ||
                  localStorage.getItem("storeLogo") === "null" ||
                  localStorage.getItem("storeLogo") === undefined ||
                  localStorage.getItem("storeLogo") === "undefined") ? (
                  <img
                    src={nostores}
                    alt="logo"
                    style={{
                      width: "78px",
                      height: "78px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "14px",
                      dropShadow: "0px 8px 44px rgba(0, 0, 0, 0.04)",
                      opacity: "100%",
                      filter: `saturate(10%)`,
                    }}
                  />
                ) : (
                  <img
                    src={localStorage.getItem("storeLogo")}
                    alt="logo"
                    style={{
                      width: "78px",
                      height: "78px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "14px",
                      opacity: "100%",
                      filter: `saturate(10%)`,
                    }}
                  />
                )}
              </span>
            )}
            <span
              style={{
                paddingBottom: "5px",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: 800,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "left",
                paddingLeft: "15px",
              }}
            >
              <div>
                <b>
                  {localStorage.getItem("storeName") &&
                    localStorage.getItem("storeName") !== null &&
                    localStorage.getItem("storeName")}
                </b>
              </div>
              <div
                style={{
                  color: "#061303",
                  opacity: 0.5,
                  fontSize: "13px",
                  fontWeight: 400,
                }}
              >
                {localStorage.getItem("storelocation") &&
                  localStorage.getItem("storelocation").split(",", 1)}
              </div>
              {props.props.isStoreDetailsSuccess &&
              props.props.isStoreDetailsSuccess === true &&
              props.props.StoreDetails &&
              props.props.StoreDetails !== null &&
              props.props.StoreDetails.storeStatus === "active" &&
              props.props.StoreDetails.storeWorking === true ? null : (
                <div
                  style={{
                    color: "#fff",
                    border: "1px solid #F4A391",
                    borderRadius: "2px",
                    height: "15px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    fontSize: "10px",
                    background: " #EA4335",
                    fontWeight: 700,
                    lineHeight: "14px",
                    marginTop: "5px",
                  }}
                >
                  {"Currently Offline"}
                </div>
              )}
            </span>
          </div>
        </div>
      ) : (
        <div
          style={{
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            position: "sticky",
            zIndex: 1,
            background: `url(${grocerybanner})`,
            height: "150px",
            width: "100%",
            alignItems: "center",
            paddingLeft: "90px",
            opacity: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              textAlign: "left",
              position: "relative",
              zIndex: 2,
              top: 0,
              opacity: 1,
              paddingTop: "35px",
            }}
          >
            {props?.props?.isStoreDetailsSuccess &&
            props?.props?.isStoreDetailsSuccess === true &&
            props?.props?.StoreDetails &&
            props?.props?.StoreDetails !== null &&
            props?.props?.StoreDetails?.storeStatus === "active" &&
            props?.props?.StoreDetails?.storeWorking === true ? (
              <span>
                {localStorage.getItem("storeLogo") &&
                (localStorage.getItem("storeLogo") === null ||
                  localStorage.getItem("storeLogo") === "null" ||
                  localStorage.getItem("storeLogo") === undefined ||
                  localStorage.getItem("storeLogo") === "undefined") ? (
                  <img
                    src={nostores}
                    alt="logo"
                    style={{
                      width: "78px",
                      height: "78px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "14px",
                      dropShadow: "0px 8px 44px rgba(0, 0, 0, 0.04)",
                      opacity: "100%",
                    }}
                  />
                ) : (
                  <img
                    src={localStorage.getItem("storeLogo")}
                    alt="logo"
                    style={{
                      width: "78px",
                      height: "78px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "14px",
                      opacity: "100%",
                    }}
                  />
                )}
              </span>
            ) : (
              <span>
                {localStorage.getItem("storeLogo") &&
                (localStorage.getItem("storeLogo") === null ||
                  localStorage.getItem("storeLogo") === "null" ||
                  localStorage.getItem("storeLogo") === undefined ||
                  localStorage.getItem("storeLogo") === "undefined") ? (
                  <img
                    src={nostores}
                    alt="logo"
                    style={{
                      width: "78px",
                      height: "78px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "14px",
                      dropShadow: "0px 8px 44px rgba(0, 0, 0, 0.04)",
                      opacity: "100%",
                      filter: `saturate(10%)`,
                    }}
                  />
                ) : (
                  <img
                    src={localStorage.getItem("storeLogo")}
                    alt="logo"
                    style={{
                      width: "78px",
                      height: "78px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "14px",
                      opacity: "100%",
                      filter: `saturate(10%)`,
                    }}
                  />
                )}
              </span>
            )}
            <span
              style={{
                paddingBottom: "5px",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: 800,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "left",
                paddingLeft: "15px",
              }}
            >
              <div>
                <b>
                  {localStorage.getItem("storeName") &&
                    localStorage.getItem("storeName") !== null &&
                    localStorage.getItem("storeName")}
                </b>
              </div>
              <div
                style={{
                  color: "#061303",
                  opacity: 0.5,
                  fontSize: "13px",
                  fontWeight: 400,
                }}
              >
                {localStorage.getItem("storelocation") &&
                  localStorage.getItem("storelocation").split(",", 1)}
              </div>
              {isStoreOffline && (
                <div
                  style={{
                    color: "#fff",
                    border: "1px solid #F4A391",
                    borderRadius: "2px",
                    height: "15px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    fontSize: "10px",
                    background: " #EA4335",
                    fontWeight: 700,
                    lineHeight: "14px",
                    marginTop: "5px",
                  }}
                >
                  {"Currently Offline"}
                </div>
              )}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default StoreHeader;
