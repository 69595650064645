import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import StoreHeader from "../../Components/StoreHeader";
import EasyCheckout from "../../Components/EasyCheckout";
import Departments from "./Departments";
import SubCategory from "./SubCategory";
import Subcategory1 from "./Subcategory1";
import Subcategory2 from "./Subcategory2";
import CategoryProducts from "./CategoryProducts";
import Category1Products from "./Category1Products";
import Category2Products from "./Category2Products";
import DepartmentProducts from "./DepartmentProducts";
import DepartmentCarousel from './DepartmentCarousel';
import CategoryCarousel from './CategoryCarousel';
import Category2Carousel from './Category2Carousel';
import Category1Carousel from './Category1Carousel';
import LeftSidebar from "./LeftSidebar";
import Rightbar from "./RightBar";

function CategoryListingPage(props) {
  let params = useParams();

  const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  const smallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    localStorage.setItem('lattitude', lat);
    localStorage.setItem('longitude', lng);
    props.getCurrentlocation(`${lat},${lng}`);
  }

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        localStorage.setItem('lattitude', position.coords.latitude);
        localStorage.setItem('longitude', position.coords.longitude);
        reverseGeocode(position.coords);
      })
    }
  }

  React.useEffect(() => {
    document.title = `Nearshopz|Explore and Order Products from ${localStorage.getItem("storeName") && localStorage.getItem("storeName")}, ${localStorage.getItem('storelocation') && localStorage.getItem('storelocation').split(",", 1)}`;
    document.getElementsByTagName("META")[2].content = `Order products online from ${localStorage.getItem("storeName") && localStorage.getItem("storeName")}! NearShopz is the best way to shop local and to shop from nearby stores. `;
  })

  React.useEffect(() => {
    if (props.isCurrentLocation && props.isCurrentLocation === true &&
      props.CurrentLocation && props.CurrentLocation !== null && props.CurrentLocation.formatted_address !== null) {
        console.log("props.CurrentLocation.formatted_address",props.CurrentLocation.formatted_address)
      localStorage.setItem('location', props.CurrentLocation.formatted_address)
      localStorage.setItem('lattitude', props.Currentlat)
      localStorage.setItem('longitude', props.Currentlng)
    }
  },
    // eslint-disable-next-line
    [props.isCurrentLocation])

  React.useEffect(() => {
    window.scrollTo(0, 0,);
    if (params.storeid) {
      props.getStoreDetails(params.storeid, true);
      if (!localStorage.getItem('location') || localStorage.getItem('location') === null) { findMyLocation(); }
    }
    else {
      props.getStoreDetails(localStorage.getItem('storeid'), false);
    }
  }
    // eslint-disable-next-line
    , []);

  React.useEffect(() => {
    if (props.isStoreDetailsSuccess && props.isStoreDetailsSuccess === true &&
      props.StoreDetails &&
      props.StoreDetails !== null
    ) {
      localStorage.setItem('storeid', props.StoreDetails.id && props.StoreDetails.id);
      localStorage.setItem('storeName', props.StoreDetails.storeName && props.StoreDetails.storeName);
      localStorage.setItem('storeLogo', props.StoreDetails.storeLogoUrl && props.StoreDetails.storeLogoUrl);
      localStorage.setItem('storelocation', props.StoreDetails.storeLocation && props.StoreDetails.storeLocation);
    }
  },
    // eslint-disable-next-line
    [props.isStoreDetailsSuccess])

  return (
    <div
      style={{ paddingTop: smallScreen ? "25px": '0px' }}>
      <Box
        position={'absolute'}
        width={'100vw'}
        zIndex={1000}
        sx={{
          zIndex:
            1000,
          left: 0,
          opacity: 1
        }}>
        <StoreHeader
          navigate={props.navigate}
          props={props} />
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          paddingTop: { xs: '150px', md: '150px' },
          position: 'relative',
          zIndex: 600,
          width: '100%'
        }}>
        {
          fullScreen ?
            <Box
              sx={{
                paddingLeft:
                {
                  xs: '15px',
                  md: 0
                }
              }}>
              <Routes>
                <Route exact={true} path={'/'} element={<DepartmentCarousel navigate={props.navigate}
                  params={params} props={props} />} />
                <Route exact={true} path='/category' element={<CategoryCarousel navigate={props.navigate}
                  params={params} props={props} />} />
                <Route exact={true} path='/category1' element={<Category1Carousel navigate={props.navigate}
                  params={params} props={props} />} />
                <Route exact={true} path='/category2' element={<Category2Carousel navigate={props.navigate}
                  params={params} props={props} />} />
                <Route path='/departmentproducts' exact={true} element={<DepartmentProducts navigate={props.navigate}
                  params={params} props={props} />} />
              </Routes>
            </Box>
            : null}
        <Stack
          direction="row"
          spacing={3}
          justifyContent="space-between"
          style={{ width: '100%' }}>
          <LeftSidebar navigate={props.navigate}
            params={params} props={props} />
          <Routes>
            <Route exact={true} path={'/'} element={<Departments navigate={props.navigate}
              params={params} props={props} />} />
            <Route exact={true} path='/category' element={<SubCategory navigate={props.navigate}
              params={params} props={props} />} />
            <Route exact={true} path='/category1' element={<Subcategory1 navigate={props.navigate}
              params={params} props={props} />} />
            <Route exact={true} path='/category2' element={<Subcategory2 navigate={props.navigate}
              params={params} props={props} />} />
            <Route path='/departmentproducts' exact={true} element={<DepartmentProducts navigate={props.navigate}
              params={params} props={props} />} />
            <Route path='/categoryproducts' exact={true} element={<CategoryProducts navigate={props.navigate}
              params={params} props={props} />} />
            <Route path='/category1products' exact={true} element={<Category1Products navigate={props.navigate}
              params={params} props={props} />} />
            <Route path='/category2products' exact={true} element={<Category2Products navigate={props.navigate}
              params={params} props={props} />} />
          </Routes>
          <Rightbar navigate={props.navigate}
            params={params} props={props} />
        </Stack>
      </Box>
      <Box
        style={{
          width: '100%',
          paddingTop: '70px'
        }}>
        <EasyCheckout navigate={props.navigate}
          params={params} props={props} />
      </Box>
    </div>
  );
}

export default CategoryListingPage;